import axios from 'axios'
import { useState } from 'react'
import styled from 'styled-components'
import Input from '../components/Input'
import sleep from '../helpers/sleep'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

const LoginPageContainer = styled.div<ThemePalleteProps>`
	background-color: ${props => props.themeSetting?.pallete.background.light};
    color: ${props => props.themeSetting?.pallete.font.light};
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LoginCardContainer = styled.div<ThemePalleteProps>`
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    background-color: ${props => props.themeSetting?.pallete.background.default};
    padding: ${props => props.themeSetting?.spacing.sm}rem;
    border-radius: 0.5rem;
`

const LoginCardHeader = styled.div<ThemePalleteProps>`
    color: inherit;
    font-size: ${props => props.themeSetting?.typography.h4.fontSize}rem;
    margin-bottom: 3rem;
`

const SytledButton = styled.button<ThemePalleteProps>`
    color: inherit;
    font-size: ${props => props.themeSetting?.typography.h6.fontSize}rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0.5rem 0.5rem;
    cursor: pointer;
    font-weight: 600;
    background-color: ${props => props.themeSetting?.pallete.secondary.main};
    color: ${props => props.themeSetting?.pallete.font.dark};
    border: none;
    outline: none;
    border-radius: 0.3rem;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

    margin-top: 2rem;
    width: 100%;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.6;
    }
`

const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")


    const {theme} = useThemeSetting()
    const {setUser, setToken, setAuth} = useLogin()
    const {setAlert} = useAlert()


    const handleAuth = async () => {
        setAuth(true)
        try {
            let result = await axios.post("/auth/login", {
                username: username,
                password: password
            })
            if (result.status !== 200) {
                setAuth(false)
                return setAlert({
                    show: true,
                    message: result.data.message,
                    type: "danger"
                })
            }
            sleep(2000).then(() => {
                setAuth(false)
                setAlert({
                    show: true,
                    message: "You are logged in !!!",
                    type: "success",
                    cb: () => {
                        setUser({
                            username: result.data.user.username,
                            email: result.data.user.email,
                            role: result.data.user.role
                        })
                        setToken(result.data.token)
                    }
                })
            })
        } catch (err: any) {
            console.log(err)
            setAuth(false)
            return setAlert({
                show: true,
                message: err.message,
                type: "danger"
            })
        }
    }

    const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.which === 13) {
            handleAuth()
        }
    }

    return (
        <LoginPageContainer className="loginContainer" themeSetting={theme}>
            <LoginCardContainer themeSetting={theme}>
                <LoginCardHeader themeSetting={theme}>Custom App Login</LoginCardHeader>
                <Input 
                label='Username' 
                width='30rem' 
                placeholder='Username / Email' 
                value={username}
                onChange={e => setUsername(e.target.value)}
                />
                <Input 
                type="password" 
                label='Password' 
                width='30rem' 
                placeholder='Password' 
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyPress={handleKeypress}
                />
                <SytledButton themeSetting={theme} onClick={handleAuth}>Submit</SytledButton>
            </LoginCardContainer>
        </LoginPageContainer>
    )
}

export default Login