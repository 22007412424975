const dateToYMD = (date: Date) => {
    let year, month, day
    year = String(new Date(date).getFullYear());
    month = String(new Date(date).getMonth() + 1);
    if (month.length === 1) {
        month = "0" + month;
    }
    day = String(new Date(date).getDate());
    if (day.length === 1) {
        day = "0" + day;
    }
    return year + "-" + month + "-" + day;
}

export default dateToYMD