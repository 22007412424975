import React from 'react'
import styled from "styled-components"
import randomID from '../helpers/randomID'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface Props {
    label?: string
    checked: boolean
    onChange: React.ChangeEventHandler<HTMLInputElement>
    value?: string | number
    className?: string
}

const StyledCheckboxContainer = styled.div<{ label?: string }>`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: auto;

    @media (max-width: 768px) {
        width: ${props => !props.label ? "1rem" : "auto"};
    }
`

const StyledCheckbox = styled.input`
    line-height: 1;
    width: auto;
    box-sizing: border-box;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        width: auto;
    }
`

const StyledLabel = styled.label<ThemePalleteProps>`
    user-select: none;
    color: ${props => props.pallete?.grey[100]};
    cursor: pointer;
`

const Checkbox = (props: Props) => {
    const {theme} = useThemeSetting()

    const id = randomID()

    return (
        <StyledCheckboxContainer label={props.label}>
            <StyledCheckbox id={id} type="checkbox" checked={props.checked} onChange={props.onChange} value={props.value} className={"checkbox" + (props.className ? " " + props.className : "")} />
            {props.label && <StyledLabel htmlFor={id} themeSetting={theme}>{props.label}</StyledLabel>}
        </StyledCheckboxContainer>
    )
}

export default Checkbox