import React from 'react'
import styled from 'styled-components'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

const Container = styled.div<ThemePalleteProps>`
    box-sizing: border-box;
    padding: ${props => props.themeSetting?.spacing.sm}rem;
    padding-top: calc(${props => props.themeSetting?.spacing.sm}rem * 2);
    color: ${props => props.themeSetting?.pallete.font.light};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        padding: calc(${props => props.themeSetting?.spacing.sm}rem * 0.1);
        padding-top: ${props => props.themeSetting?.spacing.sm}rem;
    }
`

const PageContent = (props: { children?: React.ReactNode }) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <Container className="mainPageContainer" themeSetting={theme} pallete={pallete}>{props.children}</Container>
    )
}

export default PageContent