import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import Checkbox from '../components/Checkbox'
import Color from '../components/Color'
import ManageTable from '../components/ManageTable'
import { TableHeaderDataProps } from '../components/Table'
import { fetchProduct } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import {DeleteIcon, EditIcon} from '../icons/icon'
import { IProduct } from '../types/types'

const breadCrumbItems = [
    { name: "Product Maintenance", active: true },
]

let defaultHeader: TableHeaderDataProps[] = [
    { key: "checkbox", name: "", width: "3%" },
    { key: "p_id", name: "ID", width: "4%", sort: "unsort" },
    { key: "date_time", name: "Date", width: "6%", sort: "unsort" },
    { key: "brand", name: "Brand", sort: "unsort" },
    { key: "product", name: "Product", sort: "unsort" },
    { key: "model_no", name: "Model No", sort: "unsort" },
    { key: "imei_req", name: "Imei Req.", sort: "unsort" },
    { key: "cost", name: "Cost$", sort: "unsort" },
    { key: "tax", name: "Tax%", sort: "unsort" },
    { key: "status", name: "Status", width: "5%", sort: "unsort" },
    { key: "action", name: "Action", align: "center", width: "5%" },
]

const ProductList = () => {
    let history = useNavigate()
    const {token, setToken} = useLogin()
    const [data, setData] = useState<{ [key: string]: any }[]>([])
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)

    const {setAlert} = useAlert()

    const handleDelete = useCallback((_data: IProduct) => {
        setAlert({
            show: true,
            message: "Delete " + _data.product + " ?",
            type: "question",
            cb: async () => {
                try {
                    let result = await axios.delete("/product/" + _data.p_id)

                    if(result.status !== 200) {
                        return setAlert({
                            show: true,
                            message: result.data.message,
                            type: "danger"
                        })
                    }

                    return setAlert({
                        show: true,
                        message: "Delete Product Successfully !!!",
                        type: "success",
                        cb: () => {
                            setData(v => v.filter(row => row.p_id !== _data.p_id))
                        }
                    })

                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }, [setAlert])

    const fetchData = useCallback(async () => {
        try {
            let _data = await fetchProduct(token)

            if(Array.isArray(_data)) {
                setData(_data.map(row => ({ ...row, checked: false })))
                setLoading(false)
            }
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, setAlert, setToken, setReload, reload])

    const memoData = useMemo(() => {
        return data.map(row => {
            return {
                ...row,
                product: row.brand + " " + row.product,
                status: <Color color={row.status === "active" ? "green" : "red"}>{row.status}</Color>,
                action: <Box justifyContent='center' alignItems='center'>
                    <EditIcon iconcolor="blue" iconsize='h6' onClick={() => history("/product/" + row.p_id)} />
                    <DeleteIcon iconcolor="red" iconsize='h6' onClick={() => handleDelete(row as IProduct)} />
                </Box>,
                checkbox: <Box justifyContent='center' alignItems='center'>
                    <Checkbox 
                    className="productTableRow"
                    checked={row.checked} 
                    value={row.p_id} 
                    onChange={e => {
                        setData(v => v.map(el => {
                            if(el.p_id === row.p_id) {
                                return {
                                    ...el,
                                    checked: e.target.checked
                                }
                            }
                            return el
                        }))
                    }} />
                </Box>
            }
        })
    }, [data, history, handleDelete])

    const tableRowClick = useCallback((e: React.MouseEvent<HTMLTableRowElement>) => {
        let productCheckbox = e.currentTarget.querySelector(".productTableRow") as HTMLInputElement

        let p_id = Number(productCheckbox.value)

        setData(v => v.map(row => {
            if(p_id === row.p_id) {
                return {
                    ...row,
                    checked: !row.checked
                }
            }
            return row
        }))
    }, [])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb
            items={breadCrumbItems}
            button={[
                {
                    name: "New",
                    action: "/product/new",
                    color: "blue"
                }
            ]} 
            />
            <ManageTable 
            defaultHeader={defaultHeader}
            columnSetting="productList"
            data={memoData}
            filterKey={["product", "model_no"]}
            loading={loading}
            tableRowClick={tableRowClick}
            />
        </>
    )
}

export default ProductList