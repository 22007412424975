import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardFooter, CardHeader } from '../components/Card'
import Input from '../components/Input'
import PageContent from '../components/PageContent'
import Select from '../components/Select'
import { fetchBrandById } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'

const breadCrumbItems = [
    { name: "Brand Maintenance", navigate: "/brand" },
    { name: "Brand", active: true },
]


const Brand = () => {
    let history = useNavigate()
    const {b_id} = useParams()
    const {token, setToken} = useLogin()

    const [reload, setReload] = useState(false)
    const [brand, setBrand] = useState("")
    const [status, setStatus] = useState("")

    const {setAlert} = useAlert()

    const fetchData = useCallback(async () => {
        try {
            if(b_id && b_id !== "new") {
                let _data = await fetchBrandById(token, b_id)
                setBrand(_data.brand)
                setStatus(_data.status)
            } else {
                setBrand("")
                setStatus("")
            }
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
   
    }, [token, b_id, setToken, setAlert, reload])

    const memoButton = useMemo(() => {
        if(b_id === "new") return []

        return [
            {
                name: "New",
                action: "/brand/new",
                color: "blue"
            }
        ] as BreadCrumbButtonProps[]
    }, [b_id])

    const handleSubmit = () => {
        if(!brand || !brand.trim()) return setAlert({ show: true, message: "Please insert brand...", type: "danger" })
        if(!status) return setAlert({ show: true, message: "Please select status...", type: "danger" })
        setAlert({
            show: true,
            message: b_id === "new" ? "Craete New Brand ?" : "Update Brand ?",
            type: "question",
            cb: async () => {
                try {
                    if(b_id === "new") {
                        let result = await axios.post("/brand", {
                            brand, status
                        })

                        if(result.status !== 201) {
                            setAlert({ show: true, message: result.data.message, type: "danger" })
                            return
                        }

                        return setAlert({ 
                            show: true, 
                            message: "New Brand Created Successfully !!!", 
                            type: "success",
                            cb: () => {
                                history("/brand/" + result.data.b_id)
                            } 
                        })
                    } else {
                        let result = await axios.patch("/brand/" + b_id, {
                            brand, status
                        })

                        if(result.status !== 200) {
                            setAlert({ show: true, message: result.data.message, type: "danger" })
                            return
                        }

                        return setAlert({ 
                            show: true, 
                            message: "Brand Updated Successfully !!!", 
                            type: "success"
                        })
                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({ show: true, message: err.message, type: "danger" })
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb 
            items={breadCrumbItems}
            button={memoButton}
            />
            <PageContent>
                <Card mediaWidth='100%'>
                    <CardHeader border>{b_id === "new" ? "New Brand" : "Edit Brand # " + b_id}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="1rem">
                            <Input width="30rem" mediaWidth='100%' label="Brand" value={brand} onChange={e => setBrand(e.target.value)} placeholder="Insert brand..." />
                            <Select width="30rem"  mediaWidth='100%' label="Status" value={status} onChange={e => setStatus(e.target.value)}>
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Select>
                        </Box>
                    </CardBody>
                    <CardFooter border>
                        <Box justifyContent='flex-end'>
                        <Button color="green" onClick={handleSubmit}>Submit</Button>
                        </Box>
                    </CardFooter>
                </Card>
            </PageContent>
        </>
    )
}

export default Brand