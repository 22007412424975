import axios from 'axios'
import React, { useEffect, useState } from 'react'
import useAlert from '../../hooks/useAlert'
import loginContext, { UserStoreProps } from './loginContext'


const LoginState = ({ children }: { children: React.ReactNode }) => {
    const [token, setToken] = useState("")
	const [user, setUser] = useState<UserStoreProps | null>(null)
	const [auth, setAuth] = useState(true)
	const {setAlert} = useAlert()

    const API = process.env.REACT_APP_DB_ENV === "local" ? "http://localhost:3020/custom" : "https://api.tentenbn.com/custom"

	axios.defaults.baseURL = API
	axios.defaults.withCredentials = true
	if (token) {
		axios.defaults.headers.common['Authorization'] = "Bearer " + token
	}

	axios.interceptors.request.use((config: any) => {
		config.headers["Content-Type"] = "application/json"
		return config
	})

	axios.interceptors.response.use((response: any) => {
		if (response && response.data) {
			return response
		}
	}, (err: any) => {
		if (err.response) return err.response
		throw err
	})

	useEffect(() => {
		const refreshToken = async () => {
			const controller = new AbortController();

			try {
				let result = await axios.get("/auth/accessToken", {
					signal: controller.signal
				})

				if (result.status !== 200) {
					setAuth(false)
					setAlert({
						show: true,
						message: "Session expired, login required",
						type: "danger",
						cb: () => setToken("")
					})
					return
				}

				setTimeout(() => {
					setToken(result.data.token)
					setUser({
						username: result.data.user.username,
						email: result.data.user.email,
						role: result.data.user.role,
					})
					setAuth(false)

					return () => controller.abort()
				}, 2000)

			} catch (err: any) {
				console.log(err)
				setAuth(false)
				return setAlert({
					show: true,
					message: "Session expired, login required",
					type: "danger",
					cb: () => setToken("")
				})
			}
		}
		refreshToken()
	}, [setAlert])

    return (
        <loginContext.Provider value={{ token, setToken, user, setUser, auth, setAuth }}>
            {children}
        </loginContext.Provider>
    )
}

export default LoginState