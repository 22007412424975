import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import { Card, CardBody, CardHeader } from '../components/Card'
import Input from '../components/Input'
import Modal from '../components/Modal'
import PageContent from '../components/PageContent'
import { Table, TD, TH, TR } from '../components/Table'
import {
    fetchInvoiceById,
    fetchInvoiceDetail,
    fetchInvoiceDetailByField,
    fetchStockOrder,
    fetchStockOrderById,
    fetchStockOrderDetailByField
} from '../fetchers/fetcher'
import currencyFormatter from '../helpers/currencyFormatter'
import randomID from '../helpers/randomID'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { DeleteIcon, SelectIcon } from '../icons/icon'
import StaticTableRow from '../components/StaticTableRow'
import dateToYMD from '../helpers/dateToYMD'
import Button from '../components/Button'
import { IInvoice, IInvoiceDetail, IStockOrder } from '../types/types'
import axios from 'axios'
import { errorMessage } from '../helpers/errorMessage'
import { errorCallback } from '../helpers/errorCallback'
import PdfHandler from '../helpers/pdfHandler'
import styled from 'styled-components'
import getInvoiceDoc from '../helpers/getInvoiceDoc'

interface ITableData {
    uid: string,
    invoice_detail_id: number | null,
    p_id: number | null,
    item_description: string,
    item_description1: string,
    item_description2: string,
    model_no: string,
    imei_req: string,
    cost: number,
    price: number,
    qty: number,
    showDescription: boolean,
}

const StyledBoxInfo = styled(Box)`
    gap: 3rem;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0.5rem;
    }
`

const StyledBoxInfoChild = styled(Box)`
    flex-direction: column;
    gap: 1rem;

    @media (max-width: 768px) {
        gap: 0.5rem;
    }
`

const StyledRefInfoBox = styled(Box)`
    gap: 10rem;

    @media (max-width: 768px) {
        gap: 4rem;
    }
`

const StyledRefInfoChildBox = styled(Box)`
    gap: 1rem;
    flex-direction: column;

    @media (max-width: 768px) {
        gap: 0.5rem;
    }
`

const StyledRefInfoChildItemBox1 = styled(Box)` 
    width: 4rem;

    @media (max-width: 768px) {
        width: 2rem;
    }
`
const StyledRefInfoChildItemBox2 = styled(Box)` 
    width: 2rem;

    @media (max-width: 768px) {
        width: 0.5rem;
    }
`

const breadCrumbItems = [
    { name: "Invoice Maintenance", navigate: "/invoice" },
    { name: "Invoice", active: true },
]

const TableRow = React.memo((props: {
    rowData: ITableData,
    index: number
    handleTableChange: (_data: ITableData, field: keyof ITableData, value: string) => void
    bnd_to_myr: string
    bnd_to_usd: string
    handleTableIconClick: (index: number) => void
    handleDelete: (_data: ITableData) => void
    setShowPriceRefModal: React.Dispatch<React.SetStateAction<boolean>>
    invoiceProductRef: React.MutableRefObject<{
        s_id: null | number
        l_id: null | number
        p_id: null | number
        cost: number
        item_description: string
    }>
}) => {
    return <>
        <TR>
            <TD align="center">{props.index + 1}</TD>
            <TD>
                <Input
                    mediaWidth='28rem'
                    width='100%'
                    iconType={!props.rowData.showDescription ? "add" : "minus"}
                    value={props.rowData.item_description}
                    onChange={e => props.handleTableChange(props.rowData, "item_description", e.target.value)}
                    onClick={() => props.handleTableIconClick(props.index)}
                />
            </TD>
            <TD>{props.rowData.model_no}</TD>
            <TD align='right'>{currencyFormatter(props.rowData.cost)}</TD>
            <TD align='center'>
                <Box alignItems='center' justifyContent='center'>
                    <SelectIcon iconcolor='blue' onClick={() => {
                        props.invoiceProductRef.current = {
                            ...props.invoiceProductRef.current,
                            p_id: props.rowData.p_id,
                            item_description: props.rowData.item_description,
                            cost: props.rowData.cost
                        }
                        props.setShowPriceRefModal(v => !v)
                    }} />
                </Box>
            </TD>
            <TD align='center'>
                <Input
                       mediaWidth='3rem'
                    width='100%'
                    align='center'
                    value={props.rowData.qty.toString()}
                    onChange={e => props.handleTableChange(props.rowData, "qty", e.target.value)}
                />
            </TD>
            <TD align='center'>
                <Input
                       mediaWidth='4rem'
                    width='100%'
                    align='center'
                    value={props.rowData.price.toString()}
                    onChange={e => props.handleTableChange(props.rowData, "price", e.target.value)}
                />
            </TD>
            <TD align='right'>{currencyFormatter(props.rowData.price * parseFloat(props.bnd_to_myr))}</TD>
            <TD align='right'>{currencyFormatter(Math.ceil(props.rowData.price * parseFloat(props.bnd_to_usd) * 10) / 10)}</TD>
            <TD align="right">{currencyFormatter(props.rowData.qty * props.rowData.price)}</TD>
            <TD align='center'>
                <Box alignItems='center' justifyContent='center'>
                    <DeleteIcon iconcolor='red' onClick={() => props.handleDelete(props.rowData)} />
                </Box>
            </TD>
        </TR>
        <TR hide={!props.rowData.showDescription}>
            <TD></TD>
            <TD>
                <Input
                    width='100%'
                    value={props.rowData.item_description1}
                    onChange={e => props.handleTableChange(props.rowData, "item_description1", e.target.value)}
                    placeholder="Extra item description..."
                />
            </TD>
            <TD colSpan={9}></TD>
        </TR>
        <TR hide={!props.rowData.showDescription}>
            <TD></TD>
            <TD>
                <Input
                    width='100%'
                    value={props.rowData.item_description2}
                    onChange={e => props.handleTableChange(props.rowData, "item_description2", e.target.value)}
                    placeholder="Extra item description..."
                />
            </TD>
            <TD colSpan={9}></TD>
        </TR>
    </>
})

const TransferModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    stockOrders: IStockOrder[]
}) => {
    let location = useLocation()

    return <Modal
        title="Transfer from Stock Order"
        width="50%"
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
    >
        <Box flexDirection='column' gap="1rem">

            <Table
                height="50vh"
                header={
                    <>
                        <TH width="5%">ID</TH>
                        <TH>Date</TH>
                        <TH>Running</TH>
                        <TH>Invoice Header</TH>
                        <TH>Permit Holder</TH>
                        <TH width="4%"></TH>
                    </>
                }
                body={
                    <>
                        {
                            props.stockOrders
                            .filter(row => new Date(row.date_time).getFullYear() === new Date().getFullYear())
                            .map((row) => {
                                return <TR key={"stockOrder" + row.so_id}>
                                    <TD>{row.so_id}</TD>
                                    <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                    <TD>{row.running}</TD>
                                    <TD>{row.supplier}</TD>
                                    <TD>{row.location}</TD>
                                    <TD align='center'>
                                        <Box justifyContent='center' alignItems='center'>
                                            <SelectIcon iconcolor='red' onClick={() => {
                                                location.state = {
                                                    so_id: row.so_id
                                                }
                                                props.setShowModal(false)
                                            }} />
                                        </Box>
                                    </TD>
                                </TR>
                            })
                        }
                    </>
                }
            />

        </Box>
    </Modal>
})

const PriceRefModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    bnd_to_myr: string
    bnd_to_usd: string
    invoiceDetails: IInvoiceDetail[]
    invoiceProductRef: React.MutableRefObject<{
        s_id: null | number
        l_id: null | number
        p_id: null | number
        cost: number
        item_description: string
    }>
}) => {
    return <Modal
        title="Price Reference Modal"
        width="80%"
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
    >
        <Box flexDirection='column' gap="1rem">
            <Box>
                <Box width="8rem">Product</Box>
                <Box width="2rem">:</Box>
                <Box>{props.invoiceProductRef.current.item_description}</Box>
            </Box>
            <Box>
                <Box width="8rem">Cost (MYR)</Box>
                <Box width="2rem">:</Box>
                <Box>{props.invoiceProductRef.current.cost}</Box>
            </Box>
            <Card backgroundColor={true}>
                <CardHeader border>Price Reference Convert in BND</CardHeader>
                <CardBody>
                    <StyledRefInfoBox>
                        {
                            [
                                [36, 37, 38, 39, 40],
                                [41, 42, 43, 44, 45],
                                [46, 47, 48, 49, 50]
                            ].map((row, _) => {
                                return <StyledRefInfoChildBox key={"refRow" + _}>
                                    {
                                        row.map(el => {
                                            return <Box key={"refCol" + el}>
                                                <StyledRefInfoChildItemBox1>{el}%</StyledRefInfoChildItemBox1>
                                                <StyledRefInfoChildItemBox2>-</StyledRefInfoChildItemBox2>
                                                <Box>{currencyFormatter(props.invoiceProductRef.current.cost / (props.bnd_to_myr as unknown as number) * el / 100)}</Box>
                                            </Box>
                                        })
                                    }
                                </StyledRefInfoChildBox>
                            })
                        }
                    </StyledRefInfoBox>
                </CardBody>
            </Card>
            <Table
                height="45vh"
                header={
                    <>
                        <TH width="4%" align='center'>#</TH>
                        <TH>Date</TH>
                        <TH>Running</TH>
                        <TH>Invoice Header</TH>
                        <TH>Permit Holder</TH>
                        <TH>Item Description</TH>
                        <TH width="5%" align='center'>Qty</TH>
                        <TH width="6%" align="center">Price</TH>
                    </>
                }
                body={
                    <>
                        {
                            props.invoiceDetails.filter(row =>
                                row.p_id === props.invoiceProductRef.current.p_id &&
                                row.s_id === props.invoiceProductRef.current.s_id)
                                .sort((a, b) => b.invoice_detail_id - a.invoice_detail_id)
                                .map((row, i) => {
                                    return <TR key={"refTable" + row.invoice_detail_id}>
                                        <TD align='center'>{i + 1}</TD>
                                        <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                        <TD>{row.running}</TD>
                                        <TD>{row.supplier}</TD>
                                        <TD>{row.location}</TD>
                                        <TD>{row.item_description}</TD>
                                        <TD align='center'>{row.qty}</TD>
                                        <TD align='center'>{row.price}</TD>
                                    </TR>
                                })
                        }
                    </>
                }
            />
        </Box>
    </Modal>
})

const InvoiceTable = React.memo((props: {
    tableData: ITableData[]
    setTableData: React.Dispatch<React.SetStateAction<ITableData[]>>
    bnd_to_myr: string
    bnd_to_usd: string
    setShowPriceRefModal: React.Dispatch<React.SetStateAction<boolean>>
    invoiceProductRef: React.MutableRefObject<{
        s_id: null | number
        l_id: null | number
        p_id: null | number
        cost: number
        item_description: string
    }>
}) => {
    const { setAlert } = useAlert()

    const handleTableChange = useCallback((_data: ITableData, field: keyof ITableData, value: string | number) => {
        let newData = [...props.tableData]
        let index = newData.map(row => row.uid).indexOf(_data.uid)

        if (field === "qty" || field === "price") {
            newData[index][field] = value as number
        }

        if (field === "item_description" || field === "item_description1" || field === "item_description2") {
            newData[index][field] = value as string
        }

        props.setTableData(newData)
    }, [props])

    const handleTableIconClick = useCallback((index: number) => {
        let newData = [...props.tableData]
        newData[index].showDescription = !newData[index].showDescription
        props.setTableData(newData)
    }, [props])

    const handleDelete = useCallback((_data: ITableData) => {
        setAlert({
            show: true,
            message: <Box flexDirection='column' gap="1rem" alignItems='flex-start' width="100%">
                <Box>Delete Item</Box>
                <Box width="100%">
                    <Box width="6rem">Item :</Box>
                    <Box>{_data.item_description}</Box>
                </Box>
            </Box>,
            type: "danger",
            cb: async () => {
                try {
                    if (_data.invoice_detail_id) {
                        let result = await axios.delete("/invoiceDetail/" + _data.invoice_detail_id)

                        if (result.status !== 200) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }
                    }

                    props.setTableData(v => v.filter(row => row.uid !== _data.uid))

                    setTimeout(() => {
                        setAlert({
                            show: true,
                            message: _data.item_description + " Deleted Successfully !!!",
                            type: "success"
                        })
                    }, 500)
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }, [setAlert, props])

    return <Table
        header={
            <>
                <TH width="3%" align='center'>#</TH>
                <TH>Item Description</TH>
                <TH width="10%">Model No.</TH>
                <TH width="5%" align='right'>Cost(MYR)</TH>
                <TH width="3%" align='center'>Ref</TH>
                <TH width="5%" align='center'>Qty</TH>
                <TH width="6%" align='center'>Price(BND)</TH>
                <TH width="6%" align='right'>Price(MYR)</TH>
                <TH width="6%" align='right'>Price(USD)</TH>
                <TH width="6%" align='right'>Total(BND)</TH>
                <TH width="3%" align='center'></TH>
            </>
        }
        body={
            <>
                {
                    props.tableData.map((row, _) => {
                        return <TableRow
                            key={"tableRow" + row.uid}
                            rowData={row}
                            index={_}
                            handleTableChange={handleTableChange}
                            handleTableIconClick={handleTableIconClick}
                            bnd_to_myr={props.bnd_to_myr}
                            bnd_to_usd={props.bnd_to_usd}
                            handleDelete={handleDelete}
                            setShowPriceRefModal={props.setShowPriceRefModal}
                            invoiceProductRef={props.invoiceProductRef}
                        />
                    })
                }
                <StaticTableRow row={(props.tableData.length === 0 ? 16 : 15) - props.tableData.length} column={11} />
                {
                    props.tableData.length > 0 &&
                    <TR>
                        <TD colSpan={5} align="right">Total Quantity</TD>
                        <TD align="center">{props.tableData.reduce((total, row) => total + row.qty, 0)}</TD>
                        <TD colSpan={3} align="right">Total Amount</TD>
                        <TD align="right">{currencyFormatter(props.tableData.reduce((total, row) => total + (row.qty * row.price), 0))}</TD>
                        <TD></TD>
                    </TR>
                }
            </>
        }
    />

})

const Invoice = () => {
    let history = useNavigate()
    const { invoice_id } = useParams()
    const location = useLocation()

    const [header, setHeader] = useState("New Invoice")
    const [reload, setReload] = useState(false)

    const [bnd_to_myr, setBnd_to_myr] = useState<string>("3")
    const [bnd_to_usd, setBnd_to_usd] = useState<string>("0.75")
    const [date_time, setDate_time] = useState(dateToYMD(new Date()))
    const [notes, setNotes] = useState("")
    const [notes1, setNotes1] = useState("")

    const [invoice, setInvoice] = useState<IInvoice | null>(null)
    const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetail[]>([])
    const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceDetail[]>([])
    const [stockOrders, setStockOrders] = useState<IStockOrder[]>([])
    const [showStockOrderModal, setShowStockOrderModal] = useState(false)
    const [tableData, setTableData] = useState<ITableData[]>([])

    const [showPriceRefModal, setShowPriceRefModal] = useState(false)

    const { setAlert } = useAlert()
    const { token, setToken } = useLogin()

    const invoiceProductRef = useRef<{
        s_id: null | number
        l_id: null | number
        p_id: null | number
        cost: number
        item_description: string
    }>({
        s_id: null,
        l_id: null,
        p_id: null,
        cost: 0,
        item_description: ""
    })

    const fetchTransferData = useCallback(async () => {
        try {
            if (invoice_id === "new") {
                let result = await fetchStockOrder(token)

                setStockOrders(result.filter(row => !row.invoice_id).sort((a, b) => b.so_id - a.so_id))
            }
            return
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [setAlert, setToken, invoice_id, token, reload])

    const fetchData = useCallback(async () => {
        try {
            if (invoice_id === "new") {
                if (location.state && location.state.so_id) {

                    let [_stockOrder, _stockOrderDetail, _invoiceDetails] = await Promise.all([
                        fetchStockOrderById(token, location.state.so_id),
                        fetchStockOrderDetailByField(token, { so_id: location.state.so_id }),
                        fetchInvoiceDetail(token),
                    ])

                    let newData = _stockOrderDetail.filter((v, i, s) => {
                        return s.map(el => el.p_id).indexOf(v.p_id) === i
                    }).map(row => {
                        let previousDetail = _invoiceDetails.filter(el => {
                            return el.s_id === _stockOrder.s_id && el.p_id === row.p_id
                        })

                        return {
                            uid: randomID(),
                            invoice_detail_id: null,
                            p_id: row.p_id,
                            item_description: previousDetail.length === 0 ? row.brand + " " + row.product : previousDetail[previousDetail.length - 1].item_description,
                            item_description1: previousDetail.length === 0 ? "" : previousDetail[previousDetail.length - 1].item_description1,
                            item_description2: previousDetail.length === 0 ? "" : previousDetail[previousDetail.length - 1].item_description2,
                            model_no: row.model_no,
                            imei_req: row.imei_req,
                            cost: row.cost,
                            price: previousDetail.length === 0 ? 0 : previousDetail[previousDetail.length - 1].price,
                            qty: _stockOrderDetail.reduce((total, el) => {
                                if (row.p_id === el.p_id) {
                                    total += el.qty
                                }
                                return total
                            }, 0),
                            showDescription: false
                        } as ITableData
                    })
                    setHeader("New Invoice From Order " + _stockOrder.running)

                    setDate_time(dateToYMD(new Date(_stockOrder.date_time)))

                    setTableData(newData)
                    setInvoiceDetails(_invoiceDetails)

                    invoiceProductRef.current = {
                        ...invoiceProductRef.current,
                        s_id: _stockOrder.s_id,
                        l_id: _stockOrder.l_id
                    }

                    return
                }

                setTableData([])

                return
            } else {

                let [_invoice, _invoiceDetail, _invoiceDetails] = await Promise.all([
                    fetchInvoiceById(token, Number(invoice_id)),
                    fetchInvoiceDetailByField(token, { invoice_id: Number(invoice_id) }),
                    fetchInvoiceDetail(token),
                ])

                let newData = _invoiceDetail.map(row => {
                    return {
                        uid: randomID(),
                        invoice_detail_id: row.invoice_detail_id,
                        p_id: row.p_id,
                        item_description: row.item_description,
                        item_description1: row.item_description1,
                        item_description2: row.item_description2,
                        model_no: row.model_no,
                        imei_req: row.imei_req,
                        cost: row.cost,
                        price: row.price,
                        qty: row.qty,
                        showDescription: false
                    } as ITableData
                })

                invoiceProductRef.current = {
                    ...invoiceProductRef.current,
                    s_id: _invoice.s_id,
                    l_id: _invoice.l_id
                }

                setInvoice(_invoice)
                setInvoiceDetail(_invoiceDetail)
                setHeader("Edit Invoice " + _invoice.running)
                setDate_time(dateToYMD(new Date(_invoice.date_time)))
                setBnd_to_myr(_invoice.bnd_to_myr.toString())
                setBnd_to_usd(_invoice.bnd_to_usd.toString())
                setNotes(_invoice.notes)
                setNotes1(_invoice.notes1)

                setTableData(newData)
                setInvoiceDetails(_invoiceDetails)
            }
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [setAlert, setToken, invoice_id, token, reload, location.state])

    const _button = useMemo(() => {
        if (invoice_id === "new") {
            return [
                {
                    name: "Transfer From Stock Order",
                    color: "red",
                    action: () => setShowStockOrderModal(true)
                }
            ] as BreadCrumbButtonProps[]
        } else {
            return [
                {
                    name: "Download",
                    color: "blue",
                    action: async () => {
                        let _doc = await getInvoiceDoc(invoice!, invoiceDetail)
                        return new PdfHandler(_doc, invoice!.running).download()
                    }
                },
                {
                    name: "View",
                    color: "blue",
                    action: async () => {
                        let _doc = await getInvoiceDoc(invoice!, invoiceDetail)
                        return new PdfHandler(_doc, invoice!.running).view()
                    }
                },
                {
                    name: "Create Duty",
                    color: "green",
                    action: () => {
                        history("/duty/new", {
                            state: {
                                so_id: invoice?.so_id
                            }
                        })
                    }
                }
            ] as BreadCrumbButtonProps[]
        }
    }, [invoice_id, history, invoice, invoiceDetail])

    const handleSubmit = () => {
        let error: string[] = []

        for (let i = 0; i < tableData.length; i++) {
            if (isNaN(Number(tableData[i].price)) || !tableData[i].price || !tableData[i].price.toString().trim()) error = [...error, "Price row " + (i + 1)]
            if (isNaN(Number(tableData[i].qty)) || !tableData[i].qty || !tableData[i].qty.toString().trim()) error = [...error, "Qty row " + (i + 1)]
            if (!tableData[i].item_description || !tableData[i].item_description.trim()) error = [...error, "Item Description row " + (i + 1)]
        }

        if (!date_time) error = [...error, "Date"]
        if (!bnd_to_myr || !bnd_to_myr.trim()) error = [...error, "Bnd To Myr"]
        if (!bnd_to_usd || !bnd_to_usd.trim()) error = [...error, "Bnd To Usd"]

        if (error.length > 0) {
            return setAlert({
                show: true,
                type: "danger",
                message: <Box flexDirection='column' gap="0.5rem" alignItems='flex-start' width="100%">
                    <Box>Error :-</Box>
                    {
                        error.map((element, _) => {
                            return <Box width="100%" key={"error" + _}>
                                {element}
                            </Box>
                        })
                    }
                </Box>
            })
        }

        setAlert({
            show: true,
            message: invoice_id === "new" ? "Create New Invoice ?" : "Update Invoice ?",
            type: "question",
            cb: async () => {
                try {
                    if (invoice_id === "new") {
                        let info = await axios.post("/invoice", {
                            so_id: location.state.so_id,
                            date_time: date_time,
                            notes: notes,
                            notes1: notes1,
                            bnd_to_myr: bnd_to_myr,
                            bnd_to_usd: bnd_to_usd
                        })

                        if (info.status !== 201) {
                            return setAlert({
                                show: true,
                                message: info.data.message,
                                type: "danger"
                            })
                        }


                        let detail = await axios.post("/invoiceDetail/multiple", {
                            invoice_id: info.data.invoice_id,
                            p_id: tableData.map(row => row.p_id),
                            item_description: tableData.map(row => row.item_description),
                            item_description1: tableData.map(row => row.item_description1),
                            item_description2: tableData.map(row => row.item_description2),
                            price: tableData.map(row => row.price),
                            qty: tableData.map(row => row.qty)
                        })

                        if (detail.status !== 201) {
                            return setAlert({
                                show: true,
                                message: info.data.message,
                                type: "danger"
                            })
                        }

                        return setAlert({
                            show: true,
                            message: "Invoice Created Successfully !!!",
                            type: "success",
                            cb: () => {
                                history("/invoice/" + info.data.invoice_id)
                            }
                        })
                    } else {
                        let updateInfo = await axios.patch("/invoice/" + invoice_id, {
                            date_time: date_time,
                            notes: notes,
                            notes1: notes1,
                            bnd_to_myr: bnd_to_myr,
                            bnd_to_usd: bnd_to_usd
                        })

                        if (updateInfo.status !== 200) {
                            return setAlert({
                                show: true,
                                message: updateInfo.data.message,
                                type: "danger"
                            })
                        }

                        let newDetail = tableData.filter(row => !row.invoice_detail_id)
                        let oldDetail = tableData.filter(row => !!row.invoice_detail_id)

                        if (newDetail.length > 0) {
                            let insertDetail = await axios.post("/invoiceDetail/multiple", {
                                invoice_id: invoice_id,
                                p_id: newDetail.map(row => row.p_id),
                                item_description: newDetail.map(row => row.item_description),
                                item_description1: newDetail.map(row => row.item_description1),
                                item_description2: newDetail.map(row => row.item_description2),
                                price: newDetail.map(row => row.price),
                                qty: newDetail.map(row => row.qty)
                            })

                            if (insertDetail.status !== 201) {
                                return setAlert({
                                    show: true,
                                    message: insertDetail.data.message,
                                    type: "danger"
                                })
                            }
                        }

                        let updateDetail = await axios.patch("/invoiceDetail/multiple", {
                            invoice_detail_id: oldDetail.map(row => row.invoice_detail_id),
                            p_id: oldDetail.map(row => row.p_id),
                            item_description: oldDetail.map(row => row.item_description),
                            item_description1: oldDetail.map(row => row.item_description1),
                            item_description2: oldDetail.map(row => row.item_description2),
                            price: oldDetail.map(row => row.price),
                            qty: oldDetail.map(row => row.qty)
                        })

                        if (updateDetail.status !== 200) {
                            return setAlert({
                                show: true,
                                message: updateDetail.data.message,
                                type: "danger"
                            })
                        }

                        return setAlert({
                            show: true,
                            message: "Invoice Updated Successfully !!!",
                            type: "success",
                            cb: () => fetchData()
                        })

                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message
                    })
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
        fetchTransferData()
    }, [fetchData, fetchTransferData])

    return (
        <>
            <Breadcrumb
                items={breadCrumbItems}
                button={_button}
            />
            <PageContent>
                <Card width="100%">
                    <CardHeader border>{header}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="1rem">
                            <StyledBoxInfo>
                                <StyledBoxInfoChild>
                                    <Input label="Date" width="20rem" mediaWidth='100%' type="date" value={date_time} onChange={e => setDate_time(e.target.value)} />
                                    <Input label="Note" width="20rem" mediaWidth='100%' placeholder='Notes...' value={notes} onChange={e => setNotes(e.target.value)} />
                                    <Input label="Extra Note" width="20rem" mediaWidth='100%' placeholder='Notes1...' value={notes1} onChange={e => setNotes1(e.target.value)} />
                                </StyledBoxInfoChild>
                                <StyledBoxInfoChild>
                                    <Input label="BND to MYR" width="20rem" mediaWidth='100%' placeholder='Currency rate...' value={bnd_to_myr} onChange={e => setBnd_to_myr(e.target.value)} />
                                    <Input label="BND to USD" width="20rem" mediaWidth='100%' placeholder='Currency rate...' value={bnd_to_usd} onChange={e => setBnd_to_usd(e.target.value)} />
                                </StyledBoxInfoChild>
                            </StyledBoxInfo>
                            <Box justifyContent='flex-end'>
                                <Button color="green" onClick={handleSubmit}>{invoice_id === "new" ? "Submit" : "Update"}</Button>
                            </Box>
                            <InvoiceTable
                                tableData={tableData}
                                setTableData={setTableData}
                                bnd_to_myr={bnd_to_myr}
                                bnd_to_usd={bnd_to_usd}
                                setShowPriceRefModal={setShowPriceRefModal}
                                invoiceProductRef={invoiceProductRef}
                            />
                        </Box>
                    </CardBody>
                </Card>
            </PageContent>

            <TransferModal
                showModal={showStockOrderModal}
                setShowModal={setShowStockOrderModal}
                stockOrders={stockOrders}
            />

            <PriceRefModal
                showModal={showPriceRefModal}
                setShowModal={setShowPriceRefModal}
                bnd_to_myr={bnd_to_myr}
                bnd_to_usd={bnd_to_usd}
                invoiceDetails={invoiceDetails}
                invoiceProductRef={invoiceProductRef}
            />
        </>
    )
}

export default Invoice