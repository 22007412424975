import { IColorPalette, ThemeType } from "../colorPallete"

export interface ThemePalleteProps {
    themeSetting?: ThemeType
    themesetting?: ThemeType
    pallete?: IColorPalette
}

export interface IBrand {
    b_id: number,
    date_time: Date | string,
    brand: string,
    status: string
}

export interface IProduct {
    p_id: number,
    date_time: Date | string,
    b_id: number,
    brand: string,
    product: string,
    model_no: string,
    imei_req: string,
    cost: number,
    tax: number,
    status: string
}

export interface ISupplier {
    s_id: number,
    date_time: Date | string,
    supplier: string,
    type: "permit" | "order"
}

export interface ILocation {
    l_id: number,
    date_time: Date | string,
    location: string,
    add1: string,
    add2: string,
    add3: string,
    contact_person: string,
    contact_no: string,
    notes: string,
    type: "permit" | "dealer"
    status: string
}

export interface IStockOrder {
    so_id: number
    running_year: number
    running_month: number
    running_no: number
    running: string
    date_time: Date | string
    l_id: number
    location: string
    s_id: number
    supplier: string

    invoice_id: number | null
}

export interface IStockOrderDetail {
    so_detail_id: number
    so_id: number
    s_id: number
    supplier: string
    l_id: number
    location: string
    p_id: number
    product: string
    model_no: string
    imei_req: string
    cost: number
    tax: number
    b_id: number
    brand: string
    qty: number
    running: string
}


export interface IInvoice {
    invoice_id: number
    date_time: Date | string
    so_id: number
    notes: string
    notes1: string
    bnd_to_myr: number
    bnd_to_usd: number

    s_id: number
    supplier: string
    l_id: number
    location: string

    add1: string
    add2: string
    add3: string
    contact_person: string
    contact_no: string

    do_running: string
    running: string
    running_year: number
    running_month: number
    running_no: number
    duty_id: number | null

    packing_id: number | null
}

export interface IInvoiceDetail {
    invoice_detail_id: number
    invoice_id: number
    date_time: Date | string
    running: string
    s_id: number
    supplier: string
    l_id: number
    location: string
    b_id: number
    brand: string
    p_id: number | null
    product: string
    item_description: string
    item_description1: string
    item_description2: string
    model_no: string
    imei_req: string,
    cost: number
    tax: number
    price: number
    qty: number
}

export interface IDuty {
    duty_id: number
    date_time: Date | string
    so_id: number
    bnd_to_myr: number

    s_id: number
    l_id: number
    supplier: string
    location: string
    running: string
}

export interface IDutyDetail {
    duty_detail_id: number
    duty_id: number
    so_id: number
    running: string
    l_id: number
    location: string
    product: string
    p_id: number
    model_no: string
    imei_req: string
    tax: number
    b_id: number
    brand: string
    cost: number
    duty: number
    transport: number
    qty: number
    claim: string
}

export interface IDutyBill {
    duty_bill_id: number,
    date_time: Date | string,
    duty_id: number
    l_id: number
    notes: string
    running: string
}

export interface IPacking {
    packing_id: number,
    date_time: Date | string
    so_id: number
    l_id: number
    s_id: number
    supplier: string
    location: string
    carton: number

    add1: string
    add2: string
    add3: string
    contact_person: string
    contact_no: string
    running: string
}

export interface IPackingDetail {
    packing_detail_id: number
    packing_id: number
    so_id: number
    running: string
    p_id: number
    product: string
    b_id: number
    brand: string
    model_no: string
    qty: number
    weight: number
    notes: string
}

export enum EClaimType {
    ALL = "all",
    DUTY_ONLY = "duty only",
    TRANSPORT_ONLY = "transport only",
    EXEMPTED = "exempted"
}