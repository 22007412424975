import React from 'react'
import styled from "styled-components"
import { AiOutlineMenu } from "react-icons/ai"
import { FiLogOut } from "react-icons/fi"
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md"
import { ThemePalleteProps } from '../types/types'
import useThemeSetting from '../hooks/useThemeSetting'
import axios from 'axios'
import useLogin from '../hooks/useLogin'
import useAlert from '../hooks/useAlert'
import useSidebar from '../hooks/useSidebar'
import { useNavigate } from 'react-router-dom'
import Box from './Box'

export const TOPBAR_HEIGHT = "4rem"
export const TOPBAR_HEIGHT_MEDIA = "3rem"

const TopbarContainer = styled.div<ThemePalleteProps>`
    z-index: 2;
    box-sizing: border-box;
    position: sticky;
    top:0;
    left: 0;
    right: 0;
    height: ${TOPBAR_HEIGHT};
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    padding: ${props => props.themeSetting?.spacing.sm}rem;
    background-color: ${props => props.themeSetting?.pallete.background.default};
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        height: ${TOPBAR_HEIGHT_MEDIA};
    }
`

const TitleContainer = styled.div<ThemePalleteProps>`
    font-size: ${props => props.themeSetting?.typography.h5.fontSize}rem;
    color: ${props => props.themeSetting?.pallete.neutral.light};
    margin: 0 1rem;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: calc(${props => props.themeSetting?.typography.h5.fontSize}rem * 0.8);
    }
`

const TopbarIconGroupContainer = styled.div`
    display: flex;

    @media (max-width: 768px) {
        margin-left: auto;
    }
`

const TopbarIconContainer = styled.div<ThemePalleteProps>`
    border-radius: 50%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.pallete?.grey[900]};
    }

    &:active {
        background-color: ${props => props.themeSetting?.pallete.neutral.main};
    }
`

const InputGroupContainer = styled.div`
    display: flex;
    user-select: none;
    align-items: center;
`

const MenuIcon = styled(AiOutlineMenu) <ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
    }
`

// const NotificationIcon = styled(MdOutlineNotificationsNone) <ThemePalleteProps>`
//     color: ${props => props.themesetting?.pallete.neutral.light};
//     font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;

//     @media (max-width: 768px) {
//         font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
//     }
// `
// const SettingIcon = styled(AiOutlineSetting) <ThemePalleteProps>`
//     color: ${props => props.themesetting?.pallete.neutral.light};
//     font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;

//     @media (max-width: 768px) {
//         font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
//     }
// `
// const UserIcon = styled(AiOutlineUser) <ThemePalleteProps>`
//     color: ${props => props.themesetting?.pallete.neutral.light};
//     font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;

//     @media (max-width: 768px) {
//         font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
//     }
// `
const LightIcon = styled(MdOutlineLightMode) <ThemePalleteProps>`
    color: ${props => props.themesetting?.pallete.neutral.light};
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
    }
`
const DarkIcon = styled(MdOutlineDarkMode) <ThemePalleteProps>`
    color: ${props => props.themesetting?.pallete.neutral.light};
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
    }
`

const SignoutIcon = styled(FiLogOut) <ThemePalleteProps>`
    color: ${props => props.themesetting?.pallete.neutral.light};
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem);
    }
`

const Topbar = () => {
    let history = useNavigate()

    const { toggleColorMode, theme, pallete } = useThemeSetting()
    // const [search, setSearch] = useState("")
    const { setAuth, setUser, setToken } = useLogin()
    const { setAlert } = useAlert()

    const {setIsCollapse} = useSidebar()

    const handleSignout = () => {
        setAlert({
            show: true,
            message: "Logout ?",
            type: "question",
            cb: async () => {
                setAuth(true)
                let result = await axios.get("/auth/logout")
                if (result.status === 200) {
                    setUser({
                        username: "",
                        email: "",
                        role: ""
                    })
                    setToken("")
                    setAuth(false)
                }
            }
        })
    }

    return (
        <TopbarContainer themeSetting={theme} pallete={pallete}>
            <Box alignItems='center'>
                <InputGroupContainer>
                    <TopbarIconContainer themeSetting={theme} pallete={pallete} onClick={() => setIsCollapse(v => !v)}>
                        <MenuIcon themesetting={theme} />
                    </TopbarIconContainer>
                    <TitleContainer themeSetting={theme} pallete={pallete} onClick={() => history("/home")}>Admin</TitleContainer>
                </InputGroupContainer>
                {/* <StyledBoxContainer>
                    <Input
                        width='30rem'
                        type="text"
                        iconType='search'
                        placeholder='Search'
                        value={search}
                        onChange={e => setSearch(e.target.value)} />
                </StyledBoxContainer> */}
            </Box>
            <TopbarIconGroupContainer>
                <TopbarIconContainer themeSetting={theme} pallete={pallete} onClick={() => toggleColorMode()}>
                    {
                        theme.pallete.mode === "dark"
                            ? <LightIcon themesetting={theme} />
                            : <DarkIcon themesetting={theme} />
                    }
                </TopbarIconContainer>
                {/* <TopbarIconContainer themeSetting={theme} pallete={pallete}>
                    <NotificationIcon themesetting={theme} />
                </TopbarIconContainer>
                <TopbarIconContainer themeSetting={theme} pallete={pallete}>
                    <SettingIcon themesetting={theme} />
                </TopbarIconContainer>
                <TopbarIconContainer themeSetting={theme} pallete={pallete}>
                    <UserIcon themesetting={theme} />
                </TopbarIconContainer> */}
                <TopbarIconContainer themeSetting={theme} pallete={pallete}>
                    <SignoutIcon themesetting={theme} onClick={handleSignout} />
                </TopbarIconContainer>
            </TopbarIconGroupContainer>
        </TopbarContainer>
    )
}

export default Topbar