import React, { createContext } from 'react'
import { InitialAlertProps } from './AlertState'

export interface IAlertContext {
    alert: InitialAlertProps
    setAlert: React.Dispatch<React.SetStateAction<InitialAlertProps>>
    initialAlert: InitialAlertProps
}


export const alertContext = createContext<null | IAlertContext>(null)