import React from 'react'
import styled from 'styled-components'

interface Props {
    children?: React.ReactNode
}

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 5;
    background-color: black;
    opacity: 0.5;
    font-size: 1.6rem;
    color: white;
`

const AuthContainer = (props: Props) => {
    return (
        <StyledContainer>{props.children || "Authenticate..."}</StyledContainer>
    )
}

export default AuthContainer