import React from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface Props {
    children?: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    color?: "blue" | "red" | "green"
    autoFocus?: boolean
    forwardedRef?: React.RefObject<HTMLButtonElement>
}

const switchColor = (props: ThemePalleteProps, color?: Props["color"]) => {
    switch(color) {
        case "blue":
            return props.pallete?.blue["500"];
        case "red":
            return props.pallete?.red["600"];
        case "green":
            return props.pallete?.green["400"];
        default:
            return null;
    }
}


const StyledButton = styled.button<ThemePalleteProps & { color?: Props["color"] }>`
    color: #ffffff;
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    background-color: ${props => switchColor(props, props.color)};
    padding: calc(${props => props.themeSetting?.spacing.sm}rem/3) calc(${props => props.themeSetting?.spacing.sm}rem / 1.5);
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0.3rem;
    font-weight: 600;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.7;
    }

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        padding: calc(${props => props.themeSetting?.spacing.sm}rem/4) calc(${props => props.themeSetting?.spacing.sm}rem / 2);
    }
`

const Button = (props: Props) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledButton 
        ref={props.forwardedRef}
        autoFocus={props.autoFocus}
        themeSetting={theme}
        pallete={pallete}
        color={props.color}
        onClick={props.onClick}>{props.children}</StyledButton>
    )
}

export default Button