import React from 'react'
import styled from 'styled-components'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'
import DownArrow from "../images/angle-down-solid.svg"

interface SeelectProps {
    label?: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLSelectElement>
    width?: string
    labelWidth?: number | string
    direction?: "column" | "row"
    children?: React.ReactNode
    mediaWidth?: string
}


const StyledSelectContainer = styled.div<ThemePalleteProps & {direction?: "column" | "row", width?: string, mediaWidth?: string }>`
    display: flex;
    gap: 0.3rem;
    flex-direction: ${props => props.direction ? props.direction : "column"};
    width: ${props => props.width === "100%" ? props.width : null};
    align-items: ${props => (
        !props.direction
        ? "flex-start"
        : props.direction === "row" ? "center" : "flex-start" 
    )};

    & .fa-angle-down {
        color: white;
    }

    @media (max-width: 768px) {
        width: ${props => props.mediaWidth ? props.mediaWidth : null};
    }
`

const StyledSelectLabel = styled.label<ThemePalleteProps & {labelWidth?: string | number}>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    font-weight: 500;
    margin-left: 0.3rem;
    user-select: none;


    width: ${props => props.labelWidth ? (typeof props.labelWidth === "number" ? `${props.labelWidth}rem` : props.labelWidth) : null};

    @media (max-width: 768px) {
        gap: 0.2rem;
        font-size: ${props => props.themeSetting?.typography.media}rem;
	}
`

const StyledSelect = styled.select<ThemePalleteProps & { width?: string, mediaWidth?: string }>`
    box-sizing: border-box;
    line-height: 2;
    padding: 0 0.6rem;
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    border: none;
    outline: none;
    user-select: none;
    border-radius: 0.3rem;

    appearance: none; 
    -webkit-appearance: none;
    -moz-appearance: none;

    background: url(${DownArrow});
    background-repeat: no-repeat;
    background-size: 0.8rem;
    background-position: 96% 50%;

    background-color: #d0d1d5;
    color: #040509;

    width: ${props => props.width};


    box-shadow: inset 0 0.1rem 0.2rem rgba(0,0,0,.39), 
                        0 -0.1rem 0.1rem ${props => props.themeSetting?.pallete.mode === "dark" ? "#434957" : "#fff"}, 
                        0 0.1rem 0 ${props => props.themeSetting?.pallete.mode === "dark" ? "#434957" : "#fff"};

    :focus {
        background-color: #e1e2fe;
    }

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        padding: 0.1rem 0.5rem;
        width: ${props => props.mediaWidth ? props.mediaWidth : null};
	}
`



const Select = (props: SeelectProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledSelectContainer 
        themeSetting={theme} 
        direction={props.direction} 
        width={props.width}
        mediaWidth={props.mediaWidth}
        >
            {props.label && <StyledSelectLabel themeSetting={theme} labelWidth={props.labelWidth}>{props.label}</StyledSelectLabel>}
            <StyledSelect 
            themeSetting={theme} 
            pallete={pallete} 
            width={props.width} 
            mediaWidth={props.mediaWidth}
            value={props.value} 
            onChange={props.onChange}>
                {props.children}
            </StyledSelect>
        </StyledSelectContainer>
    )
}

export default Select