import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import ManageTable from '../components/ManageTable'
import { TableHeaderDataProps } from '../components/Table'
import TextButton from '../components/TextButton'
import { fetchDuty, fetchDutyDetail } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import PdfDefinition from '../helpers/pdfDefinition'
import PdfHandler from '../helpers/pdfHandler'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import {DeleteIcon, DownloadIcon, EditIcon} from '../icons/icon'
import { IDuty } from '../types/types'

const breadCrumbItems = [
    { name: "Duty Maintenance", active: true },
]

let defaultHeader: TableHeaderDataProps[] = [
    { key: "duty_id", name: "ID", width: "4%", sort: "asc" },
    { key: "date_time", name: "Date", width: "6%", sort: "unsort" },
    { key: "show_running", name: "Running", width:"13%", sort: "unsort" },
    { key: "supplier", name: "Invoice Header", sort: "unsort" },
    { key: "location", name: "Permit Holder", sort: "unsort" },
    { key: "bnd_to_myr", name: "Bnd To Myr", sort: "unsort" },
    { key: "action", name: "Action", align: "center", width: "5%" },
]

const DutyList = () => {
    let history = useNavigate()
    const {token, setToken} = useLogin()
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)

    const {setAlert} = useAlert()

    const handleDelete = useCallback((_data: IDuty) => {
        setAlert({
            show: true,
            message: "Delete Duty # " + _data.running + " ?",
            type: "question",
            cb: async () => {
                try {
                    let result = await axios.delete("/duty/" + _data.duty_id)
        
                    if(result.status !== 200) {
                        setAlert({
                            show: true,
                            message: result.data.message,
                            type: "danger"
                        })
                    }
        
                    return setAlert({
                        show: true,
                        message: "Duty Deleted Successfully !!!",
                        type: "success",
                        cb: () => {
                            setData(v => v.filter(row => row.duty_id !== _data.duty_id))
                        }
                    })
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message
                    })
                }
            }
        })
    }, [setAlert])

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            let [_data, _detail] = await Promise.all([
                fetchDuty(token), fetchDutyDetail(token)
            ])

            if(Array.isArray(_data)) {
                setData(_data.sort((a, b) => a.duty_id - b.duty_id).map(row => ({ 
                    ...row,
                    show_running: <Box justifyContent='space-between' alignItems='center'>
                    <TextButton
                        onClick={() => {
                            let currentDetail = _detail.filter(el => el.so_id === row.so_id)
                            let _doc = PdfDefinition.aplusCustomDuty(row, currentDetail)
                            return new PdfHandler(_doc, row.running).view()
                        }}
                    >{row.running}</TextButton>
                    <DownloadIcon iconcolor="red" onClick={() => {
                        let currentDetail = _detail.filter(el => el.so_id === row.so_id)
                        let _doc = PdfDefinition.aplusCustomDuty(row, currentDetail)
                        return new PdfHandler(_doc, row.running).download()
                    }} />
                </Box>,
                    action: <Box alignItems='center' justifyContent='center'>
                        <EditIcon iconcolor="blue" iconsize='h6' onClick={() => history("/duty/" + row.duty_id)} />
                        <DeleteIcon iconcolor="red" iconsize='h6' onClick={() => handleDelete(row)} />
                    </Box>
                })))
                setLoading(false)
            }
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, history, setAlert, setToken, reload, handleDelete])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb items={breadCrumbItems} 
            button={[
                {
                    name: "Duty Bill Maintenance",
                    action: "/duty/dutyBill",
                    color: "blue"
                },
                {
                    name: "New",
                    action: "/duty/new",
                    color: "blue"
                }
            ]} 
            />
            <ManageTable 
            defaultHeader={defaultHeader}
            columnSetting="dutyList"
            data={data}
            filterKey={["running", "location", "supplier"]}
            loading={loading}
            />
        </>
    )
}

export default DutyList