import React, { createContext } from "react"

export interface UserStoreProps {
    username: string
    email: string
    role?: string | null
}

export interface LoginContextProps {
    user: UserStoreProps | null
    setUser: React.Dispatch<React.SetStateAction<UserStoreProps | null>>
    token: string
    setToken: React.Dispatch<React.SetStateAction<string>>
    auth: boolean
    setAuth: React.Dispatch<React.SetStateAction<boolean>>
}

const loginContext = createContext<LoginContextProps | null>(null) 

export default loginContext