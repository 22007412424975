import styled from "styled-components"
import { ThemePalleteProps } from '../types/types'
import React, { useCallback, useRef, useState } from "react"
import { BiLibrary, BiBuildingHouse } from "react-icons/bi"
import { AiOutlineHdd, AiOutlineShoppingCart, AiOutlineFileText, AiOutlineMoneyCollect } from "react-icons/ai"
import { IoLocationOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import useThemeSetting from "../hooks/useThemeSetting"
import { TOPBAR_HEIGHT, TOPBAR_HEIGHT_MEDIA } from "./Topbar"
import useSidebar from "../hooks/useSidebar"

export const SIDEBAR_WIDTH = "4rem"
export const SIDEBAR_WIDTH_MEDIA = "0"

const SidebarContainer = styled.div<ThemePalleteProps>`
    position: absolute;
    display: block;
	box-sizing: border-box;
    height: calc(100vh - ${TOPBAR_HEIGHT});
    width: ${SIDEBAR_WIDTH};
    transition: width 300ms ease-in-out;
    background-color: ${props => props.themeSetting?.pallete.background.default};
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    z-index: 1;


    &.active {
        width: 20rem;
    }

    &.active .top .title {
        visibility: visible;
        opacity: 1;
    }

    &.active .profile .text {
        visibility: visible;
        opacity: 1;
        margin-top: 2rem;
        height: 3rem;
    }

    &.active .item {
        margin-left: 2rem;
    }

    &.active .item .text {
        width: 100%;
    }
  
    &.active .itemList .title {
        height: 1.5rem;
        visibility: visible;
        opacity: 0.7;
        margin-bottom: 0.5rem;
    }

    &.active .itemList .text {
        visibility: visible;
        opacity: 1;
    }

    &.active .itemList .border {
        visibility: hidden;
        opacity: 0;
        margin: 0 1rem;
    }

    @media (max-width: 768px) {
        position: absolute;
        height: calc(100vh - ${TOPBAR_HEIGHT_MEDIA});
        width: ${SIDEBAR_WIDTH_MEDIA};

        &.active {
            width: 40%;
        }
    }
`

const SidebarTooltip = styled.div<ThemePalleteProps>`
    display: inline;
    position: absolute;
    background-color: ${props => props.pallete?.grey[800]};
    color: ${props => props.themeSetting?.pallete.neutral.light};
    padding: 0.75rem;
    border-radius: 1rem;
    visibility: hidden;
    opacity: 0;
    word-wrap: normal;
    font-weight: 600;
    user-select: none;
    white-space: nowrap;
    z-index: 1;

    &.active {
        visibility: visible;
        opacity: 1;
    }

    @media (max-width: 768px) {
        display: none;
    }
`

const MenuContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 0.5rem;

    height: 70%;
    overflow-y: scroll;
    overflow-x: hidden;
  
`

const MenuTitle = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    font-size: ${props => props.themeSetting?.typography.h6.fontSize}rem;
    margin-left: 1rem;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    height: 0;

    transition: height 300ms ease-in-out, visibility 300ms ease-in-out, opacity 300ms ease-in-out, margin-bottom 300ms ease-in-out;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    }
`

const MenuBorder = styled.div<ThemePalleteProps>`
    border-bottom: 0.1rem solid ${props => props.themeSetting?.pallete.neutral.light};
    opacity: 0.3;
    visibility: visible;
    user-select: none;
    margin: 0.5rem 1rem;

    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out, margin 300ms ease-in-out;
`

const MenuItemContainer = styled.div<ThemePalleteProps>`
    display: flex;
    align-items: center;
    width: auto;

    border-radius: 2rem;
    cursor: pointer;
    margin: 0 0.75rem;
    padding: 0.5rem;

    transition: margin-left 300ms ease-in-out;

    @media (max-width: 768px) {
        margin: 0 0.5rem;
    }
`

const MenuItemText = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    font-size: ${props => props.themeSetting?.typography.h6.fontSize}rem;
    visibility: hidden;
    opacity: 0;
    width: 0;
    user-select: none;
    margin-left: 1.5rem;

    transition: width 400ms ease-in-out, visibility 400ms ease-in-out, opacity 400ms ease-in-out;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
    }
`

const MenuIconContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BrandIcon = styled(BiLibrary) <ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`

const LocationIcon = styled(IoLocationOutline) <ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`

const SupplierIcon = styled(BiBuildingHouse) <ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`


const ItemIcon = styled(AiOutlineHdd) <ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`

const InvoiceIcon = styled(AiOutlineFileText) <ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`

const OrderIcon = styled(AiOutlineShoppingCart)<ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`

const DutyIcon = styled(AiOutlineMoneyCollect)<ThemePalleteProps>`
    font-size: ${props => props.themesetting?.typography.h5.fontSize}rem;
    color: ${props => props.themesetting?.pallete.font.light};

       @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.h6.fontSize}rem;
    }
`

const initialToolTip = {
    top: 0,
    left: 0,
    active: false,
    text: ""
}

const Sidebar = () => {
    let history = useNavigate()
    const { theme, pallete } = useThemeSetting()
    const [tooltip, setTooltip] = useState(initialToolTip)

    const sidebarRef = useRef<HTMLDivElement>(null)

    const {isCollapse, setIsCollapse} = useSidebar()

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>, text: string) => {
        setTooltip({
            top: e.currentTarget.getBoundingClientRect().top - 40,
            left: e.currentTarget.clientWidth + 25,
            active: true,
            text
        })
    }

    const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => setTooltip(initialToolTip)

    const handleNavPage = useCallback((page: string) => {
        history("/" + page)
        if(isCollapse) {
            setIsCollapse(v => !v)
        }
    }, [isCollapse, setIsCollapse, history])

    return (
        <SidebarContainer themeSetting={theme} ref={sidebarRef} className={isCollapse ? "sidebar active" : "sidebar"}>
            <SidebarTooltip
                themeSetting={theme}
                pallete={pallete}
                style={{ top: tooltip.top, left: tooltip.left }}
                className={"sidebar_tooltip" + (tooltip.active ? " active" : "")}>
                    {tooltip.text}
            </SidebarTooltip>
            <MenuContainer className="itemList">
                <MenuTitle themeSetting={theme} className="title">Setup</MenuTitle>
                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("location")}
                    onMouseEnter={e => handleMouseEnter(e, "Location")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <LocationIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Location</MenuItemText>
                </MenuItemContainer>

                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("supplier")}
                    onMouseEnter={e => handleMouseEnter(e, "Supplier")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <SupplierIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Supplier</MenuItemText>
                </MenuItemContainer>

                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("brand")}
                    onMouseEnter={e => handleMouseEnter(e, "Brand")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <BrandIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Brand</MenuItemText>
                </MenuItemContainer>

                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("product")}
                    onMouseEnter={e => handleMouseEnter(e, "Product")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <ItemIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Product</MenuItemText>
                </MenuItemContainer>

                <MenuTitle themeSetting={theme} className="title">Operation</MenuTitle>
                <MenuBorder themeSetting={theme} className="border" />

                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("stockOrder")}
                    onMouseEnter={e => handleMouseEnter(e, "Stock Order")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <OrderIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Order</MenuItemText>
                </MenuItemContainer>

                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("invoice")}
                    onMouseEnter={e => handleMouseEnter(e, "Invoice")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <InvoiceIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Invoice</MenuItemText>
                </MenuItemContainer>

                <MenuItemContainer className="item"
                    pallete={pallete}
                    themeSetting={theme}
                    onClick={() => handleNavPage("duty")}
                    onMouseEnter={e => handleMouseEnter(e, "Duty")}
                    onMouseLeave={handleMouseLeave}
                >
                    <MenuIconContainer>
                        <DutyIcon themesetting={theme} />
                    </MenuIconContainer>
                    <MenuItemText className="text" themeSetting={theme}>Duty</MenuItemText>
                </MenuItemContainer>
              
            </MenuContainer>
        </SidebarContainer>
    )
}

export default Sidebar