import { IDuty, IDutyDetail, IInvoice, IInvoiceDetail, IStockOrder, IStockOrderDetail, EClaimType } from "../types/types"

import AplusLogo from "../images/apluslogo.png"
import AplusSign from "../images/aplussign.png"

import PrimenetLogo from '../images/primenetlogo.png'
import PrimenetSign from '../images/primenetsign.png'

import beizhu_url from "../images/beizhu.png"
import kehu_url from "../images/kehu.png"
import dizhi_url from "../images/dizhi.png"
import lianxiren_url from "../images/lianxiren.png"
import riqi_url from "../images/riqi.png"
import yewuyuandaima_url from "../images/yewuyuandaima.png"
import xitongdanhao_url from "../images/xitongdanhao.png"
import fukuanfangshi_url from "../images/fukuanfangshi.png"
import huowuneirong_url from "../images/huowuneirong.png"
import shuliang_url from "../images/shuliang.png"
import danjia_url from "../images/danjia.png"
import jiner_url from "../images/jiner.png"

import sign_url from "../images/srkl_signature.png"
import urlToBase64 from "./urlToBase64"

interface TableCellProps {
    text: string | number,
    fontSize?: number,
    alignment?: string,
    border?: boolean[],
    margin?: number[],
    bold?: boolean,
    colSpan?: number,
    rowSpan?: number,
    color?: string,
    image?: string
}

const tableLayoutStyle = {
    hLineWidth: (bypass: null | number, outerLineWidth?: number, innerLineWidth?: number) => {
        return (i: any, node: any) => {
            if(bypass !== null) {
                return bypass
            }
            return (i === 0 || i === node.table.body.length) ? outerLineWidth : innerLineWidth;
        }
    },
    vLineWidth: (bypass: null | number, outerLineWidth?: number, innerLineWidth?: number) => {
        return (i: any, node: any) => {
            if(bypass !== null) {
                return bypass
            }
            return (i === 0 || i === node.table.widths.length) ? outerLineWidth : innerLineWidth;
        }
    },
    hLineColor: (bypass: null | string, outerLineColor?: string, innerLineColor?: string) => {
        return (i: any, node: any) => {
            if(bypass !== null) {
                return bypass
            }
            return (i === 0 || i === node.table.body.length) ? outerLineColor : innerLineColor;
        }
    },
    vLineColor: (bypass: null | string, outerLineColor?: string, innerLineColor?: string) => {
        return (i: any, node: any) => {
            if(bypass !== null) {
                return bypass
            }
            return (i === 0 || i === node.table.widths.length) ? outerLineColor : innerLineColor;
        }
    },
    fillColor: (bypass: null | string, color?: string) => {
        return (i: any) => {
            if(bypass !== null) {
                return bypass
            }
            return (i % 2 !== 0) ?  color : null;
        }
    },
    paddingTop: (padding: number) => {
        return (i: any) => {
            return padding
        }
    },
    paddingBottom: (padding: number) => {
        return (i: any) => {
            return padding
        }
    },
    paddingLeft: (padding: number) => {
        return (i: any) => {
            return padding
        }
    },
    paddingRight: (padding: number) => {
        return (i: any) => {
            return padding
        }
    }
}

class PdfDefinition {

    static customStockOrder (info: IStockOrder, detail: IStockOrderDetail[]): any {
        detail.sort((a, b) => {
            if(a.brand > b.brand) return 1
            if(a.brand < b.brand) return -1
            if(a.product > b.product) return 1
            if(a.product < b.product) return -1
            return 0
        })
    
        let uniqueProduct = detail.map(row => ({ b_id: row.b_id, brand: row.brand, p_id: row.p_id, product: row.product, model_no: row.model_no })).filter((v, i, s) => {
            return s.map(el => el.p_id).indexOf(v.p_id) === i
        })
    
        let uniqueLocation = detail.map(row => ({ l_id: row.l_id, location: row.location })).filter((v, i, s) => {
            return s.map(el => el.l_id).indexOf(v.l_id) === i
        })
    
        let tableBody: (TableCellProps | string)[][] = []
        let widths = [ "auto", "*", "15%" ]
    
        let tableHeader = [
            {
                text: "#",
                alignment: "center",
                fontSize: 7,
                border: [ false, false, true, true ]
            },
            {
                text: "Product Description",
                fontSize: 7,
                border: [ false, false, true, true ]
            },
            {
                text: "Model",
                fontSize: 7,
                border: [ false, false, true, true ]
            }
        ]
    
        uniqueLocation.forEach(row => {
            let _th = {
                text: row.location,
                fontSize: 7,
                alignment: "center",
                border: [ false, false, true, true ]
            }
    
            tableHeader = [ ...tableHeader, _th ]
            widths = [ ...widths, "7%" ]
        })
    
        tableHeader = [ ...tableHeader, {
                text: "Total",
                fontSize: 7,
                alignment: "center",
                border: [ false, false, false, true ]
        }]

    
        widths = [ ...widths, "auto" ]
    
        tableBody = [ ...tableBody, tableHeader ]
    
        let n = 1
        uniqueProduct.forEach(row => {
            let tableRow: TableCellProps[] = [
                {
                    text: n++,
                    fontSize: 7,
                    alignment: "center",
                    border: [ false, false, true, false ]
                },
                {
                    text: row.brand + " " + row.product,
                    fontSize: 7,
                    border: [ false, false, true, false ]
                },
                {
                    text: row.model_no,
                    fontSize: 7,
                    border: [ false, false, true, false ]
                }
            ]
    
            uniqueLocation.forEach(el => {
                let locationProductQuantity = detail.reduce((total, prod) => {
                    if(el.l_id === prod.l_id && row.p_id === prod.p_id) total += prod.qty 
                    return total
                }, 0)
    
                tableRow = [ ...tableRow, {
                    text: locationProductQuantity === 0 ? "" : locationProductQuantity,
                    fontSize: 7,
                    alignment: "center",
                    border: [ false, false, true, false ]
                } ]
            })
    
            let totalProductQuantity = detail.reduce((total, prod) => {
                if(row.p_id === prod.p_id) total += prod.qty 
                return total
            }, 0)
    
            tableRow = [ ...tableRow, {
                text: totalProductQuantity,
                fontSize: 7,
                alignment: "center",
                border: [ false, false, false, false ]
            }]
    
            tableBody = [ ...tableBody, tableRow ]
        })
    
        let finalRow: (TableCellProps | string)[] = [
            {
                text: "Total",
                fontSize: 7,
                colSpan: 3,
                alignment: "right",
                border: [ false, true, true, false ]
            }, "", ""
        ]
    
        uniqueLocation.forEach(row => {
            let totalLocationProductQuantity = detail.reduce((total, el) => {
                if(row.l_id === el.l_id) total += el.qty 
                return total
            }, 0)
    
            finalRow = [ ...finalRow, {
                text: totalLocationProductQuantity,
                fontSize: 7,
                alignment: "center",
                border: [ false, true, true, false ]
            } ]
        })
    
        let allTotalQuantity = detail.reduce((total, row) => total + row.qty, 0)
    
        finalRow = [ ...finalRow, {
            text: allTotalQuantity,
            fontSize: 7,
            alignment: "center",
            border: [ false, true, false, false ]
        } ]
    
        tableBody = [ ...tableBody, finalRow  ]

        let table = {
            layout: {
                defaultBorder: false,
                hLineColor: tableLayoutStyle.hLineColor("#D3D3D3"),
                hLineWidth: tableLayoutStyle.hLineWidth(0.5),
                vLineColor: tableLayoutStyle.vLineColor("#D3D3D3"),
                vLineWidth: tableLayoutStyle.vLineWidth(0.5),
                paddingTop: tableLayoutStyle.paddingTop(1.5),
                paddingBottom: tableLayoutStyle.paddingBottom(1.5),
                fillColor: tableLayoutStyle.fillColor(null,  "#f7f7f7")
            },
            width: "*",
            margin: [ 20, 0, 20, 10 ],
            table: {
                headerRows: 1,
                widths: widths,
                body: tableBody
            }
        }
    
        return {
            info: {
                title: info.running
            },
            pageSize: "A4",
            pageOrientation: "landscape",
            pageMargins: [ 20, 100, 20, 0 ],
            header: (currentPage: number, pageCount: number) => {
                return [
                    {
                        margin: [ 50, 25, 0, 2 ],
                        columns: [
                            { text: "Date", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: new Date(info.date_time).toLocaleDateString("en-GB"), width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Doc No.", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.running, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Invoice Header", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.supplier, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Permit Holder", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.location, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Page", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text:  currentPage + " of " + pageCount, width: "*", fontSize: 8 },
                        ]
                    }
                ]
            },
            content: [
                [table]
            ]
        }
    }

    static customStockOrderSupplier (info: IStockOrder, detail: IStockOrderDetail[]): any {
        let uniqueSupplier = detail.map(row => ({ s_id: row.s_id, supplier: row.supplier })).filter((v, i, s) => s.map(row => row.s_id).indexOf(v.s_id) === i)

        let uniqueProduct = detail.map(row => ({ b_id: row.b_id, brand: row.brand, p_id: row.p_id, product: row.product, model_no: row.model_no })).filter((v, i, s) => {
            return s.map(el => el.p_id).indexOf(v.p_id) === i
        })
    
        let tableBody: (TableCellProps | string)[][] = []
        let widths = [ "auto", "*", "15%" ]

        let tableHeader = [
            {
                text: "#",
                alignment: "center",
                fontSize: 7,
                border: [ false, false, true, true ]
            },
            {
                text: "Product Description",
                fontSize: 7,
                border: [ false, false, true, true ]
            },
            {
                text: "Model",
                fontSize: 7,
                border: [ false, false, true, true ]
            }
        ]

        uniqueSupplier.forEach(row => {
            let _th = {
                text: row.supplier,
                fontSize: 7,
                alignment: "center",
                border: [ false, false, true, true ]
            }
    
            tableHeader = [ ...tableHeader, _th ]
            widths = [ ...widths, "7%" ]
        })
    
        tableHeader = [ ...tableHeader, {
            text: "Total",
            fontSize: 7,
            alignment: "center",
            border: [ false, false, false, true ]
        }]

        widths = [ ...widths, "auto" ]
    
        tableBody = [ ...tableBody, tableHeader ]


        for(let i=0; i<uniqueProduct.length; i++) {
            let tableRow: TableCellProps[] = [
                {
                    text: i+1,
                    fontSize: 7,
                    alignment: "center",
                    border: [ false, false, true, false ]
                },
                {
                    text: uniqueProduct[i].brand + " " + uniqueProduct[i].product,
                    fontSize: 7,
                    border: [ false, false, true, false ]
                },
                {
                    text: uniqueProduct[i].model_no,
                    fontSize: 7,
                    border: [ false, false, true, false ]
                }
            ]


            uniqueSupplier.forEach(el => {
                let supplierProductQuantity = detail.reduce((total, prod) => {
                    if(el.s_id === prod.s_id && uniqueProduct[i].p_id === prod.p_id) total += prod.qty 
                    return total
                }, 0)
    
                tableRow = [ ...tableRow, {
                    text: supplierProductQuantity === 0 ? "" : supplierProductQuantity,
                    fontSize: 7,
                    alignment: "center",
                    border: [ false, false, true, false ]
                } ]
            })


            let totalProductQuantity = detail.reduce((total, prod) => {
                if(uniqueProduct[i].p_id === prod.p_id) total += prod.qty 
                return total
            }, 0)
    
            tableRow = [ ...tableRow, {
                text: totalProductQuantity,
                fontSize: 7,
                alignment: "center",
                border: [ false, false, false, false ]
            }]
    
            tableBody = [ ...tableBody, tableRow ]
        }

        let finalRow: (TableCellProps | string)[] = [
            {
                text: "Total",
                fontSize: 7,
                colSpan: 3,
                alignment: "right",
                border: [ false, true, true, false ]
            }, "", ""
        ]
    
        uniqueSupplier.forEach(row => {
            let totalSupplierProductQuantity = detail.reduce((total, el) => {
                if(row.s_id === el.s_id) total += el.qty 
                return total
            }, 0)
    
            finalRow = [ ...finalRow, {
                text: totalSupplierProductQuantity,
                fontSize: 7,
                alignment: "center",
                border: [ false, true, true, false ]
            } ]
        })
    
        let allTotalQuantity = detail.reduce((total, row) => total + row.qty, 0)
    
        finalRow = [ ...finalRow, {
            text: allTotalQuantity,
            fontSize: 7,
            alignment: "center",
            border: [ false, true, false, false ]
        } ]

        tableBody = [ ...tableBody, finalRow  ]


        let table = {
            layout: {
                defaultBorder: false,
                hLineColor: tableLayoutStyle.hLineColor("#D3D3D3"),
                hLineWidth: tableLayoutStyle.hLineWidth(0.5),
                vLineColor: tableLayoutStyle.vLineColor("#D3D3D3"),
                vLineWidth: tableLayoutStyle.vLineWidth(0.5),
                paddingTop: tableLayoutStyle.paddingTop(1.5),
                paddingBottom: tableLayoutStyle.paddingBottom(1.5),
                fillColor: tableLayoutStyle.fillColor(null,  "#f7f7f7")
            },
            width: "*",
            margin: [ 20, 0, 20, 10 ],
            table: {
                headerRows: 1,
                widths: widths,
                body: tableBody
            }
        }
    
        return {
            info: {
                title: info.running
            },
            pageSize: "A4",
            pageOrientation: "landscape",
            pageMargins: [ 20, 100, 20, 0 ],
            header: (currentPage: number, pageCount: number) => {
                return [
                    {
                        margin: [ 50, 25, 0, 2 ],
                        columns: [
                            { text: "Date", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: new Date(info.date_time).toLocaleDateString("en-GB"), width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Doc No.", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.running, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Invoice Header", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.supplier, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Permit Holder", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.location, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 2 ],
                        columns: [
                            { text: "Page", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text:  currentPage + " of " + pageCount, width: "*", fontSize: 8 },
                        ]
                    }
                ]
            },
            content: [
                [table]
            ]
        }


    }

    static async aplusCustomInvoice (info: IInvoice, detail: IInvoiceDetail[]): Promise<any>  {
        let logo = await urlToBase64(AplusLogo)
        let sign = await urlToBase64(AplusSign)
    
        let contentBody: TableCellProps[][] = [];
    
        let total_amount = detail.reduce((total, row) => total + row.price * row.qty, 0);
        let total_quantity = detail.reduce((total, row) => total + row.qty, 0);
    
        let x = 0;
        let n = 1
    
        detail.forEach(row => {
            contentBody.push([
                { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                { text: n++, alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                { text: row.item_description, fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                { text: row.qty, fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "$ " + row.price.toFixed(2), fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "ZRE", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "$ " + row.price.toFixed(2), fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "$ " + (row.qty * row.price).toFixed(2), fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
            ]);
            x++
    
            if(row.model_no !== "") {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                    { text: `Model No : ${row.model_no}`, fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                ]);
                x++
            }
    
           contentBody.push([
                { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
            ]);
            x++
        });
    
        if(x < 50) {
            for(let i=x; i<50; i++) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                ]);
            }
        } else if(x >= 50) {
            for(let i=x; i<100; i++) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                ]);
            }
        }
    
        return {
            info: {
                title: info.running
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            // left, top, right, bottom
            pageMargins: [ 0, 160.48, 0, 201.8 ],
    
            header: (currentPage: number, pageCount: number) => {
                return [
                    {
                        margin: [ 20, 30, 20, 0 ],
                        layout: {
                            defaultBorder: false,
                            paddingLeft: function() { return 0; },
                            paddingRight: function() { return 0; },
                            paddingTop: function() { return 0; },
                            paddingBottom: function() { return 0; }
                        },
                        table: {
                            headerRows: 0,
                            widths: [ "25%", "*", "25%" ],
                            body: [
                                [
                                    { text: "", border: [ true, true, false, false ] },
                                    {
                                        text: [
                                            { text: "APLUS DISTRIBUTION SDN BHD ", fontSize: 12, bold: true },
                                            { text: " (1220817-T)", fontSize: 7 }
                                        ],
                                        border: [ false, true, false, false ],
                                        alignment: "center",
                                        margin: [ 0, 10, 0, 0 ]
                                    },
                                    { 
                                        rowSpan: 5, 
                                        border: [ false, true, true, false ],
                                        image: logo,
                                        height: 45,
                                        width: 90,
                                        margin: [ 6, 10, 0, 0 ],
                                    },
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "Lot2978, JLN PUJUT 5C,", fontSize: 6, alignment: "center" },
                                    ""
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "SEVENEW COMMERCIAL CENTRES, 98000 MIRI, SARAWAK.", fontSize: 6, alignment: "center" },
                                    ""
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "Tel: 085-622256 GST ID: 000401453056", fontSize: 6, alignment: "center" },
                                    ""
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "Email: aplusdistributionsb@gmail.com", fontSize: 6, alignment: "center" },
                                    ""
                                ]
                            ]
                        }
                    },
                    {
                        margin: [ 20, 0, 20, 0 ],
                        width: "*",
                        layout: {
                            defaultBorder: false
                        },
                        table: {
                            widths: [ "35%", "*", "35%" ],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        border: [ true, false, false, false ],
                                        columns: [
                                            [
                                                { text: info.location, fontSize: 8, bold: true },
                                                { text: info.add1, fontSize: 8, margin: [ 0, 3, 0, 0 ] },
                                                { text: info.add2, fontSize: 8 },
                                                { text: info.add3, fontSize: 8 },
                                                { 
                                                    margin: [ 0, 7, 0, 0 ],
                                                    columns: [
                                                        { text: "ATTN", fontSize: 8, width: 30 },
                                                        { text: ":", fontSize: 8, width: 20 },
                                                        { text: info.contact_person, fontSize: 8, width: "auto" }
                                                    ]
                                                },
                                                { 
                                                    columns: [
                                                        { text: "TEL", fontSize: 8, width: 30 },
                                                        { text: ":", fontSize: 8, width: 20 },
                                                        { text: info.contact_no, fontSize: 8, width: "auto" }
                                                    ]
                                                },
                                            ]
                                        ]
                                    }, "",
                                    {
                                        border: [ false, false, true, false ],
                                        margin: [ 35, 0, 0, 0 ],
                                        columns: [
                                            [
                                                { text: "COMMERCIAL INVOICE", alignment: "center", fontSize: 10, bold: true, margin: [ 0, 0, 12, 0 ] },
                                                {
                                                    margin: [ 0, 5, 0, 0 ],
                                                    columns: [
                                                        { text: "NO", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: info.running, width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "DO", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: info.do_running, width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "DATE", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: (new Date(info.date_time)).toLocaleDateString("en-GB"), width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "TERM", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: "14 DAYS", width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "PAGE", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: currentPage + " of " + pageCount, width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                            ]
                                        ]
                                    }
                                ]
                            ]
                        }
                    }
                ]
            },
            footer: [
                {
                    margin: [ 20, 0, 20, 0 ],
                    layout: {
                        defaultBorder: false,
                        paddingTop: function() { return 0; },
                        paddingBottom: function() { return 0; }
                    },
                    table: {
                        headerRows: 0,
                        widths: [ "0.5%", "3.5%", "*", "7%", "8.5%", "3%", "8%", "9%" ],
                        body: [
                            [
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ], alignment: "right" },
                                { text: "TOTAL QUANTITY", border: [ true, true, true, true ], fontSize: 7, margin: [ 0, 0, 0, 0 ] },
                                { text: total_quantity, fontSize: 7, alignment: "center", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                            ],
                        ]
                    }
                },
                {
                    margin: [ 20, 0, 20, 0 ],
                    layout: {
                        defaultBorder: false,
                        paddingTop: function() { return 0; },
                        paddingBottom: function() { return 0; }
                    },
                    table: {
                        headerRows: 0,
                        widths: [ "0.5%", "3.5%", "*", "7%", "8.5%", "3%", "8%", "9%" ],
                        body: [
                            [
                                { 
                                    rowSpan: 5,
                                    colSpan: 3,
                                    stack: [
                                        { text: "1. Cheques should be crossed & made payable to", fontSize: 6 },
                                        { text: "APLUS DISTRIBUTION SDN BHD", bold: true, fontSize: 6, margin: [ 6, 0, 0, 0 ] },
                                        { text: "Bank in to: MAYBANK A/C NO: 5123-9807-6484", fontSize: 6, margin: [ 6, 0, 0, 0 ] },
                                        { text: "2. Interest will be charged at 2% per month on all overdue amount.", fontSize: 6 },
                                        { text: "3. If no remarks are made within 7 days, account will be considered as correct.", fontSize: 6 }
                                    ]
                                },
                                "", "",
                                { text: "TOTAL", fontSize: 7, border: [ true, false, true, false ] },
                                { text: "$ " + total_amount.toFixed(2), alignment: "center", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "-", alignment: "center", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "$ " + total_amount.toFixed(2), alignment: "center", fontSize: 7, border: [ false, false, true, false ] }
                            ],
                            [
                                "", "", "",
                                { text: "DISCOUNT", fontSize: 7, border: [ true, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] }
                            ],
                            [
                                "", "", "",
                                { text: "NET", fontSize: 7, border: [ true, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "$ " + total_amount.toFixed(2), fontSize: 7, border: [ false, false, true, false ], alignment: "center" }
                            ],
                            [
                                "", "", "",
                                { text: " ", fontSize: 7, border: [ true, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] }
                            ],
                            [
                                "", "", "",
                                { text: "GRAND", fontSize: 7, border: [ true, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "$ " + total_amount.toFixed(2), fontSize: 7, border: [ false, false, true, true ], alignment: "center" }
                            ]
                        ]
                    }
                },
                {
                    margin: [ 0, 15, 0, 0 ],
                    columns: [
                        [
                            {
                                alignment: "center",
                                image: sign,
                                width: 75,
                                height: 50,
                                margin: [ 0, 0, 20, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "______________________",
                                margin: [ 0, 0, 60, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "AUTHORISED SIGNATURE",
                                fontSize: 8,
                                margin: [ 0, 0, 60, 0 ]
                            }
                        ],
                        [
                            {
                                text: "",
                                alignment: "center",
                                margin: [ 60, 50, 0, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "______________________",
                                margin: [ 60, 0, 0, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "RECEIVED BY",
                                fontSize: 8,
                                margin: [ 60, 0, 0, 0 ]
                            } 
                        ]
                    ]
                },
           
            ],
            content: [
                {
                    layout: {
                        defaultBorder: false,
                        // paddingLeft: function(i, node) { return 0; },
                        // paddingRight: function(i, node) { return 0; },
                        paddingTop: function() { return 0; },
                        paddingBottom: function() { return 0; }
                    },
                    width: "*",
                    margin: [ 20, 0, 20, 0 ],
                    table: {
                        headerRows: 2,
                        widths: [ "0.5%", "3.5%", "*", "7%", "8.5%", "3%", "8%", "9%" ],
                        body: [
                            [
                                {
                                    rowSpan: 2,
                                    text: "",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "ITEM",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "ITEM DESCRIPTION",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "QTY",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "PRICE (BND)",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "TAX",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    text: "UNIT PRICE",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, false ]
                                },
                                {
                                    text: "TOTAL PRICE",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, false ]
                                },
                            ],
                            [
                                "" ,"", "","", "", "",
                                {
                                    text: "(BND)",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, false, true, true ]
                                },
                                {
                                    text: "(BND)",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, false, true, true ]
                                },
                            ], ...contentBody
                        ]
                    }
                }
            ]
        }
    }
    
    static async aplusMalaysiaCustomInvoice (info: IInvoice, detail: IInvoiceDetail[]): Promise<any>  {
        let logo = await urlToBase64(AplusLogo)
        let sign = await urlToBase64(AplusSign)
    
        let contentBody: TableCellProps[][] = [];
    
        let total_amount = detail.reduce((total, row) => total + row.price * 3 * row.qty, 0);
        let total_quantity = detail.reduce((total, row) => total + row.qty, 0);
    
        let x = 0;
        let n = 1
    
        detail.forEach(row => {
            contentBody.push([
                { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                { text: n++, alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                { text: row.item_description, fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                { text: row.qty, fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "$ " + (row.price * 3).toFixed(2), fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "ZRE", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "$ " + (row.price * 3).toFixed(2), fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: "$ " + (row.qty * row.price * 3).toFixed(2), fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
            ]);
            x++
    
            if(row.item_description1) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                    { text: row.item_description1, fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                ]);
                x++
            }
    
           contentBody.push([
                { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
            ]);
            x++
        });
    
        if(x < 50) {
            for(let i=x; i<50; i++) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                ]);
            }
        } else if(x >= 50) {
            for(let i=x; i<100; i++) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, border: [ true, false, true, false ], margin: [ 0, 0, 0, 0 ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
                ]);
            }
        }
    
        return {
            info: {
                title: info.running
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            // left, top, right, bottom
            pageMargins: [ 0, 160.48, 0, 201.8 ],
    
            header: (currentPage: number, pageCount: number) => {
                return [
                    {
                        margin: [ 20, 30, 20, 0 ],
                        layout: {
                            defaultBorder: false,
                            paddingLeft: function() { return 0; },
                            paddingRight: function() { return 0; },
                            paddingTop: function() { return 0; },
                            paddingBottom: function() { return 0; }
                        },
                        table: {
                            headerRows: 0,
                            widths: [ "25%", "*", "25%" ],
                            body: [
                                [
                                    { text: "", border: [ true, true, false, false ] },
                                    {
                                        text: [
                                            { text: "APLUS DISTRIBUTION SDN BHD ", fontSize: 12, bold: true },
                                            { text: " (1220817-T)", fontSize: 7 }
                                        ],
                                        border: [ false, true, false, false ],
                                        alignment: "center",
                                        margin: [ 0, 10, 0, 0 ]
                                    },
                                    { 
                                        rowSpan: 5, 
                                        border: [ false, true, true, false ],
                                        image: logo,
                                        height: 45,
                                        width: 90,
                                        margin: [ 6, 10, 0, 0 ],
                                    },
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "Lot2978, JLN PUJUT 5C,", fontSize: 6, alignment: "center" },
                                    ""
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "SEVENEW COMMERCIAL CENTRES, 98000 MIRI, SARAWAK.", fontSize: 6, alignment: "center" },
                                    ""
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "Tel: 085-622256 GST ID: 000401453056", fontSize: 6, alignment: "center" },
                                    ""
                                ],
                                [
                                    { text: "", border: [ true, false, false, false ] },
                                    { text: "Email: aplusdistributionsb@gmail.com", fontSize: 6, alignment: "center" },
                                    ""
                                ]
                            ]
                        }
                    },
                    {
                        margin: [ 20, 0, 20, 0 ],
                        width: "*",
                        layout: {
                            defaultBorder: false
                        },
                        table: {
                            widths: [ "35%", "*", "35%" ],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        border: [ true, false, false, false ],
                                        columns: [
                                            [
                                                { text: info.location, fontSize: 8, bold: true },
                                                { text: info.add1 ? info.add1 : " ", fontSize: 8, margin: [ 0, 3, 0, 0 ] },
                                                { text: info.add2 ? info.add2 : " ", fontSize: 8 },
                                                { text: info.add3 ? info.add3 : " ", fontSize: 8 },
                                                { 
                                                    margin: [ 0, 7, 0, 0 ],
                                                    columns: [
                                                        { text: "ATTN", fontSize: 8, width: 30 },
                                                        { text: ":", fontSize: 8, width: 20 },
                                                        { text: info.contact_person, fontSize: 8, width: "auto" }
                                                    ]
                                                },
                                                { 
                                                    columns: [
                                                        { text: "TEL", fontSize: 8, width: 30 },
                                                        { text: ":", fontSize: 8, width: 20 },
                                                        { text: info.contact_no, fontSize: 8, width: "auto" }
                                                    ]
                                                },
                                            ]
                                        ]
                                    }, "",
                                    {
                                        border: [ false, false, true, false ],
                                        margin: [ 35, 0, 0, 0 ],
                                        columns: [
                                            [
                                                { text: "COMMERCIAL INVOICE", alignment: "center", fontSize: 10, bold: true, margin: [ 0, 0, 12, 0 ] },
                                                {
                                                    margin: [ 0, 5, 0, 0 ],
                                                    columns: [
                                                        { text: "NO", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: info.notes ? info.notes : info.running, width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "DO", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: "", width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "DATE", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: (new Date(info.date_time)).toLocaleDateString("en-GB"), width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "TERM", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: "14 DAYS", width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                                {
                                                    columns: [
                                                        { text: "PAGE", width: 35, fontSize: 8 },
                                                        { text: ":", width: 20, fontSize: 8, alignment: "center" },
                                                        { text: currentPage + " of " + pageCount, width: "auto", fontSize: 8 },
                                                    ]
                                                },
                                            ]
                                        ]
                                    }
                                ]
                            ]
                        }
                    }
                ]
            },
            footer: [
                {
                    margin: [ 20, 0, 20, 0 ],
                    layout: {
                        defaultBorder: false,
                        paddingTop: function() { return 0; },
                        paddingBottom: function() { return 0; }
                    },
                    table: {
                        headerRows: 0,
                        widths: [ "0.5%", "3.5%", "*", "7%", "8.5%", "3%", "8%", "9%" ],
                        body: [
                            [
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ], alignment: "right" },
                                { text: "TOTAL QUANTITY", border: [ true, true, true, true ], fontSize: 7, margin: [ 0, 0, 0, 0 ] },
                                { text: total_quantity, fontSize: 7, alignment: "center", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                                { text: "", border: [ true, true, true, true ] },
                            ],
                        ]
                    }
                },
                {
                    margin: [ 20, 0, 20, 0 ],
                    layout: {
                        defaultBorder: false,
                        paddingTop: function() { return 0; },
                        paddingBottom: function() { return 0; }
                    },
                    table: {
                        headerRows: 0,
                        widths: [ "0.5%", "3.5%", "*", "7%", "8.5%", "3%", "8%", "9%" ],
                        body: [
                            [
                                { 
                                    rowSpan: 5,
                                    colSpan: 3,
                                    stack: [
                                        { text: "1. Cheques should be crossed & made payable to", fontSize: 6 },
                                        { text: "APLUS DISTRIBUTION SDN BHD", bold: true, fontSize: 6, margin: [ 6, 0, 0, 0 ] },
                                        { text: "Bank in to: MAYBANK A/C NO: 5123-9807-6484", fontSize: 6, margin: [ 6, 0, 0, 0 ] },
                                        { text: "2. Interest will be charged at 2% per month on all overdue amount.", fontSize: 6 },
                                        { text: "3. If no remarks are made within 7 days, account will be considered as correct.", fontSize: 6 }
                                    ]
                                },
                                "", "",
                                { text: "TOTAL", fontSize: 7, border: [ true, false, true, false ] },
                                { text: "$ " + total_amount.toFixed(2), alignment: "center", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "-", alignment: "center", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "$ " + total_amount.toFixed(2), alignment: "center", fontSize: 7, border: [ false, false, true, false ] }
                            ],
                            [
                                "", "", "",
                                { text: "DISCOUNT", fontSize: 7, border: [ true, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] }
                            ],
                            [
                                "", "", "",
                                { text: "NET", fontSize: 7, border: [ true, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "", fontSize: 7, border: [ false, false, true, false ] },
                                { text: "$ " + total_amount.toFixed(2), fontSize: 7, border: [ false, false, true, false ], alignment: "center" }
                            ],
                            [
                                "", "", "",
                                { text: " ", fontSize: 7, border: [ true, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] }
                            ],
                            [
                                "", "", "",
                                { text: "GRAND", fontSize: 7, border: [ true, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "", fontSize: 7, border: [ false, false, true, true ] },
                                { text: "$ " + total_amount.toFixed(2), fontSize: 7, border: [ false, false, true, true ], alignment: "center" }
                            ]
                        ]
                    }
                },
                {
                    margin: [ 0, 15, 0, 0 ],
                    columns: [
                        [
                            {
                                alignment: "center",
                                image: sign,
                                width: 75,
                                height: 50,
                                margin: [ 0, 0, 20, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "______________________",
                                margin: [ 0, 0, 60, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "AUTHORISED SIGNATURE",
                                fontSize: 8,
                                margin: [ 0, 0, 60, 0 ]
                            }
                        ],
                        [
                            {
                                text: "",
                                alignment: "center",
                                margin: [ 60, 50, 0, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "______________________",
                                margin: [ 60, 0, 0, 0 ]
                            },
                            {
                                alignment: "center",
                                text: "RECEIVED BY",
                                fontSize: 8,
                                margin: [ 60, 0, 0, 0 ]
                            } 
                        ]
                    ]
                },
           
            ],
            content: [
                {
                    layout: {
                        defaultBorder: false,
                        // paddingLeft: function(i, node) { return 0; },
                        // paddingRight: function(i, node) { return 0; },
                        paddingTop: function() { return 0; },
                        paddingBottom: function() { return 0; }
                    },
                    width: "*",
                    margin: [ 20, 0, 20, 0 ],
                    table: {
                        headerRows: 2,
                        widths: [ "0.5%", "3.5%", "*", "7%", "8.5%", "3%", "8%", "9%" ],
                        body: [
                            [
                                {
                                    rowSpan: 2,
                                    text: "",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "ITEM",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "ITEM DESCRIPTION",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "QTY",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "PRICE (MYR)",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    rowSpan: 2,
                                    text: "TAX",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, true ],
                                    margin: [ 0, 5, 0, 0 ]
                                },
                                {
                                    text: "UNIT PRICE",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, false ]
                                },
                                {
                                    text: "TOTAL PRICE",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, true, true, false ]
                                },
                            ],
                            [
                                "" ,"", "","", "", "",
                                {
                                    text: "(MYR)",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, false, true, true ]
                                },
                                {
                                    text: "(MYR)",
                                    alignment: "center",
                                    fontSize: 7,
                                    border: [ true, false, true, true ]
                                },
                            ], ...contentBody
                        ]
                    }
                }
            ]
        }
    }

    static async primenetCustomInvoice (info: IInvoice, detail: IInvoiceDetail[]): Promise<any>  {

        let logo = await urlToBase64(PrimenetLogo)
        let sign = await urlToBase64(PrimenetSign)
    
        let contentBody: TableCellProps[][] = [];
    
        let x = 0;
    
        detail.forEach(row => {
            contentBody.push(   [
                { text: x+1, fontSize: 10, alignment: "center", border: [ true, false, true, false ]},
                { text: row.item_description, fontSize: 10, border: [ true, false, true, false ]},
                { text: row.qty, fontSize: 10, alignment: "center", border: [ true, false, true, false ]},
                { text: row.price.toFixed(2), fontSize: 10, alignment: "center", border: [ true, false, true, false ]},
                { text: (row.qty * row.price).toFixed(2), fontSize: 10, alignment: "right", border: [ true, false, true, false ]},
            ]);
            x++;
        });
    
        for(let i=x; i<25; i++) {
            contentBody.push([
                { text: " ", fontSize: 10, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 10, border: [ true, false, true, false ]},
                { text: " ", fontSize: 10, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 10, alignment: "center", border: [ true, false, true, false ]},
                { text: " ", fontSize: 10, alignment: "right", border: [ true, false, true, false ]},
            ]);
        }
    
        let total_quantity = detail.reduce((total, row) => total + row.qty, 0);
        let total = detail.reduce((total, row) => total + row.qty * row.price, 0);
    
        let info_title = "P" + (Number(new Date(info.date_time).getTime().toString().slice(0, 9)) + info.so_id)
    
        return {
            info: {
                title: info_title
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            // left, top, right, bottom
            pageMargins: [ 0, 185, 0, 216.2 ],
    
            header: (currentPage: number, pageCount: number) => {
                return  [
                    {
                        margin: [ 30, 30, 30, 0 ],
                        columns: [
                            {
                                width: 100,
                                margin: [ 0, 5, 0, 0 ],
                                columns: [
                                    {
                                        text: "",
                                        width: 30
                                    },
                                    {
                                        image: logo,
                                        height: 45,
                                        width: 120,
                                    }
                                ]
                            },
                            [
                                {
                                    columns: [
                                        {
                                            width: 350,
                                            alignment: "center",
                                            text: [
                                                { text: "Prime Net (S) Pte Ltd ", fontSize: 16, bold: true },
                                                { text: " (200413417G)", fontSize: 7 }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 350,
                                            alignment: "center",
                                            fontSize: 8,
                                            text: "1, Kaki Bukit Road 1,"
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 350,
                                            alignment: "center",
                                            fontSize: 8,
                                            text: "#02-02&03 Enterprise One,"
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 350,
                                            alignment: "center",
                                            fontSize: 8,
                                            text: "Singapore 415834."
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            width: 350,
                                            alignment: "center",
                                            fontSize: 8,
                                            text: "Tel: +65-6848 9559"
                                        }
                                    ]
                                }
                            ]
                        ]
                    },
                    {
                        layout: {
                            defaultBorder: false,
                        },
                        width: "*",
                        margin: [ 30, 5, 30, 0 ],
                        table: {
                            headerRows: 1,
                            widths: [ "*" ],
                            body: [
                                [
                                    { text: "", border: [ false, true, false, false ] }
                                ]
                            ]
                        } 
                    },
                    {
                        margin: [ 45, 0, 0, 0 ],
                        columns: [
                            [
                                {
                                    columns: [
                                        {
                                            text: "Customer",
                                            fontSize: 12,
                                            bold: true,
                                            width: 190
                                        }
                                    ]
                                },
                                {
                                    margin: [ 0, 3, 0, 0 ],
                                    columns: [
                                        {
                                            text: info.location,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: info.add1,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text:  info.add2,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text:  info.add3,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            columns: [
                                                {
                                                    text: "Attn",
                                                    width: 30,
                                                    fontSize: 9
                                                },
                                                {
                                                    text: ":",
                                                    width: 15,
                                                    fontSize: 9
                                                },
                                                {
                                                    text:  info.contact_person,
                                                    width: 100,
                                                    fontSize: 9
                                                },
                                            ]
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: "Tel",
                                            width: 30,
                                            fontSize: 9
                                        },
                                        {
                                            text: ":",
                                            width: 15,
                                            fontSize: 9
                                        },
                                        {
                                            text:  info.contact_no,
                                            width: 100,
                                            fontSize: 9
                                        },
                                    ]
                                }
                            ],
                            [
                                {
                                    columns: [
                                        {
                                            text: "Delivery to",
                                            fontSize: 12,
                                            bold: true
                                        }
                                    ]
                                },
                                {
                                    margin: [ 0, 3, 0, 0 ],
                                    columns: [
                                        {
                                            text: info.location,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: info.add1,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text:  info.add2,
                                            fontSize: 9
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: info.add3,
                                            fontSize: 9
                                        }
                                    ]
                                }
                            ],
                            [
                                {
                                    columns: [
                                       [
                                           {
                                                text: "INVOICE", bold: true, margin: [ 40, 0, 0, 0 ]
                                           },
                                           {
                                                columns: [
                                                   {
                                                        text: "Inv No.",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: info_title,
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 80
                                                   }
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                        text: "Date",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: (new Date(info.date_time)).toLocaleDateString("en-GB"),
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                       text: "Term",
                                                       alignment: "left",
                                                       fontSize: 10,
                                                       width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: "C.O.D",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                       text: "Sales Rep",
                                                       alignment: "left",
                                                       fontSize: 10,
                                                       width: 60
                                                   },
                                                   {
                                                       text: ":",
                                                       alignment: "left",
                                                       fontSize: 10,
                                                       width: 15
                                                   },
                                                   {
                                                        text: "DANIEL",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                       text: "Page",
                                                       alignment: "left",
                                                       fontSize: 10,
                                                       width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: currentPage + " of " + pageCount,
                                                        alignment: "left",
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           }
                                       ]
                                    ]
                                }
                            ],
                        ]
                    }
                ]
            },
            footer: [
                [
                    {
                        margin: [ 25, 0, 24, 0 ],
                        table: {
                            widths: [ "5%", "50%", "15%", "15%", "15%" ],
                            body: [
                                [
                                    { text: "", border: [ false, true, false, false ] },
                                    { text: "Total Quantity :", border: [ false, true, false, false ], fontSize: 10, alignment: "right" },
                                    { text: total_quantity, border: [ false, true, false, false ], fontSize: 10, alignment: "center" },
                                    { text: "", border: [ false, true, false, false ] },
                                    { text: "", border: [ false, true, false, false ] }
                                ],
                            ]
                        }
                    },
                    {
                        columns: [
                            [
                                {
                                    columns: [
                                        {
                                            width: 395,
                                            margin: [ 40, 0, 40, 0 ],
                                            text: "Notes:",
                                            fontSize: 10,
                                            bold: true
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            margin: [ 40, 0, 40, 0 ],
                                            text: "1. All cheques should be crossed and made payable to",
                                            fontSize: 8
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            margin: [ 49, 0, 40, 0 ],
                                            text: "Prime Net (S) Pte Ltd",
                                            bold: true,
                                            fontSize: 8
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                                margin: [ 40, 0, 40, 0 ],
                                                text: "2. All goods sold are not returnable or exchange.",
                                                fontSize: 8
                                        }
                                    ]
                                },
                            ],
                            [
                                {
                                    columns: [
                                        {
                                            text: "Sub Total",
                                            margin: [ 0, 7, 0, 0 ],
                                            width: 85,
                                            fontSize: 10,
                                            alignment: "left"
                                        },
                                        {
                                            text: ":",
                                            margin: [ 0, 7, 10, 0 ],
                                            width: 5,
                                            fontSize: 10,
                                            alignment: "center"
                                        },
                                        {
                                            width: 85,
                                            margin: [ 0, 4, 0, 0 ],
                                            table: {
                                                widths: [ "*" ],
                                                body: [
                                                    [
                                                        { text: total.toFixed(2), border: [ true, true, true, true ], fontSize: 10, alignment: "right" }
                                                    ]
                                                ]
                                            }
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: "GST 0.00%",
                                            margin: [ 0, 7, 0, 0 ],
                                            width: 85,
                                            fontSize: 10,
                                            alignment: "left"
                                        },
                                        {
                                            text: ":",
                                            margin: [ 0, 7, 10, 0 ],
                                            width: 5,
                                            fontSize: 10,
                                            alignment: "center"
                                        },
                                        {
                                            width: 85,
                                            margin: [ 0, 5, 0, 0 ],
                                            table: {
                                                widths: [ "*" ],
                                                body: [
                                                    [
                                                        { text: "0.00", border: [ true, true, true, true ], fontSize: 10, alignment: "right" }
                                                    ]
                                                ]
                                            }
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: "Total",
                                            margin: [ 0, 7, 0, 0 ],
                                            width: 85,
                                            fontSize: 10,
                                            alignment: "left"
                                        },
                                        {
                                            text: ":",
                                            margin: [ 0, 7, 10, 0 ],
                                            width: 5,
                                            fontSize: 10,
                                            alignment: "center"
                                        },
                                        {
                                            width: 85,
                                            margin: [ 0, 5, 0, 0 ],
                                            table: {
                                                widths: [ "*" ],
                                                body: [
                                                    [
                                                        { text: total.toFixed(2), border: [ true, true, true, true ], fontSize: 10, alignment: "right" }
                                                    ]
                                                ]
                                            }
                                        }
                                    ]
                                },
                            ]
                        ]
                    }
                ],
                {
                    margin: [ 80, 10, 60, 0 ],
                    image: sign,
                    width: 85,
                    height: 50,
                },
                {
                    margin: [ 60, 0, 60, 0 ],
                    text: "___________________",
                },
                {
                    margin: [ 60, 0, 60, 0 ],
                    text: "Authorised Signature",
                    fontSize: 10
                }
            ],
            content: [
                {
                    layout: {
                        defaultBorder: false,
                    },
                    width: "*",
                    margin: [ 25, 5, 25, 0 ],
                    table: {
                        headerRows: 1,
                        widths: [ "5%", "*", "15%", "15%", "15%" ],
                        body: [
                            [
                                {
                                    text: "No.",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ true, true, true, true ]
                                },
                                {
                                    text: "Description",
                                    alignment: "left",
                                    fontSize: 10,
                                    border: [ true, true, true, true ]
                                },
                                {
                                    text: "Qty",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ true, true, true, true ]
                                },
                                {
                                    text: "U/Price",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ true, true, true, true ]
                                },
                                {
                                    text: "Amount (SGD)",
                                    alignment: "right",
                                    fontSize: 10,
                                    border: [ true, true, true, true ]
                                }
                            ], ...contentBody
                        ]
                    }
                }
            ]
        }
    }
    
    static async uniqbeCustomInvoice (info: IInvoice, detail: IInvoiceDetail[]): Promise<any>  {
    
        let beizhu = await urlToBase64(beizhu_url)
        let kehu = await urlToBase64(kehu_url)
        let dizhi = await urlToBase64(dizhi_url)
        let lianxiren = await urlToBase64(lianxiren_url)
        let riqi = await urlToBase64(riqi_url)
        let yewuyuandaima = await urlToBase64(yewuyuandaima_url)
        let xitongdanhao = await urlToBase64(xitongdanhao_url)
        let fukuanfangshi = await urlToBase64(fukuanfangshi_url)
        let huowuneirong = await urlToBase64(huowuneirong_url)
        let shuliang = await urlToBase64(shuliang_url)
        let danjia = await urlToBase64(danjia_url)
        let jiner = await urlToBase64(jiner_url)
        
        let contentBody: TableCellProps[][] = [];
    
        let total_amount = detail.reduce((total, row) => {
            total += Math.ceil(row.price*info.bnd_to_usd*10)/10 * row.qty
            return total
        }, 0);
        let total_quantity = detail.reduce((total, row) => total + row.qty, 0);
    
        let x = 0;
    
    
        detail.forEach(row => {
            let price = Math.ceil(row.price*info.bnd_to_usd*10)/10
            contentBody.push([
                { text: row.item_description, fontSize: 7, border: [ true, true, true, true ], margin: [ 0, 0, 0, 0 ]},
                { text: row.qty, fontSize: 7, alignment: "center", border: [ true, true, true, true ]},
                { text: price, fontSize: 7, alignment: "center", border: [ true, true, true, true ]},
                { text: (row.qty * price).toFixed(2), fontSize: 7, alignment: "right", border: [ true, true, true, true ]},
                { text: "", fontSize: 7, alignment: "center", border: [ true, false, true, false ]},
            ]);
            x++
        });
    
        if(x<20) {
            for(let i=x; i<20; i++) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, true, true, true  ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, true, true, true ]},
                    { text: " ", fontSize: 7, border: [ true, true, true, true ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, true, true, true ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, i === 19 ? true : false ]},
                ]);
            }
        } 
        
        if(x>20) {
            for(let i=x; i<40; i++) {
                contentBody.push([
                    { text: " ", fontSize: 7, border: [ true, true, true, true  ]},
                    { text: " ", alignment: "center", fontSize: 7, border: [ true, true, true, true ]},
                    { text: " ", fontSize: 7, border: [ true, true, true, true ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, true, true, true ]},
                    { text: " ", fontSize: 7, alignment: "center", border: [ true, false, true, i === 19 ? true : false ]},
                ]);
            }
        }
    
         return {
            info: {
                title: info.notes
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            // left, top, right, bottom
            pageMargins: [ 0, 315, 0, 237.4 ],
    
            header: () => {
                return [
                    {
                        margin: [ 20, 25, 20, 0 ],
                        width: "*",
                        layout: {
                            defaultBorder: false
                        },
                        table: {
                            widths: [ "50%", "50%" ],
                            headerRows: 0,
                            body: [
                                [
                                    {
                                        layout: {
                                            defaultBorder: false,
                                            paddingLeft: function() { return 0; },
                                            paddingRight: function() { return 0; },
                                            paddingTop: function() { return 0; },
                                            paddingBottom: function() { return 0; }
                                        },
                                        width: "100%",
                                        table: {
                                            headerRows: 0,
                                            widths: [ "*" ],
                                            body: [
                                                [
                                                    { 
                                                        margin: [ 8, 0, 0, 12 ],
                                                        text: [
                                                            { text: "UNIQBE LIMITED", fontSize: 18, bold: true},
                                                            { text: " (BRC:38268523)", fontSize: 8, bold: true},
                                                        ], 
                                                        border: [ true, false, false, false ]
                                                    },
                                                ],
                                                [
                                                    {  
                                                        margin: [ 10, 0, 0, 0 ],
                                                        text: "UNIT 403D, 4/F ELITE INDUSTRIAL CENTRE", 
                                                        fontSize: 8, 
                                                        bold: true,
                                                        border: [ true, false, false, false ]
                                                    },  
                                                ],
                                                [
                                                    {  
                                                        margin: [ 10, 0, 0, 0 ],
                                                        text: "883 CHEUNG SHA WAN ROAD, KOWLOON, HONG KONG", 
                                                        fontSize: 8, 
                                                        bold: true,
                                                        border: [ true, false, false, false ]
                                                    },  
                                                ],
                                                [
                                                    {  
                                                        margin: [ 10, 0, 0, 0 ],
                                                        text: "Tel: +852 2750 2932      Fax: +852 2796 2932", 
                                                        fontSize: 8, 
                                                        bold: true,
                                                        border: [ true, false, false, false ]
                                                    },  
                                                ],
                                                [
                                                    {
                                                        margin: [ 5, 0, 0, 0 ],
                                                        border: [ true, false, false, false ],
                                                        width: "100%",
                                                        layout: {
                                                            defaultBorder: false,
                                                            paddingLeft: function() { return 0; },
                                                            paddingRight: function() { return 0; },
                                                            paddingTop: function() { return 0; },
                                                            paddingBottom: function() { return 0; }
                                                        },
                                                        table: {
                                                            widths: [ "18%", "*" ],
                                                            body: [
                                                                [
                                                                    {
                                                                        stack: [
                                                                            {
                                                                                image: kehu,
                                                                                width: 20,
                                                                                height: 10,
                                                                                margin: [ 8, 15, 0, 0 ]
                                                                            },
                                                                            { text: "Customer :", fontSize: 8, bold: true, margin: [ 0, 2, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: info.location, 
                                                                        fontSize: 10, bold: true, 
                                                                        border: [ false, false, false, true ], 
                                                                        margin: [ 5, 25, 0, 0 ],
                                                                    }
                                                                ],
                                                                [
                                                                    {
                                                                        stack: [
                                                                            {
                                                                                image: dizhi,
                                                                                width: 20,
                                                                                height: 10,
                                                                                margin: [ 8, 10, 0, 0 ]
                                                                            },
                                                                            { text: "Ship To :", fontSize: 8, bold: true, margin: [ 0, 3, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: info.add1, 
                                                                        fontSize: 10, 
                                                                        border: [ false, false, false, true ], 
                                                                        margin: [ 10, 20, 0, 0 ] 
                                                                    }
                                                                ],
                                                                [
                                                                    "", 
                                                                    { 
                                                                        text: info.add2, 
                                                                        fontSize: 10, 
                                                                        border: [ false, false, false, true ], 
                                                                        margin: [ 10, 4, 0, 0 ] 
                                                                    }
                                                                ],
                                                                [
                                                                    "", 
                                                                    { 
                                                                        text: info.add3, 
                                                                        fontSize: 10, 
                                                                        border: [ false, false, false, true ], 
                                                                        margin: [ 10, 12, 0, 0 ] 
                                                                    }
                                                                ],
                                                                [
                                                                    {
                                                                        stack: [
                                                                            {
                                                                                image: lianxiren,
                                                                                width: 20,
                                                                                height: 10,
                                                                                margin: [ 8, 5, 0, 0 ]
                                                                            },
                                                                            { text: "Contact :", fontSize: 8, bold: true, margin: [ 0, 2, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: info.contact_person, 
                                                                        fontSize: 10, 
                                                                        border: [ false, false, false, true ], 
                                                                        margin: [ 10, 17, 0, 0 ] 
                                                                    }
                                                                ],
                                                                [
                                                                    "", 
                                                                    { 
                                                                        text: "INVOICE/DO", 
                                                                        fontSize: 14, 
                                                                        border: [ false, false, false, false ], 
                                                                        margin: [ 10, 15, 0, 40 ] 
                                                                    }
                                                                ],
                                                            ]
                                                        }
                                                    }
                                                ]
                                            ]
                                        }
                                    },
                                    {
                                        margin: [ 10, 55, 0, 0 ],
                                        layout: {
                                            defaultBorder: false,
                                            paddingLeft: function() { return 0; },
                                            paddingRight: function() { return 0; },
                                            paddingTop: function() { return 0; },
                                            paddingBottom: function() { return 0; }
                                        },
                                        width: "100%",
                                        table: {
                                            headerRows: 0,
                                            widths: [ "*" ],
                                            body: [
                                                [
                                                    {
                                                        margin: [ 5, 0, 0, 0 ],
                                                        width: "100%",
                                                        layout: {
                                                            defaultBorder: false,
                                                            paddingLeft: function() { return 0; },
                                                            paddingRight: function() { return 0; },
                                                            paddingTop: function() { return 0; },
                                                            paddingBottom: function() { return 0; }
                                                        },
                                                        table: {
                                                            widths: [ "20%", "30%", "*" ],
                                                            body: [
                                                                [
                                                                    "",
                                                                    { text: "No. :",alignment: "right", fontSize: 9, bold: true },
                                                                    { 
                                                                        text: info.notes, 
                                                                        fontSize: 9, 
                                                                        border: [ false, false, false, true ],
                                                                        margin: [ 0, 0, 0, 3 ]
                                                                    }
                                                                ],
                                                                [
                                                                    "",
                                                                    {
                                                                        margin: [ 0, 10, 0, 0],
                                                                        stack: [
                                                                            {
                                                                                image: riqi,
                                                                                width: 20,
                                                                                height: 10,
                                                                                margin: [ 0, 0, 2, 0],
                                                                            },
                                                                            { text: "Date :", fontSize: 9, bold: true, margin: [ 0, 2, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: new Date(info.date_time).toLocaleDateString("zh-Hans-CN"), 
                                                                        fontSize: 9, 
                                                                        margin: [ 5, 20, 0, 0 ],
                                                                        border: [ false, false, false, true ]
                                                                    }
                                                                ],
                                                                [
                                                                    "",
                                                                    {
                                                                        margin: [ 0, 10, 0, 0],
                                                                        stack: [
                                                                            {
                                                                                image: yewuyuandaima,
                                                                                width: 40,
                                                                                height: 10,
                                                                                margin: [ 0, 0, 2, 0],
                                                                            },
                                                                            { text: "Sales Person :", fontSize: 9, bold: true, margin: [ 0, 2, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: "B1919", 
                                                                        fontSize: 9, 
                                                                        margin: [ 2, 20, 0, 0 ],
                                                                        border: [ false, false, false, true ]
                                                                    }
                                                                ]
                                                            ]
                                                        }
                                                    }
                                                ],
                                                [
                                                    {
                                                        margin: [ 5, 5, 0, 0 ],
                                                        width: "100%",
                                                        layout: {
                                                            defaultBorder: false,
                                                            paddingLeft: function() { return 0; },
                                                            paddingRight: function() { return 0; },
                                                            paddingTop: function() { return 0; },
                                                            paddingBottom: function() { return 0; }
                                                        },
                                                        table: {
                                                            widths: [ "30%", "*" ],
                                                            body: [
                                                                [
                                                                    {
                                                                        margin: [ 0, 10, 0, 0],
                                                                        stack: [
                                                                            {
                                                                                image: xitongdanhao,
                                                                                width: 40,
                                                                                height: 10,
                                                                                margin: [ 0, 0, 2, 0],
                                                                            },
                                                                            { text: "Z Ref. No. :", fontSize: 9, bold: true, margin: [ 0, 2, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: "------", 
                                                                        fontSize: 10, 
                                                                        margin: [ 50, 15, 0, 0 ],
                                                                    }
                                                                ],
                                                                [
                                                                    {
                                                                        margin: [ 0, 12, 0, 0],
                                                                        stack: [
                                                                            {
                                                                                image: fukuanfangshi,
                                                                                width: 40,
                                                                                height: 10,
                                                                                margin: [ 0, 0, 2, 0],
                                                                            },
                                                                            { text: "Payment Terms :", fontSize: 9, bold: true, margin: [ 0, 2, 0, 0 ] }
                                                                        ],
                                                                        alignment: "right"
                                                                    }, 
                                                                    { 
                                                                        text: "CREDIT TERM", 
                                                                        fontSize: 10, 
                                                                        margin: [ 3, 20, 0, 0 ],
                                                                        border: [ false, false, false, true ]
                                                                    }
                                                                ]
                                                            ]
                                                        }
                                                    } 
                                                ],
                                                [
                                                    {   
                                                        alignment: "right",
                                                        margin: [ 210, 35, 0, 0 ],
                                                        width: "20%",
                                                        layout: {
                                                            paddingLeft: function() { return 10; },
                                                            paddingRight: function() { return 10; },
                                                            paddingTop: function() { return 0; },
                                                            paddingBottom: function() { return 10; }
                                                        },
                                                        table: {
                                                            widths: [ "auto" ],
                                                            body: [
                                                                [
                                                                    { text: "USD", fontSize: 14 }
                                                                ]
                                                            ]
                                                        }
                                                    }
                                                ]
                                            ]
                                        }
                                    }
                                ]
                            ]
                        }
                    }
                ]
            },
            footer: (currentPage: number) => {
                return [
                    {
                        layout: {
                            defaultBorder: false,
                            // paddingLeft: function(i, node) { return 0; },
                            // paddingRight: function(i, node) { return 0; },
                            paddingTop: function() { return 2; },
                            paddingBottom: function() { return 2; },
                        },
                        width: "*",
                        margin: [ 30, 0, 30, 0 ],
                        table: {
                            widths: [ "*", "10%", "14%", "12%", "18%" ],
                            body: [
                                [
                                    { text: "Total", fontSize: 8, alignment: "right", margin: [ 0, 0, 25, 0 ], border: [ true, false, true, true ] },
                                    { text: total_quantity, fontSize: 8, alignment: "center", border: [ true, false, true, true ], fillColor: "#eeffee" },
                                    { text: "",fontSize: 8, border: [ true, false, true, true ] },
                                    { text: total_amount.toFixed(2), fontSize: 8, alignment: "right", border: [ true, false, true, true ], fillColor: "#eeffee" },
                                    { text: "", fontSize: 8, border: [ true, false, true, true ] },
                                ]
                            ]
                        }
                    }, 
                    {
                        layout: {
                            defaultBorder: false,
                            // paddingLeft: function(i, node) { return 0; },
                            // paddingRight: function(i, node) { return 0; },
                            paddingTop: function() { return 0; },
                            paddingBottom: function() { return 0; },
                        },
                        width: "*",
                        margin: [ 30, 0, 30, 0 ],
                        table: {
                            widths: [ "*" ],
                            body: [
                                [
                                    { text: "", border: [ false, true, false, false ] }
                                ]
                            ]
                        }
                    },
                    {
                        margin: [ 50, 40, 0, 8 ],
                        text: "Bank Information",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Bank Name: DBS Bank (Hong Kong) Limited",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Bank Address: 16th Floor, The Center, 99 Quenen's Rd Central, Central, Hong Kong",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Bank Code: 016",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Swift Code: DHBKHKHH",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Account Name: Uniqbe Limited",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Account No.(USD): 478000270052",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 50, 0, 0, 4 ],
                        text: "Account No.(HKD): 47800027001",
                        fontSize: 6,
                        bold: true
                    },
                    {
                        margin: [ 0, 10, 0, 0 ],
                        text: currentPage,
                        alignment: "center",
                        fontSize: 8,
                        bold: true,
                    }
                ]
            },
            content: [
                {
                    layout: {
                        defaultBorder: false,
                        // paddingLeft: function(i, node) { return 0; },
                        // paddingRight: function(i, node) { return 0; },
                        paddingTop: function() { return 2; },
                        paddingBottom: function() { return 2; }
                    },
                    width: "*",
                    margin: [ 30, 0, 30, 0 ],
                    table: {
                        headerRows: 1,
                        widths: [ "*", "10%", "14%", "12%", "18%" ],
                        body: [
                            [
                                {
                                    border: [ true, true, false, true ],
                                    fillColor: "#eeffee",
                                    stack: [
                                        {
                                            image: huowuneirong,
                                            width: 36,
                                            height: 8,
                                            margin: [ 0, 2, 0, 0],
                                        },
                                        { text: "Descriptions of Goods", fontSize: 8, bold: true }
                                    ],
                                    alignment: "center"
                                },
                                {
                                    border: [ false, true, false, true ],
                                    fillColor: "#eeffee",
                                    stack: [
                                        {
                                            image: shuliang,
                                            width: 16,
                                            height: 8,
                                            margin: [ 0, 2, 0, 0],
                                        },
                                        { text: "Quantity", fontSize: 8, bold: true }
                                    ],
                                    alignment: "center"
                                },
                                {
                                    border: [ false, true, false, true ],
                                    fillColor: "#eeffee",
                                    stack: [
                                        {
                                            image: danjia,
                                            width: 16,
                                            height: 8,
                                            margin: [ 0, 2, 0, 0],
                                        },
                                        { text: "Unit Price", fontSize: 8, bold: true }
                                    ],
                                    alignment: "center"
                                },
                                {
                                    border: [ false, true, false, true ],
                                    fillColor: "#eeffee",
                                    stack: [
                                        {
                                            image: jiner,
                                            width: 16,
                                            height: 8,
                                            margin: [ 0, 2, 0, 0],
                                        },
                                        { text: "Amount", fontSize: 8, bold: true }
                                    ],
                                    alignment: "right"
                                },
                                {
                                    border: [ false, true, true, true ],
                                    fillColor: "#eeffee",
                                    stack: [
                                        {
                                            image: beizhu,
                                            width: 16,
                                            height: 8,
                                            margin: [ 0, 2, 0, 0],
                                        },
                                        { text: "Remark", fontSize: 8, bold: true }
                                    ],
                                    alignment: "center"
                                }
                               
                            ], ...contentBody
                        ]
                    }
                }
            ]
        }
    }
    
    static chinaElectronicInvoice (info: IInvoice, detail: IInvoiceDetail[]): any {
        let bodyContent: TableCellProps[][] = []
    
        let n = 1
        for(let i=0; i<18; i++) {
            if(detail[i]) {
                let price = Math.ceil(detail[i].price*info.bnd_to_usd*10)/10
                bodyContent.push([
                    { text: n++, fontSize: 6, alignment: "center", border: [ true, false, true, true ] },
                    { text: detail[i].brand, fontSize: 6,  alignment: "center", border: [ false, false, true, true ] },
                    { text: detail[i].item_description, fontSize: 6, border: [ false, false, true, true ] },
                    { text: detail[i].item_description1, fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: detail[i].item_description2, fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: detail[i].qty, fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: price, fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: price*detail[i].qty, fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: "", fontSize: 6, alignment: "center", border: [ false, false, true, true ] }
                ]);
            } else {
                bodyContent.push([
                    { text: " ", fontSize: 6, alignment: "center", border: [ true, false, true, true ] },
                    { text: " ", fontSize: 6,  alignment: "center", border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, alignment: "center", border: [ false, false, true, true ] },
                    { text: " ", fontSize: 6, alignment: "center", border: [ false, false, true, true ] }
                ]);
            }
        }
    
    
        return {
            info: {
                title: "abcd"
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            // left, top, right, bottom
            pageMargins: [ 30, 30, 30, 30 ],
            content: [
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                        },
                        vLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                        },
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    },
                    width: "*",
                    table: {
                        widths: [ "6.5%", "8%", "*", "9.5%", "11.5%", "8.5%", "10%", "10%", "10%" ],
                        body: [
                            [
                                { 
                                    colSpan: 9, 
                                    text: "CHINA ELECTRONICS SHENZHEN COMPANY", 
                                    bold: true, 
                                    fontSize: 14, 
                                    alignment: "center", 
                                    border: [ true, true, true, true ] 
                                }
                            ],
                            [
                                { 
                                    colSpan: 9, text: "35/F,Block A, Electronics Science & Technology Building, Shennan zhonglu, Shenzhen China", 
                                    bold: true,
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, true, true, true ] 
                                }
                            ],
                            [
                                { 
                                    colSpan: 9, text: "Attn: Tina. Mobile: 86 18355153075.", 
                                    bold: true,
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, true, true, true ] 
                                }
                            ],
                        ]
                    }
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                        },
                        vLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                        },
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    },
                    width: "*",
                    table: {
                        widths: [ "6.5%", "8%", "*", "9.5%", "11.5%", "8.5%", "10%", "10%", "10%" ],
                        body: [
                            [
                                { 
                                    colSpan: 9, 
                                    text: "COMMERCIAL INVOICE", 
                                    bold: true, 
                                    fontSize: 13, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                }
                            ]
                        ]
                    }
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                        },
                        vLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                        },
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    },
                    width: "*",
                    table: {
                        widths: [ "6.5%", "8%", "*", "9.5%", "11.5%", "8.5%", "10%", "10%", "10%" ],
                        body: [
                            [
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "Invoice NO.:", 
                                    fontSize: 7,
                                    bold: true,
                                    alignment: "right", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    colSpan: 3,
                                    text: info.notes, 
                                    fontSize: 7,
                                    bold: true,
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "PI NO.:", 
                                    fontSize: 7,
                                    bold: true,
                                    alignment: "right", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    colSpan: 3,
                                    text: info.notes1, 
                                    fontSize: 7,
                                    bold: true,
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "", 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: "IssueDate:", 
                                    fontSize: 7,
                                    bold: true,
                                    alignment: "right", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    colSpan: 3,
                                    text: new Date(info.date_time).toLocaleDateString("en-GB"), 
                                    fontSize: 7,
                                    bold: true,
                                    border: [ true, false, true, true ] 
                                },
                            ],
                        ]
                    }
                },
                {
                    columns: [
                        {
                            layout: {
                                defaultBorder: false,
                                hLineWidth: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                                },
                                vLineWidth: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                                },
                                hLineColor: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                },
                                vLineColor: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                },
                                paddingLeft: function (i: any, node: any) {
                                    return 1.5
                                },
                            },
                            // widths: [ "9%", "9%", "*", "9%", "9%", "8%", "10%", "10%", "10%" ],
                            width: "*",
                            table: {
                                widths: [ "10.96%", "13.33%", "*", "15.68%", "18.66%" ],
                                body: [
                                    [
                                        { 
                                            colSpan: 2,
                                            text: "Consignee:", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },"",
                                        { 
                                            text: "", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },
                                        { 
                                            text: "", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },
                                        { 
                                            text: "", 
                                            bold: true, 
                                            fontSize: 6, 
                                            border: [ true, false, true, true ] 
                                        },
                                    ],
                                    [
                                        { 
                                            text: "Company:", 
                                            bold: true, 
                                            alignment: "right",
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 4,
                                            text: info.location, 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        }
                                    ],
                                    [
                                        { 
                                            text: "Address:", 
                                            bold: true, 
                                            alignment: "right",
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 4,
                                            text: info.add1 + " " + info.add2 + " " + info.add3 + (info.l_id === 2 ? "\n " : ""), 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        }
                                    ],
                                    [
                                        { 
                                            text: "Contact:", 
                                            bold: true, 
                                            alignment: "right",
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 4,
                                            text: info.contact_person, 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        }
                                    ],
                                    [
                                        { 
                                            text: "Tel.:", 
                                            bold: true, 
                                            alignment: "right",
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 4,
                                            text: info.contact_no, 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ true, false, true, true ] 
                                        }
                                    ],
                                ]
                            }
                        },
                        {
                            layout: {
                                defaultBorder: false,
                                hLineWidth: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                                },
                                vLineWidth: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                                },
                                hLineColor: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                                },
                                vLineColor: function (i: any, node: any) {
                                    return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                                },
                            },
                            width: "39.28%",
                            table: {
                                widths: [ "*",  "26.18%",  "26.18%", "26.18%" ],
                                body: [
                                    [
                                        { 
                                            text: "Shipper:", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            text: "", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            text: "", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            text: "", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                    ],
                                    [
                                        { 
                                            text: "Company:",
                                            alignment: "right",
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 3,
                                            text: "CHINA ELECTRONICS SHENZHEN COMPANY", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        }
                                    ],
                                    [
                                        { 
                                            text: "Address:",
                                            alignment: "right",
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 3,
                                            text: "35/F,Block A, Electronics Science & Technology Building, Shennan zhonglu, Shenzhen China", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        }
                                    ],
                                    [
                                        { 
                                            text: "Attn:",
                                            alignment: "right",
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 3,
                                            text: "Tina", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        }
                                    ],
                                    [
                                        { 
                                            text: "Tel.:",
                                            alignment: "right",
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        },
                                        { 
                                            colSpan: 3,
                                            text: "86 18355153760", 
                                            bold: true, 
                                            fontSize: 7, 
                                            border: [ false, false, true, true ] 
                                        }
                                    ],
                                ]
                            }
                        },
                    ]
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                        },
                        vLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                        },
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    },
                    width: "*",
                    table: {
                        widths: [ "6.5%", "8%", "*", "9.5%", "11.5%", "8.5%", "10%", "10%", "10%" ],
                        body: [
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    colSpan: 2,
                                    text: "Origin of Goods: CHINA", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    colSpan: 3,
                                    text: "Trade Term : FCA HongKong", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "", "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    colSpan: 3,
                                    text: "Payment Term: CREDIT TERM", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "", "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    colSpan: 2,
                                    text: "Item/Product:", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                        ]
                    }
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 1;
                        },
                        vLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 1;
                        },
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'black';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'black';
                        },
                        paddingTop: function () { return 5; },
                        paddingBottom: function () { return 5; },
                    },
                    width: "*",
                    table: {
                        widths: [ "6.5%", "7.94%", "*", "9.5%", "11.48%", "8.46%", "9.98%", "10%", "10.02%" ],
                        body: [
                            [
                                { 
                                    text: "No.", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Brand", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Product Name", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Description", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Color", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Quantity", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Unit Price (USD)", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Total Amount (USD)", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: "Remark", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                            ], ...bodyContent,
                            [
                                { 
                                    colSpan: 3,
                                    text: "Total", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                "", "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: detail.reduce((total, row) => total + row.qty, 0), 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: detail.reduce((total, row) => total + row.qty * (Math.ceil(row.price*info.bnd_to_usd*10)/10), 0), 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },  
                            ]
                        ]
                    }
                },
                {
                    layout: {
                        defaultBorder: false,
                        hLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 1 : 0.5;
                        },
                        vLineWidth: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 1 : 0.5;
                        },
                        hLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i: any, node: any) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    },
                    width: "*",
                    table: {
                        widths: [ "6.5%", "8%", "*", "9.5%", "11.5%", "8.5%", "10%", "10%", "10%" ],
                        body: [
                            [
                                { 
                                    colSpan: 2,
                                    text: "For and on behalf of", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    colSpan: 2,
                                    text: "Company:", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    colSpan: 3,
                                    text: "CHINA ELECTRONICS SHENZHEN COMPANY", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                "", "",
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 6, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                            [
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                                { 
                                    text: " ", 
                                    bold: true, 
                                    fontSize: 7, 
                                    alignment: "center", 
                                    border: [ true, false, true, true ] 
                                },
                            ],
                        ]
                    }
                }
            ]
        }
    }
    
    static async srklCustomInvoice (info: IInvoice, detail: IInvoiceDetail[]): Promise<any> {
    
        let signature = await urlToBase64(sign_url);
    
        let bodyContent: TableCellProps[][] = [];
    
        let n = 1;
        detail.forEach(row => {
            bodyContent.push([
                { text: n++, fontSize: 10, alignment: "center" },
                { text: row.item_description, fontSize: 10 },
                { text: row.qty, fontSize: 10, alignment: "center" },
                { text: Math.ceil(row.price * 3).toFixed(2), fontSize: 10, alignment: "center" },
                { text: "", fontSize: 10, alignment: "center" },
                { text: "", fontSize: 10, alignment: "center" },
                { text: Math.ceil(row.qty * row.price * 3).toFixed(2), fontSize: 10, alignment: "right" },
            ]);
        });
    
        let total_quantity = detail.reduce((total, row) => total + row.qty, 0);
        let total = detail.reduce((total, row) => total + Math.ceil(row.qty * row.price * 3), 0);
    
        let info_title = "INV" + (Number(new Date(info.date_time).getTime().toString().slice(0, 9)) + info.so_id);
    
        return {
            info: {
                title: info_title
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            // left, top, right, bottom
            pageMargins: [ 0, 185, 0, 216.2 ],
    
            header: (currentPage: number, pageCount: number) => {
                return  [
                    {
                        margin: [ 30, 30, 30, 0 ],
                        alignment: "center",
                        columns: [
                            [
                                {
                                    text: "SRKL SDN BHD", fontSize: 19, bold: true
                                },
                                {
                                    text: "6.15 Imbi Plaza, Jalan Imbi,",
                                    fontSize: 8
                                },
                                {
                                    text: "55100 Kuala Lumpur.",
                                    fontSize: 8,
                                },
                                {
                                    text: "Sales Rep: Ray Chua   Tel: +012 398 8210",
                                    fontSize: 8
                                }
                            ]
                        ]
                    },
                    {
                        layout: {
                            defaultBorder: false,
                        },
                        width: "*",
                        margin: [ 30, 5, 30, 0 ],
                        table: {
                            headerRows: 1,
                            widths: [ "*" ],
                            body: [
                                [
                                    { text: "", border: [ false, true, false, false ] }
                                ]
                            ]
                        } 
                    },
                    {
                        margin: [ 40, 0, 40, 0 ],
                        columns: [
                            [
                                {
                                    columns: [
                                        {
                                            text: "To",
                                            fontSize: 12,
                                            bold: true,
                                            width: 200
                                        }
                                    ]
                                },
                                {
                                    margin: [ 0, 3, 0, 0 ],
                                    columns: [
                                        {
                                            text: info.location,
                                            fontSize: 10
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: info.add1,
                                            fontSize: 10
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: info.add2,
                                            fontSize: 10
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: info.add3,
                                            fontSize: 10
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            columns: [
                                                {
                                                    text: "Attn",
                                                    width: 30,
                                                    fontSize: 10
                                                },
                                                {
                                                    text: ":",
                                                    width: 15,
                                                    fontSize: 10
                                                },
                                                {
                                                    text: info.contact_person,
                                                    width: 100,
                                                    fontSize: 10
                                                },
                                            ]
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: "Tel",
                                            width: 30,
                                            fontSize: 10
                                        },
                                        {
                                            text: ":",
                                            width: 15,
                                            fontSize: 10
                                        },
                                        {
                                            text: info.contact_no,
                                            width: 100,
                                            fontSize: 10
                                        },
                                    ]
                                }
                            ],
                            [
                                {
                                    margin: [ 110, 0, 0, 0 ],
                                    columns: [
                                       [
                                           {
                                                text: "INVOICE", bold: true, margin: [ 35, 0, 0, 0 ]
                                           },
                                           {
                                                columns: [
                                                   {
                                                        text: "Inv No.",
                                                        fontSize: 10,
                                                        width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: info_title,
                                                        fontSize: 10,
                                                        width: 80
                                                   }
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                        text: "Date",
                                                        fontSize: 10,
                                                        width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: (new Date(info.date_time)).toLocaleDateString("en-GB"),
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                       text: "Term",
                                                       fontSize: 10,
                                                       width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: "C.O.D",
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           },
                                           {
                                               columns: [
                                                   {
                                                       text: "Page",
                                                       fontSize: 10,
                                                       width: 60
                                                   },
                                                   {
                                                        text: ":",
                                                        fontSize: 10,
                                                        width: 15
                                                   },
                                                   {
                                                        text: currentPage + " of " + pageCount,
                                                        fontSize: 10,
                                                        width: 80
                                                   },
                                               ]
                                           }
                                       ]
                                    ]
                                }
                            ],
                        ]
                    }
                ]
            },
            footer: [
                [
                    {
                        columns: [
                            {
                                margin: [ 25, 0, 25, 0 ],
                                layout: {
                                    defaultBorder: false
                                },
                                table: {
                                    widths: [ "5%", "*", "8%", "8%", "8%", "8%", "13%" ],
                                    headerRows: 0,
                                    body: [
                                        [
                                            { colSpan: 2, text: "Total Quantity :", fontSize: 10, border: [ false, true, false, false ], alignment: "right" },
                                            "",
                                            { text: total_quantity, border: [ false, true, false, false ], fontSize: 10, alignment: "center", margin: [ 0, 1.5, 0, 0 ] },
                                            { colSpan: 3, text: "TOTAL", fontSize: 10, border: [ true, true, false, false ] },
                                            "", "",
                                            { text: total.toFixed(2), fontSize: 10, alignment: "right", border: [ false, true, true, false ] }
                                        ],
                                        [
                                            "", "", "",
                                            { colSpan: 3, text: "DISCOUNT", fontSize: 10, border: [ true, false, false, true ] }, 
                                            "", "",
                                            { text: "0.00", fontSize: 10, alignment: "right", border: [ false, false, true, true ], margin: [ 0, 1.5, 0, 0 ] }
                                        ],
                                        [
                                            "", "", "",
                                            { colSpan: 3, text: "NET", fontSize: 10, border: [ true, false, false, false ] },
                                            "", "",
                                            { text: total.toFixed(2), fontSize: 10, alignment: "right", border: [ false, false, true, false ], margin: [ 0, 1.5, 0, 0 ] }
                                        ],
                                        [
                                            "", "", "",
                                            { colSpan: 3, text: "TAX", fontSize: 10, border: [ true, false, false, true ] },
                                            "", "",
                                            { text: "0.00", fontSize: 10, alignment: "right", border: [ false, false, true, true ], margin: [ 0, 1.5, 0, 0 ] }
                                        ],
                                        [
                                            { 
                                                colSpan: 3, 
                                                rowSpan: 4,
                                                stack: [
                                                    {
                                                        image: signature,
                                                        width: 60,
                                                        height: 45,
                                                        margin: [ 80, 0, 0, 0 ]
                                                    },
                                                    {
                                                        text: "_____________________", margin: [ 60, 0, 0, 0 ]
                                                    },
                                                    {
                                                        text: "Authorised Signature", fontSize: 9, margin: [ 72, 0, 0, 0 ]
                                                    }
                                                ]
                                            },
                                            "", "",
                                            { colSpan: 2, text: "GROSS", fontSize: 10, border: [ true, false, false, true ] },
                                            "",
                                            { text: "RM", fontSize: 10, alignment: "center", border: [ false, false, false, true ] },
                                            { text: total.toFixed(2), fontSize: 10, alignment: "right", border: [ false, false, true, true ], margin: [ 0, 1.5, 0, 0 ] }
                                        ],
                                        [
                                            "", "", "", "", "", "",
                                            { text: "60.00", alignment: "right", fontSize: 10 }
                                        ],
                                        [
                                            "", "", "", "", "",
                                            { colSpan: 2, text: (total + 60).toFixed(2), alignment: "right", fontSize: 10, border: [ false, true, false, false ] },
                                            ""
                                        ],
                                        [ "", "", "", "", "", "", "" ],
                                        [
                                            { colSpan: 4, text: "", fontSize: 10 },
                                            "", "", "", "", "", ""
                                        ],
                                    ]
                                }
                            },
                        ]
                    }
                ]
            ],
            content: [
                {
                    layout: {
                        defaultBorder: false,
                    },
                    width: "*",
                    margin: [ 25, 5, 25, 0 ],
                    table: {
                        headerRows: 1,
                        widths: [ "5%", "*", "8%", "8%", "8%", "8%", "13%" ],
                        body: [
                            [
                                {
                                    text: "No.",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                },
                                {
                                    text: "Description",
                                    alignment: "left",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                },
                                {
                                    text: "Qty",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                },
                                {
                                    text: "U/Price",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                },
                                {
                                    text: "Dis.",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                },
                                {
                                    text: "Tax",
                                    alignment: "center",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                },
                                {
                                    text: "Amount",
                                    alignment: "right",
                                    fontSize: 10,
                                    border: [ false, true, false, true ]
                                }
                            ], ...bodyContent
                        ]
                    }
                }
            ]
        }
    }
    
    static aplusCustomDuty (info: IDuty, detail: IDutyDetail[]): any {
        let uniqueLocation = detail.map(row => ({ l_id: row.l_id, location: row.location })).filter((v, i, s) => {
            return s.map(row => row.l_id).indexOf(v.l_id) === i
        })
    
        let tableData: any[] = [];
    
        uniqueLocation.forEach(row => {
            let dealer = {
                text: row.location, fontSize: 8, bold: true, margin: [ 20, 0, 0, 0 ]
            }
    
            tableData = [ ...tableData, dealer ] 
    
            let claimProduct = detail.filter(el => el.l_id === row.l_id)
    
            let allTableRow: any[][] = []
            let n = 1
            claimProduct.forEach(el => {
                let total = 0
                if(el.claim === EClaimType.ALL) {
                    total = el.qty * (el.duty + el.transport)
                }
    
                if(el.claim === EClaimType.DUTY_ONLY) {
                    total = el.qty * el.duty
                }
    
                if(el.claim === EClaimType.TRANSPORT_ONLY) {
                    total = el.qty * el.transport
                }
    
    
                let tableRow = [
                    { text: n++, fontSize: 7, alignment: "center"},
                    { text: el.brand + " " + el.product, fontSize: 7},
                    { text: el.qty, fontSize: 7, alignment: "center"},
                    { text: (el.claim === EClaimType.DUTY_ONLY || el.claim === EClaimType.ALL) ? el.duty.toFixed(2) : "0.00", fontSize: 7, alignment: "center"},
                    { text: (el.claim === EClaimType.TRANSPORT_ONLY || el.claim === EClaimType.ALL) ? el.transport.toFixed(2) : "0.00", fontSize: 7, alignment: "center"},
                    { text: total.toFixed(2), fontSize: 7, alignment: "right"},
                    { text: el.claim, fontSize: 7},
                ]
                allTableRow = [ ...allTableRow, tableRow ]
            })
    
            let totalAmount = claimProduct.reduce((total, row) => {
                if(row.claim === EClaimType.ALL) total += (row.qty * (row.duty + row.transport))
                if(row.claim === EClaimType.DUTY_ONLY) total += (row.qty * row.duty)
                if(row.claim === EClaimType.TRANSPORT_ONLY) total += (row.qty * row.transport)
                return total
            }, 0)
    
            let totalQuantity = claimProduct.reduce((total, row) => total + row.qty, 0)
    
            let tableLastRow = [
                { text: "",  border: [ false, true, false, false ] },
                { text: "Total      ", fontSize: 7, alignment: "right", border: [ false, true, false, false ] },
                { text: totalQuantity, fontSize: 7, alignment: "center",  border: [ false, true, false, false ] },
                { text: "",  border: [ false, true, false, false ] },
                { text: "",  border: [ false, true, false, false ] },
                { text: totalAmount.toFixed(2), fontSize: 7, alignment: "right",  border: [ false, true, false, false ] },
                { text: "",  border: [ false, true, false, false ] },
            ]
    
            allTableRow = [ ...allTableRow, tableLastRow ]
    
            let table =  {     
                layout: {
                    defaultBorder: false,
                    hLineColor: function () {
                        return '#D3D3D3';
                    },
                    hLineWidth: function () {
                        return 0.5;
                    },
                    paddingTop: function() { return 1.5; },
                    paddingBottom: function() { return 1.5; }
                },
                width: "*",
                margin: [ 20, 0, 20, 10 ],
                table: {
                    headerRows: 1,
                    widths: [ "3%", "*", "6%", "7%", "7%%", "6%", "12%" ],
                    body: [
                        [
                            {
                                text: "#",
                                alignment: "center",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                            {
                                text: "Item",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                            {
                                text: "Qty",
                                alignment: "center",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                            {
                                text: "Duty/Unit",
                                alignment: "center",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                            {
                                text: "Tans/Unit",
                                alignment: "center",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                            {
                                text: "Total",
                                alignment: "right",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                            {
                                text: "Claim",
                                fontSize: 7,
                                border: [ false, false, false, true ]
                            },
                        ], ...allTableRow
                    ]
                }
            }
    
            tableData = [ ...tableData, table ]
        })
    
        return {
            info: {
                title: info.running
            },
            pageSize: "A4",
            pageOrientation: "potrait",
            // left, top, right, bottom
            pageMargins: [ 20, 100, 20, 0 ],
    
            header: (currentPage: number, pageCount: number) => {
                return [
                    {
                        margin: [ 50, 25, 0, 1 ],
                        columns: [
                            { text: "Date", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: new Date(info.date_time).toLocaleDateString("en-GB"), width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 1 ],
                        columns: [
                            { text: "Doc No.", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.running, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 1 ],
                        columns: [
                            { text: "Invoice Header", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.supplier, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 1 ],
                        columns: [
                            { text: "Permit Holder", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text: info.location, width: "*", fontSize: 8 },
                        ]
                    },
                    {
                        margin: [ 50, 0, 0, 1 ],
                        columns: [
                            { text: "Page", width: 120, fontSize: 8 },
                            { text: ":", width: 10, fontSize: 8 },
                            { text:  currentPage + " of " + pageCount, width: "*", fontSize: 8 },
                        ]
                    }
                ]
            },
            content: [
                ...tableData
            ]
        }
    }
    
    static customStockOrderDetail (detail: IStockOrderDetail[]): any {
        let tableBody: (TableCellProps | string)[][] = []
    
        let tableHeader = [
            {
                text: "#",
                alignment: "center",
                fontSize: 8,
                border: [ true, true, true, true ]
            },
            {
                text: "Doc No.",
                fontSize: 8,
                border: [ false, true, true, true ]
            },
            {
                text: "Supplier",
                fontSize: 8,
                border: [ false, true, true, true ]
            },
            {
                text: "Dealer",
                fontSize: 8,
                border: [ false, true, true, true ]
            },
            {
                text: "Item Description",
                fontSize: 8,
                border: [ false, true, true, true ]
            },
            {
                text: "Qty",
                fontSize: 8,
                alignment: "center",
                border: [ false, true, true, true ]
            }
        ]
    
        detail.forEach((row, _i) => {
            tableBody.push([
                { text: _i+1, alignment: "center", fontSize: 8, border: [ true, false, true, _i + 1 === detail.length ? true : false ]},
                { text: row.running, fontSize: 8, border: [ true, false, true, _i + 1 === detail.length ? true : false ]},
                { text: row.supplier, fontSize: 8, border: [ true, false, true, _i + 1 === detail.length ? true : false ]},
                { text: row.location, fontSize: 8, border: [ true, false, true, _i + 1 === detail.length ? true : false ]},
                { text: row.brand + " " + row.product, fontSize: 8, border: [ true, false, true, _i + 1 === detail.length ? true : false ]},
                { text: row.qty, alignment: "center", fontSize: 8, border: [ true, false, true, _i + 1 === detail.length ? true : false ]},
            ]);
        });
    
    
        return {
            info: {
                title: "Stock Order Listing"
            },
            pageSize: "A4",
            pageOrientation: "portrait",
            pageMargins: [ 20, 30, 20, 20 ],
            content: [
                {
                    layout: {
                        defaultBorder: false,
                        paddingTop: function() { return 2; },
                        paddingBottom: function() { return 2; },
                        hLineWidth: tableLayoutStyle.hLineWidth(null, 1, 0.5),
                        vLineWidth: tableLayoutStyle.vLineWidth(null, 1, 0.5),
                        fillColor: tableLayoutStyle.fillColor(null, "#f0f0f0")
                    },
                    width: "*",
                    margin: [ 20, 0, 20, 0 ],
                    table: {
                        headerRows: 1,
                        widths: [ "4%", "18%", "12%", "12%", "*", "5%" ],
                        body: [
                            tableHeader,
                            ...tableBody
                        ]
                    }
                }
            ]
        }
    }
}



export default PdfDefinition
