
const urlToBase64 = async (url:any) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
        img.onload = () => {
            let canvas: HTMLCanvasElement = document.createElement('canvas')
            canvas.height = img.height;
            canvas.width = img.width;
            let ctx:any = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL('image/png')
            resolve(dataURL)
        };
        img.onerror = err => reject(err)
    })
}

export default urlToBase64

  