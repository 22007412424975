import React from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface ICardProps {
    children?: React.ReactNode
    width?: string | number
    backgroundColor?: boolean
    mediaWidth?: string
}

interface ICardBorder {
    border?: boolean
    top?: boolean
    bottom?: boolean
}

const CardContainer = styled.div<ThemePalleteProps & {width?: string | number, backgroundColor?: boolean, mediaWidth?: string}>`
    box-sizing: border-box;
    padding: ${props => props.themeSetting?.spacing.sm}rem;
    
    width: ${props => !props.width ? "fit-content" : (typeof props.width === "string" ? props.width : props.width + "rem")};
    border-radius: 0.5rem;
	background-color: ${props => props.backgroundColor ? props.themeSetting?.pallete.background.light : props.themeSetting?.pallete.background.default};
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

    @media (max-width: 768px) {
        padding: calc(${props => props.themeSetting?.spacing.sm}rem / 1.5) calc(${props => props.themeSetting?.spacing.sm}rem / 2);
        width: ${props => props.mediaWidth ? props.mediaWidth : null};
    }
`

const Card = (props: ICardProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <CardContainer 
        themeSetting={theme} 
        pallete={pallete} 
        width={props.width} 
        backgroundColor={props.backgroundColor}
        mediaWidth={props.mediaWidth}
        >{props.children}</CardContainer>
    )
}

const CardHeaderContainer = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    font-size: calc(${props => props.themeSetting?.typography.fontSize}rem * 1.2);
    user-select: none;
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: calc(${props => props.themeSetting?.typography.media}rem * 1.2);
    }
`

const CardBorder = styled.div<ThemePalleteProps & ICardBorder>`
    border-bottom: 0.1rem solid ${props => props.themeSetting?.pallete.neutral.light};
    opacity: 0.3;
    visibility: visible;
    user-select: none;

    transition: visibility 500ms ease-in-out, opacity 500ms ease-in-out;

    margin-bottom: ${props => props.bottom ? `calc(${props.themeSetting?.spacing.sm}rem /3)` : 0 };
    margin-top: ${props => props.top ? `calc(${props.themeSetting?.spacing.sm}rem /3)` : 0 };
`

const CardHeader = (props: ICardProps & ICardBorder) => {
    const {theme} = useThemeSetting()

    return (
        <CardHeaderContainer themeSetting={theme}>
            {props.children}
            {props.border && <CardBorder themeSetting={theme} top />}
        </CardHeaderContainer>
    )
}

const CardBodyContainer = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    padding: calc(${props => props.themeSetting?.spacing.md}rem) 0;
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;

    @media (max-width: 768px) {
        padding: 0.5rem;
        font-size: ${props => props.themeSetting?.typography.media}rem;
    }
`

const CardBody = (props: ICardProps) => {
    const {theme} = useThemeSetting()
    return (
        <CardBodyContainer themeSetting={theme}>
            {props.children}
        </CardBodyContainer>
    )
}

const CardFooterContainer = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
    }
`

const CardFooter = (props: ICardProps & ICardBorder) => {
    const {theme} = useThemeSetting()

    return (
        <CardFooterContainer themeSetting={theme}>
            {props.border && <CardBorder themeSetting={theme} bottom />}
            {props.children}
        </CardFooterContainer>
    )
}

export {Card, CardHeader, CardBody, CardFooter}