import { createContext } from "react";
import { IColorPalette, ThemeMode, ThemeType } from "../../colorPallete";

export interface IColorModeContext {
    toggleColorMode: () => void
    mode: ThemeMode
    theme: ThemeType
    pallete: IColorPalette
}

export const colorModeContext = createContext<IColorModeContext | null>(null)