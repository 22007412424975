import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardFooter, CardHeader } from '../components/Card'
import Input from '../components/Input'
import PageContent from '../components/PageContent'
import Select from '../components/Select'
import { fetchSupplierById } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'

const breadCrumbItems = [
    { name: "Supplier Maintenance", navigate: "/supplier" },
    { name: "Supplier", active: true },
]


const Supplier = () => {
    let history = useNavigate()
    const {s_id} = useParams()
    const {token, setToken} = useLogin()
    const [supplier, setSupplier] = useState("")
    const [type, setType] = useState("")
    const [reload, setReload] = useState(false)

    const {setAlert} = useAlert()

    const fetchData = useCallback(async () => {
        try {
            if(s_id && s_id !== "new") {
                let _data = await fetchSupplierById(token, s_id)
                setSupplier(_data.supplier)
                setType(_data.type)
            } else {
                setSupplier("")
                setType("")
            }
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, s_id, setAlert, setReload, reload, setToken])

    const memoButton = useMemo(() => {
        if(s_id === "new") return []

        return [
            {
                name: "New",
                action: "/supplier/new",
                color: "blue"
            }
        ] as BreadCrumbButtonProps[]
    }, [s_id])

    const handleSubmit = () => {
        if (!supplier || !supplier.trim()) return setAlert({ show: true, type: "danger", message: "Please insert supplier name..." })
        if (!type) return setAlert({ show: true, type: "danger", message: "Please select supplier type..." })


        setAlert({
            show: true,
            message: s_id === "new" ? "Create New Supplier ?" : "Update Supplier ?",
            type: "question",
            cb: async () => {
                try {
                    if (s_id === "new") {
                        let result = await axios.post("/supplier", {
                            supplier,
                            type
                        })

                        if (result.status !== 201) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }

                        setAlert({
                            show: true,
                            message: "Supplier Created Successfully !!!",
                            type: "success",
                            cb: () => {
                                history("/supplier/" + result.data.s_id)
                            }
                        })
                    } else {
                        let result = await axios.patch("/supplier/" + s_id, {
                            supplier,
                            type
                        })

                        if (result.status !== 200) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }

                        setAlert({
                            show: true,
                            message: "Supplier Updated Successfully !!!",
                            type: "success"
                        })
                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger",
                        cb: () => {
                            setAlert({
                                show: true,
                                message: err.message,
                                type: "danger"
                            })
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb 
            items={breadCrumbItems}
            button={memoButton}
            />
            <PageContent>
                <Card mediaWidth='100%'>
                    <CardHeader border>{s_id === "new" ? "New Supplier" : "Edit Supplier # " + s_id}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="1rem">
                            <Input mediaWidth='100%' width="30rem" label="Supplier" value={supplier} onChange={e => setSupplier(e.target.value)} placeholder="Insert supplier..." />
                            <Select width="30rem" mediaWidth='100%' label="Type" value={type} onChange={e => setType(e.target.value)}>
                                <option value="">Select Type</option>
                                <option value="permit">Permit</option>
                                <option value="order">Order</option>
                            </Select>
                        </Box>
                    </CardBody>
                    <CardFooter border>
                        <Box justifyContent='flex-end'>
                        <Button color="green" onClick={handleSubmit}>Submit</Button>
                        </Box>
                    </CardFooter>
                </Card>
            </PageContent>
        </>
    )
}

export default Supplier