import React, { useState } from 'react'
import sidebarContext from './sidebarContext'

const SidebarState = (props: {
    children: React.ReactNode
}) => {
    const [isCollapse, setIsCollapse] = useState(false)

    return (
        <sidebarContext.Provider value={{ isCollapse, setIsCollapse }}>
            {props.children}
        </sidebarContext.Provider>
    )
}

export default SidebarState