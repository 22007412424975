import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import useAlert from '../hooks/useAlert'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'
import Box from './Box'
import Button from './Button'
import Modal, { ModalBorder } from './Modal'

const StyledMessageBox = styled(Box)<ThemePalleteProps>`
    align-items: center;
    justify-content: center;
    font-weight: 500;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
    }
`

const Alert = () => {
    const {alert, setAlert} = useAlert()
    const {theme, pallete} = useThemeSetting()

    const ref = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if(alert.show && ref.current) {
            let timeout = setTimeout(() => {
                ref.current?.focus()
            }, 100)

            return () => clearTimeout(timeout)
        }
    }, [alert.show])

    return (
        <Modal 
        mediaWidth='50%'
        zIndex={3}
        width="25rem"
        show={alert.show}
        closeModal={() => setAlert(v => ({ ...v, show: false }))}
        title={alert.type === "success" ? "Success" : (alert.type === "danger" ? "Alert" : "Question")}
        >
            <Box flexDirection='column' gap="1rem">
                <StyledMessageBox themeSetting={theme}>
                    {alert.message}
                </StyledMessageBox>
                <Box width="100%" flexDirection='column' gap="0.5rem">
                    <ModalBorder themeSetting={theme} pallete={pallete} />
                    <Box alignItems='center' justifyContent='space-around'>
                        {
                            alert.type === "question" &&
                            <Button color="red" onClick={() => {
                                setAlert(v => ({ ...v, show: false }))
                            }}>{alert.cancel ? alert.cancel : "Cancel"}</Button>
                        }
                        <Button 
                        forwardedRef={ref}
                        autoFocus 
                        color="green" 
                        onClick={() => {
                            alert.cb && alert.cb()
                            setAlert(v => ({ ...v, show: false }))
                        }}>{alert.ok ? alert.ok : "Ok"}</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default Alert