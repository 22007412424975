import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardFooter, CardHeader } from '../components/Card'
import Input from '../components/Input'
import { IconGroupContainer } from '../components/ManageTable'
import Modal from '../components/Modal'
import PageContent from '../components/PageContent'
import { Table, TD, TH, TR } from '../components/Table'
import { fetchLocation, fetchStockOrderById, fetchSupplier } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { ResetIcon } from '../icons/icon'
import { ILocation, ISupplier } from '../types/types'

interface PopModalProps {
    modal: {
        show: boolean
        data: "" | "invoice header" | "permit holder"
    }
    setModal: React.Dispatch<React.SetStateAction<{
        show: boolean
        data: "" | "invoice header" | "permit holder"
    }>>
    locations: ILocation[]
    suppliers: ISupplier[]

    handleSelectLocation: (data: ILocation) => void
    handleSelectSupplier: (data: ISupplier) => void
    clearSelection: (type: "" | "permit holder" | "invoice header") => void
}

const breadCrumbItems = [
    { name: "Stock Order Maintenance", navigate: "/stockOrder" },
    { name: "Stock Order", active: true },
]

const initialModal: {
    show: boolean
    data: "" | "invoice header" | "permit holder"
} = {
    show: false,
    data: ""
}

const initialLocation: {
    l_id: null | number
    location: string
} = {
    l_id: null,
    location: ""
}

const initialSupplier: {
    s_id: null | number
    supplier: string
} = {
    s_id: null,
    supplier: ""
}

const PopModal = React.memo((modalProps: PopModalProps) => {
    const [filterModal, setFilterModal] = useState("")

    const modalInputRef = useRef<HTMLInputElement>(null)

    const handleModalInputOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            if (modalProps.modal.data === "permit holder") {
                let _location = modalProps.locations.filter(row => row.location.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1)

                if (_location.length !== 1) return

                modalProps.handleSelectLocation(_location[0])
                setFilterModal("")
                return
            }

            if (modalProps.modal.data === "invoice header") {
                let _supplier = modalProps.suppliers.filter(row => row.supplier.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1)

                if (_supplier.length !== 1) return

                modalProps.handleSelectSupplier(_supplier[0])
                setFilterModal("")
                return
            }
        }
    }

    useEffect(() => {
        if (modalInputRef.current && modalProps.modal.show) {
            let timeout = setTimeout(() => {
                modalInputRef.current?.focus()
            }, 100)

            return () => clearTimeout(timeout)
        }
    }, [modalProps.modal.show])

    return <Modal
        width="20%"
        title={(modalProps.modal.data && modalProps.modal.data[0].toUpperCase() + modalProps.modal.data.slice(1)) + ' List'}
        show={modalProps.modal.show}
        closeModal={() => modalProps.setModal(v => ({ ...v, show: !v.show }))}
        clickOutside
    >
        <Box flexDirection='column' gap="0.5rem">
            <Input
                width="100%"
                placeholder='Search'
                value={filterModal}
                onChange={e => setFilterModal(e.target.value)}
                forwardedRef={modalInputRef}
                onKeyPress={handleModalInputOnKeyPress}
            />
            <Table
                height="50vh"
                header={
                    <>
                        <TH>ID</TH>
                        <TH>{modalProps.modal.data && modalProps.modal.data.toString()[0].toUpperCase() + modalProps.modal.data.slice(1)}</TH>
                    </>
                }
                body={
                    <>
                        {
                            modalProps.modal.data && (
                                modalProps.modal.data === "permit holder"
                                    ? modalProps.locations.filter(row => row.location.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1).map(row => {
                                        return <TR key={"location" + row.l_id} onDoubleClick={() => {
                                            modalProps.handleSelectLocation(row)
                                            setFilterModal("")
                                        }}>
                                            <TD>{row.l_id}</TD>
                                            <TD>{row.location}</TD>
                                        </TR>
                                    })
                                    : modalProps.suppliers.filter(row => row.supplier.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1).map(row => {
                                        return <TR key={"supplier" + row.s_id} onDoubleClick={() => {
                                            modalProps.handleSelectSupplier(row)
                                            setFilterModal("")
                                        }}>
                                            <TD>{row.s_id}</TD>
                                            <TD>{row.supplier}</TD>
                                        </TR>
                                    })
                            )
                        }
                    </>
                }
            />
            <Box alignItems='center' justifyContent='center'>
                <IconGroupContainer
                onClick={() => modalProps.clearSelection(modalProps.modal.data)}
                >
                    <ResetIcon />
                    Clear Selection
                </IconGroupContainer>
            </Box>
        </Box>
    </Modal>
})

const StockOrder = () => {
    let history = useNavigate()
    const {so_id} = useParams()
    const {token, setToken} = useLogin()
    const [supplier, setSupplier] = useState(initialSupplier)
    const [location, setLocation] = useState(initialLocation)

    const [suppliers, setSuppliers] = useState<ISupplier[]>([])
    const [locations, setLocations] = useState<ILocation[]>([])

    const [modal, setModal] = useState(initialModal)
    const [reload, setReload] = useState(false)

    const {setAlert} = useAlert()

    const fetchRefData = useCallback(async () => {
        try {
            let [_supplier, _location] = await Promise.all([
                fetchSupplier(token), fetchLocation(token)
            ])
    
            setSuppliers(_supplier.filter(row => row.type === "permit"))
            setLocations(_location.filter(row => row.type === "permit"))
        } catch (err: any) {
            console.log(err)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }

    }, [token, setToken, setReload, setAlert, reload])

    const fetchData = useCallback(async () => {
        try {
            if(so_id && so_id !== "new") {
                let _data = await fetchStockOrderById(token, so_id)
                setSupplier({
                    s_id: _data.s_id,
                    supplier: _data.supplier
                })
                setLocation({
                    l_id: _data.l_id,
                    location: _data.location
                })
            } else {
                setSupplier(initialSupplier)
                setLocation(initialLocation)
            }
        } catch (err: any) {
            console.log(err)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }

    }, [token, so_id, reload, setAlert, setToken, setReload])

    const memoButton = useMemo(() => {
        if(so_id === "new") return []

        return [
            {
                name: "New",
                action: "/stockOrder/new",
                color: "blue"
            },
            {
                name: "Order",
                action: "/orderForm/" + so_id,
                color: "red" 
            }
        ] as BreadCrumbButtonProps[]
    }, [so_id])


    const handleSelectLocation = useCallback((_data: ILocation) => {
        setLocation({
            l_id: _data.l_id,
            location: _data.location
        })
        setModal(v => ({ ...v, show: !v.show }))
    }, [])

    const handleSelectSupplier = useCallback((_data: ISupplier) => {
        setSupplier({
            s_id: _data.s_id,
            supplier: _data.supplier
        })
        setModal(v => ({ ...v, data: "permit holder" }))
    }, [])

    const clearSelection = useCallback((type: "" | "permit holder" | "invoice header") => {
        if(type === "") return
        if(type === "permit holder") {
            return setLocation(initialLocation)
        }
        return setSupplier(initialSupplier)
    }, [])

    const hadnleSubmit = () => {
        if(!supplier.s_id) return setAlert({ show: true, message: "Please select invoice header...", type: "danger" })
        if(!location.l_id) return setAlert({ show: true, message: "Please select permit holder...", type: "danger" })

        setAlert({
            show: true,
            message: so_id === "new" ? "Create New Stock Order ?" : "Update Stock Order ?",
            type: "question",
            cb: async () => {
                try {
                    if(so_id === "new") {
                        let result = await axios.post("/stockOrder", {
                            s_id: supplier.s_id,
                            l_id: location.l_id
                        })

                        if(result.status !== 201) {
                            setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                            return
                        }
                        setAlert({
                            show: true,
                            message: "New Stock Order Created Successfully !!!",
                            type: "success",
                            cb: () => {
                                history("/stockOrder/" + result.data.so_id)
                            }
                        })
                    } else {
                        let result = await axios.patch("/stockOrder/" + so_id, {
                            s_id: supplier.s_id,
                            l_id: location.l_id
                        })

                        if(result.status !== 200) {
                            setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                            return
                        }
                        
                        setAlert({
                            show: true,
                            message: "Stock Order updated Successfully !!!",
                            type: "success"
                        })
                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }

    useEffect(() => {
        fetchRefData()
    }, [fetchRefData])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb 
            items={breadCrumbItems}
            button={memoButton}
            />
            <PageContent>
                <Card mediaWidth='100%'>
                    <CardHeader border>{so_id === "new" ? "New StockOrder" : "Edit StockOrder # " + so_id}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="1rem">
                            <Input 
                            mediaWidth='100%'
                            width="30rem" 
                            label="Invoice Header" 
                            value={supplier.supplier} 
                            iconType="add" 
                            readOnly 
                            placeholder="Select Invoice Header..." 
                            onClick={() => {
                                setModal(v => ({ show: !v.show, data: "invoice header" }))
                            }}
                            />
                            <Input 
                            mediaWidth='100%'
                            width="30rem" 
                            label="Permit Holder" 
                            value={location.location} 
                            iconType="add" 
                            readOnly 
                            placeholder="Select Permit Holder..." 
                            onClick={() => {
                                setModal(v => ({ show: !v.show, data: "permit holder" }))
                            }}
                            />
                        </Box>
                    </CardBody>
                    <CardFooter border>
                        <Box justifyContent='flex-end'>
                        <Button color="green" onClick={hadnleSubmit}>Submit</Button>
                        </Box>
                    </CardFooter>
                </Card>
            </PageContent>
            <PopModal 
            handleSelectLocation={handleSelectLocation}
            handleSelectSupplier={handleSelectSupplier}
            modal={modal}
            setModal={setModal}
            suppliers={suppliers}
            locations={locations}
            clearSelection={clearSelection}
            />
        </>
    )
}

export default StockOrder