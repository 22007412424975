import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { ThemePalleteProps } from '../types/types'
import Box from './Box'
import { FaTimes } from "react-icons/fa"
import useThemeSetting from '../hooks/useThemeSetting'

interface ModalProps {
    width?: string | number
    title?: string
    show?: boolean
    closeModal?: () => void
    children?: React.ReactNode
    clickOutside?: boolean
    zIndex?: number
    mediaWidth?: string
}

const ModalBackDrop = styled.div<{ zIndex?: number }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${props => !props.zIndex ? 2 : props.zIndex};

    opacity: 0;
    visibility: hidden;

    transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;

    &.active {
        opacity: 1;
        visibility: visible;
    }
`

export const ModalBorder = styled.div<ThemePalleteProps>`
    border-bottom: 0.1rem solid ${props => props.themeSetting?.pallete.neutral.light};
    opacity: 0.3;
    visibility: visible;
    user-select: none;
    margin-top: ${props => `calc(${props.themeSetting?.spacing.sm}rem /3)`};
`

const ModalContainer = styled.div<ThemePalleteProps & { width?: string | number, mediaWidth?: string }>`
    width: ${props => !props.width ? "fit-content" : (typeof props.width === "string" ? props.width : props.width + "rem")};
	background-color: ${props => props.themeSetting?.pallete.background.default};
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    border-radius: 0.5rem;
    padding: ${props => props.themeSetting?.spacing.sm}rem;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    @media (max-width: 768px) {
        width: ${props => props.mediaWidth ? props.mediaWidth : "85%"};
    }
`

const ModalHeader = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.font.light};
    padding-bottom: 0;
    font-size: calc(${props => props.themeSetting?.typography.fontSize}rem * 1.2);
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
	}
`

const ModalBody = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.font.light};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    padding-top: ${props => props.themeSetting?.spacing.sm}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
	}
`

const TimesIcon = styled(FaTimes)<ThemePalleteProps>`
    font-size: 1rem;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.media}rem;
	}
`

const StyledheaderTitle = styled.div<ThemePalleteProps>`
    font-size: ${props => props.themeSetting?.typography.h5.fontSize}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    }
`

const Modal = (props: ModalProps) => {
    const {theme, pallete} = useThemeSetting()

    const modalRef = useRef<HTMLDivElement>(null)

    const handleCloseModalFunction = useMemo(() => props.closeModal ? props.closeModal : undefined, [props.closeModal])

    const listener = useCallback((e: any) => {
        if(modalRef.current) {
            if (modalRef.current !== e.target) {
                return;
            }
            handleCloseModalFunction && handleCloseModalFunction()
        }
    },[handleCloseModalFunction])

    useEffect(() => {
        if(props.clickOutside) {
            document.addEventListener("click", listener);
            return () => {
                document.removeEventListener("click", listener);
            };
        }
    }, [listener, props.clickOutside])

    return (
        <ModalBackDrop className={"modal" + (props.show ? " active" : "")} ref={modalRef} zIndex={props.zIndex}>
            <ModalContainer themeSetting={theme} pallete={pallete} width={props.width} mediaWidth={props.mediaWidth}>
                <ModalHeader themeSetting={theme} pallete={pallete}>
                    <Box justifyContent='space-between' alignItems='center'>
                        <StyledheaderTitle themeSetting={theme}>{props.title}</StyledheaderTitle>
                        &nbsp;<TimesIcon themesetting={theme} onClick={() => props.closeModal && props.closeModal()} />
                    </Box>
                </ModalHeader>
                <ModalBorder themeSetting={theme} pallete={pallete} />
                <ModalBody themeSetting={theme} pallete={pallete}>
                    {props.children}
                </ModalBody>
            </ModalContainer>
        </ModalBackDrop>
    )
}

export default Modal