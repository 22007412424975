import React, { useCallback, useMemo, useState } from 'react'
import { colorPalette, ThemeMode, themeSetting } from '../../colorPallete'
import { colorModeContext } from './colorModeContext'

export const ColorModeState = ({ children }: { children: React.ReactNode }) => {
    const [mode, setMode] = useState<ThemeMode>(() => {
        let _themeMode = window.localStorage.getItem("themeMode")
        let _theme = "dark"
        if(!_themeMode) {
            window.localStorage.setItem("themeMode", JSON.stringify(_theme))
            return _theme
        }
        return JSON.parse(_themeMode)
    })

    const toggleColorMode = useCallback(() => {
        let newMode: ThemeMode = mode === "dark" ? "light" : "dark"
        setMode(newMode)
        window.localStorage.setItem("themeMode", JSON.stringify(newMode))
    }, [mode])

    const theme = useMemo(() => (
        themeSetting(mode)
    ), [mode])

    const pallete = useMemo(() => colorPalette(mode), [mode])

    return (
        <colorModeContext.Provider value={{ toggleColorMode, mode, theme, pallete }}>
            {children}
        </colorModeContext.Provider>
    )
}

