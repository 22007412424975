import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import ManageTable from '../components/ManageTable'
import { TableHeaderDataProps } from '../components/Table'
import TextButton from '../components/TextButton'
import { fetchInvoice, fetchInvoiceDetail } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import PdfHandler from '../helpers/pdfHandler'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import {DeleteIcon, DownloadIcon, EditIcon} from '../icons/icon'
import { IInvoice } from '../types/types'
import getInvoiceDoc from '../helpers/getInvoiceDoc'

const breadCrumbItems = [
    { name: "Invoice Maintenance", active: true },
]

let defaultHeader: TableHeaderDataProps[] = [
    { key: "invoice_id", name: "ID", width: "4%", sort: "asc" },
    { key: "date_time", name: "Date", width: "6%", sort: "unsort" },
    { key: "show_running", name: "Running", width: "15%", sort: "unsort" },
    { key: "supplier", name: "Invoice Header", sort: "unsort" },
    { key: "location", name: "Permit Holder", sort: "unsort" },
    { key: "action", name: "Action", align: "center", width: "5%" },
    { key: "duty", name: "Duty", align: "center", width: "5%" }
]

const DUTY_INVOICE_HEADER = "Aplus Brunei"

const InvoiceList = () => {
    let history = useNavigate()

    const {token, setToken} = useLogin()
    
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)

    const {setAlert} = useAlert()

    const handleDelete = useCallback((_data: IInvoice) => {
        setAlert({
            message: "Delete Invoice # " + _data.running + " ?",
            type: "question",
            show: true,
            cb: async () => {
                try {
                    let result = await axios.delete("/invoice/" + _data.invoice_id)
        
                    if(result.status !== 200) {
                        return setAlert({
                            show: true,
                            type: "danger",
                            message: result.data.message
                        })
                    }
        
                    return setAlert({
                        show: true,
                        type: 'success',
                        message: "Invoice Deleted !!!",
                        cb: () => {
                            setData(v => v.filter(row => row.invoice_id !== _data.invoice_id))
                        }
                    })
        
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message
                    })
                }
            }
        })
    }, [setAlert])

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            let [_data, _detail] = await Promise.all([
                fetchInvoice(token), fetchInvoiceDetail(token)
            ])
            if(Array.isArray(_data)) {
                setData(_data.map(row => ({ 
                    ...row,
                    show_running: <Box justifyContent='space-between' alignItems='center'>
                        <TextButton
                            onClick={async () => {
                                let currentDetail = _detail.filter(el => el.invoice_id === row.invoice_id)
                                let _doc = await getInvoiceDoc(row, currentDetail)
                                return new PdfHandler(_doc, row.running).view()
                            }}
                        >{row.running}</TextButton>
                        <DownloadIcon iconcolor="red" onClick={async () => {
                            let currentDetail = _detail.filter(el => el.invoice_id === row.invoice_id)
                            let _doc = await getInvoiceDoc(row, currentDetail)
                            return new PdfHandler(_doc, row.running).download()
                        }} />
                    </Box>,
                    action: !row.duty_id 
                        ? <Box justifyContent='center' alignItems='center'>
                            <EditIcon iconcolor="blue" iconsize='h6' onClick={() => history("/invoice/" + row.invoice_id)} />
                            <DeleteIcon iconcolor="red" iconsize='h6' onClick={() => handleDelete(row)} />
                        </Box> 
                        : "",
                    duty: !row.duty_id && row.supplier === DUTY_INVOICE_HEADER
                        ? <Box justifyContent='center' alignItems='center'>
                            <EditIcon iconcolor="blue" iconsize='h6' onClick={() => history("/duty/new", {
                                state: {
                                    so_id: row.so_id
                                }
                            })} />
                        </Box>
                        : ""
                })))
                return setLoading(false)
            }
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, setAlert, history, setToken, reload, handleDelete])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb 
            items={breadCrumbItems} 
            button={[
                {
                    name: "New Invoice",
                    color: "blue",
                    action: () => history("/invoice/new")
                }
            ]}
            />
            <ManageTable 
            defaultHeader={defaultHeader}
            columnSetting="invoiceList"
            data={data}
            filterKey={["running", "supplier", "location"]}
            loading={loading}
            />
        </>
    )
}

export default InvoiceList