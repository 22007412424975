export const matchObjectSearch = <T> (obj: T, key: Array<keyof T>, matchValue: string) => {
    // escape special character and set match to global and insensitive
    let matchedValue = new RegExp(escapeRegExp(matchValue), "gi");
    if(Array.isArray(key)) {
        return key.some(el => {
            const val = obj[el]
            if(!val) return false;
            if(val === null) return false;

            if(typeof val === "string") {
                return val.toString().match(matchedValue);
            }
            return false

        });
    } else {
        return false
    }

    function escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/gi, '\\$&')
    }
}