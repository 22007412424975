import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import Button from '../components/Button'
import Input from '../components/Input'
import ManageTable from '../components/ManageTable'
import Modal from '../components/Modal'
import { Table, TableHeaderDataProps, TD, TH, TR } from '../components/Table'
import { fetchLocation, fetchStockOrderDetail, fetchSupplier } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { EditIcon } from '../icons/icon'
import { ILocation, ISupplier } from '../types/types'

interface IOrderDetail {
    so_detail_id: null | number
    running: string
    s_id: null | number
    supplier: string
    l_id: null | number
    location: string
    item_description: string
    qty: number
}

const breadCrumbItems = [
    { name: "Stock Order Maintenance", navigate: "/stockOrder" },
    { name: "Stock Order Detail Maintenance", active: true },
]

let defaultHeader: TableHeaderDataProps[] = [
    { key: "so_detail_id", name: "ID", width: "4%", sort: "asc" },
    { key: "running", name: "Doc Running", width: "10%", sort: "unsort" },
    { key: "supplier", name: "Supplier", sort: "unsort" },
    { key: "location", name: "Dealer", sort: "unsort" },
    { key: "product", name: "Product Description", sort: "unsort" },
    { key: "model_no", name: "Model No.", width: "10%" },
    { key: "imei_req", name: "Imei Req.", width: "5%" },
    { key: "qty", name: "Quantity", width: "4%", align: "center" },
    { key: "action", name: "Action", width: "4%", align: "center" },
]

const initialDetail: IOrderDetail = {
    so_detail_id: null,
    running: "",
    s_id: null,
    supplier: "",
    l_id: null,
    location: "",
    item_description: "",
    qty: 0
}

const initialModal: {
    show: boolean
    data: "" | "location" | "supplier"
} = {
    show: false,
    data: ""
}


const PopModal = React.memo((modalProps: {
    showModal: {
        show: boolean
        data: "" | "location" | "supplier"
    }
    setShowModal: React.Dispatch<React.SetStateAction<{
        show: boolean
        data: "" | "location" | "supplier"
    }>>
    locations: ILocation[]
    suppliers: ISupplier[]
    setOrderDetail: React.Dispatch<React.SetStateAction<IOrderDetail>>
}) => {
    const [filterModal, setFilterModal] = useState("")

    const modalInputRef = useRef<HTMLInputElement>(null)

    const handleSelectSupplier = useCallback((_data: ISupplier) => {
        modalProps.setOrderDetail(v => ({
            ...v,
            s_id: _data.s_id,
            supplier: _data.supplier
        }))
    }, [modalProps])

    const handleSelectLocation = useCallback((_data: ILocation) => {
        modalProps.setOrderDetail(v => ({
            ...v,
            l_id: _data.l_id,
            location: _data.location
        }))
    }, [modalProps])

    const handleModalInputOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            if (modalProps.showModal.data === "location") {
                let _location = modalProps.locations.filter(row => row.location.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1)

                if (_location.length !== 1) return

                handleSelectLocation(_location[0])
                setFilterModal("")
            }

            if (modalProps.showModal.data === "supplier") {
                let _supplier = modalProps.suppliers.filter(row => row.supplier.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1)

                if (_supplier.length !== 1) return

                handleSelectSupplier(_supplier[0])
                setFilterModal("")

            }

            return modalProps.setShowModal(initialModal)
        }
    }

    useEffect(() => {
        if (modalInputRef.current && modalProps.showModal.show) {
            let timeout = setTimeout(() => {
                modalInputRef.current?.focus()
            }, 100)

            return () => clearTimeout(timeout)
        }
    }, [modalProps.showModal.show])

    return <Modal
        width="20%"
        title={(modalProps.showModal.data && modalProps.showModal.data[0].toUpperCase() + modalProps.showModal.data.slice(1)) + ' List'}
        show={modalProps.showModal.show}
        closeModal={() => modalProps.setShowModal(v => ({ ...v, show: !v.show }))}
        clickOutside
    >
        <Box flexDirection='column' gap="1rem">
            <Input
                width="100%"
                placeholder='Search'
                value={filterModal}
                onChange={e => setFilterModal(e.target.value)}
                forwardedRef={modalInputRef}
                onKeyPress={handleModalInputOnKeyPress}
            />
            <Table
                height="50vh"
                header={
                    <>
                        <TH>ID</TH>
                        <TH>{modalProps.showModal.data}</TH>
                    </>
                }
                body={
                    <>
                        {
                            modalProps.showModal.data && (
                                modalProps.showModal.data === "location"
                                    ? modalProps.locations.filter(row => row.location.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1).map(row => {
                                        return <TR key={"location" + row.l_id} onDoubleClick={() => {
                                            handleSelectLocation(row)
                                            setFilterModal("")
                                            return modalProps.setShowModal(initialModal)
                                        }}>
                                            <TD>{row.l_id}</TD>
                                            <TD>{row.location}</TD>
                                        </TR>
                                    })
                                    : modalProps.suppliers.filter(row => row.supplier.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1).map(row => {
                                        return <TR key={"supplier" + row.s_id} onDoubleClick={() => {
                                            handleSelectSupplier(row)
                                            setFilterModal("")
                                            return modalProps.setShowModal(initialModal)
                                        }}>
                                            <TD>{row.s_id}</TD>
                                            <TD>{row.supplier}</TD>
                                        </TR>
                                    })
                            )
                        }
                    </>
                }
            />
        </Box>
    </Modal>
})

const EditModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    orderDetail: IOrderDetail
    setOrderDetail: React.Dispatch<React.SetStateAction<IOrderDetail>>
    setShowPopModal: React.Dispatch<React.SetStateAction<{
        show: boolean
        data: "" | "location" | "supplier"
    }>>
    setData: React.Dispatch<React.SetStateAction<any[]>>
}) => {

    const { setAlert } = useAlert()

    const handleUpdateDetail = () => {
        if (!props.orderDetail.s_id) return setAlert({ show: true, message: "Please select supplier...", type: "danger" })
        if (!props.orderDetail.l_id) return setAlert({ show: true, message: "Please select dealer...", type: "danger" })
        if (!props.orderDetail.qty) return setAlert({ show: true, message: "Please insert quantity...", type: "danger" })

        if (isNaN(Number(props.orderDetail.qty))) {
            return setAlert({ show: true, message: "Invalid quantity...", type: "danger" })
        }

        setAlert({
            show: true,
            message: "Update Item ?",
            type: "question",
            cb: async () => {
                try {

                    let result = await axios.patch("/stockOrderDetail/" + props.orderDetail.so_detail_id, {
                        s_id: props.orderDetail.s_id,
                        l_id: props.orderDetail.l_id,
                        qty: props.orderDetail.qty
                    })

                    if (result.status !== 200) {
                        setAlert({ show: true, message: result.data.message, type: "danger" })
                        return
                    }

                    return setAlert({
                        show: true,
                        message: "Item Updated Successfully !!!",
                        type: "success",
                        cb: () => {
                            props.setData(v => {
                                return v.map(row => {
                                    if (row.so_detail_id === props.orderDetail.so_detail_id) {
                                        return {
                                            ...row,
                                            s_id: result.data.s_id,
                                            supplier: result.data.supplier,
                                            l_id: result.data.l_id,
                                            location: result.data.location,
                                            qty: result.data.qty
                                        }
                                    }
                                    return row
                                })
                            })

                            props.setShowModal(v => !v)
                        }
                    })
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({ show: true, message: err.message, type: "danger" })
                }
            }
        })
    }

    return <Modal
        width="fit-content"
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
        title="Edit Order Detail"
    >
        <Box flexDirection='column' gap="1rem">
            <Input iconType='add' label="Supplier" width="30rem" placeholder='Select supplier...' value={props.orderDetail.supplier} readOnly onClick={() => {
                props.setShowPopModal(v => ({
                    ...v,
                    show: true,
                    data: "supplier"
                }))
            }} />
            <Input iconType='add' label="Dealer" width="30rem" placeholder='Select dealer...' value={props.orderDetail.location} readOnly onClick={() => {
                props.setShowPopModal(v => ({
                    ...v,
                    show: true,
                    data: "location"
                }))
            }} />
            <Input label="Product" width="30rem" value={props.orderDetail.item_description} readOnly />
            <Input label="Quantity" width="30rem" value={props.orderDetail.qty.toString()} onChange={e => {
                props.setOrderDetail(v => ({
                    ...v,
                    qty: (e.target.value as unknown as number)
                }))
            }} />
            <Button color='green' onClick={handleUpdateDetail}>Update</Button>
        </Box>
    </Modal>
})

const StockOrderDetailList = () => {
    const { token, setToken } = useLogin()
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showPopModal, setShowPopModal] = useState(initialModal)

    const [suppliers, setSuppliers] = useState<ISupplier[]>([])
    const [locations, setLocations] = useState<ILocation[]>([])

    const [orderDetail, setOrderDetail] = useState(initialDetail)

    const { setAlert } = useAlert()

    const fetchRefData = useCallback(async () => {
        try {
            let [_supplier, _location] = await Promise.all([
                fetchSupplier(token), fetchLocation(token)
            ])
            setSuppliers(_supplier.filter(row => row.type === "order"))
            setLocations(_location.filter(row => row.type === "dealer" && row.status === "active"))
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, setAlert, setReload, reload, setToken])

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            let _data = await fetchStockOrderDetail(token)

            if (Array.isArray(_data)) {
                setData(_data.map(row => ({
                    ...row,
                    product: row.brand + " " + row.product,
                    action: <Box justifyContent='center' alignItems='center'>
                        <EditIcon iconcolor='blue' onClick={() => {
                            setOrderDetail({
                                so_detail_id: row.so_detail_id,
                                running: row.running,
                                s_id: row.s_id,
                                supplier: row.supplier,
                                l_id: row.l_id,
                                location: row.location,
                                item_description: row.brand + " " + row.product,
                                qty: row.qty
                            })
                            setShowModal(v => !v)
                        }} />
                    </Box>
                })))
                setLoading(false)
            }
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, setAlert, setReload, reload, setToken])

    const MemoTable = useMemo(() => {
        return <ManageTable
            defaultHeader={defaultHeader}
            columnSetting="stockOrderDetailList"
            data={data}
            filterKey={["running", "supplier", "location", "product", "model_no"]}
            loading={loading}
        />
    }, [data, loading])

    useEffect(() => {
        fetchData()
        fetchRefData()
    }, [fetchData, fetchRefData])

    return (
        <>
            <Breadcrumb items={breadCrumbItems} />

            {MemoTable}

            <EditModal
                showModal={showModal}
                setShowModal={setShowModal}
                orderDetail={orderDetail}
                setOrderDetail={setOrderDetail}
                setShowPopModal={setShowPopModal}
                setData={setData}
            />

            <PopModal
                showModal={showPopModal}
                setShowModal={setShowPopModal}
                suppliers={suppliers}
                locations={locations}
                setOrderDetail={setOrderDetail}
            />

        </>
    )
}

export default StockOrderDetailList