import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import Button from '../components/Button'
import Input from '../components/Input'
import ManageTable from '../components/ManageTable'
import Modal from '../components/Modal'
import { TableHeaderDataProps } from '../components/Table'
import TextButton from '../components/TextButton'
import { fetchDutyBill, fetchDutyDetail } from '../fetchers/fetcher'
import currencyFormatter from '../helpers/currencyFormatter'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import {DeleteIcon, EditIcon, SelectIcon} from '../icons/icon'
import { EClaimType, IDutyBill, IDutyDetail } from '../types/types'

const breadCrumbItems = [
    { name: "Duty Maintenance", navigate: "/duty" },
    { name: "Duty Bill Maintenance", active: true },
]

let defaultHeader: TableHeaderDataProps[] = [
    { key: "duty_id", name: "ID", width: "5%" },
    { key: "show_running", name: "Running", width: "12%" },
    { key: "date_time", name: "Date", width: "10%" },
    { key: "location", name: "Dealer" },
    { key: "total", name: "Total", align: "right", width:"5%" },
    { key: "notes", name: "Notes" },
    { key: "action", name: "Action", align: "center", width: "5%" },
]

const initialDutyBill: {
    duty_bill_id: null | number
    duty_id: null | number
    l_id: null | number
    location: string
    running: string
    total: string
    notes: string
} = {
    duty_bill_id: null,
    duty_id: null,
    l_id: null,
    location: "",
    running: "",
    total: "",
    notes: ""
}

const DutyBillList = () => {
    let history = useNavigate()

    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [dutyDetails, setDutyDetails] = useState<IDutyDetail[]>([])
    const [dutyBills, setDutyBills] = useState<IDutyBill[]>([])

    const [dutyBill, setDutyBill] = useState(initialDutyBill)
    
    const {token, setToken} = useLogin()
    const {setAlert} = useAlert()

    const inputRef = useRef<HTMLInputElement>(null)

    const handleDelete = useCallback((id: number) => {
        setAlert({
            show: true,
            message: "Delete Duty Bill # " + id + " ?",
            type: "question",
            cb: async () => {
                try {
                    let result = await axios.delete("/dutyBill/" + id)
        
                    if(result.status !== 200) {
                        setAlert({
                            show: true,
                            message: result.data.message,
                            type: "danger"
                        })
                    }
        
                    return setAlert({
                        show: true,
                        message: "Duty Bill Deleted Successfully !!!",
                        type: "success",
                        cb: () => {
                            setDutyBills(v => v.filter(row => row.duty_bill_id !== id))
                        }
                    })
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message
                    })
                }
            }
        })
    }, [setAlert])

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            let [_dutyDetail, _dutyBill] = await Promise.all([
                fetchDutyDetail(token), fetchDutyBill(token)
            ])

            setDutyDetails(_dutyDetail)
            setDutyBills(_dutyBill)

            setLoading(false)
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, setAlert, setToken, reload])

    const memoData = useMemo(() => {
        return dutyDetails
        .filter((v, i, s) => {
            return s.map(row => row.duty_id + "|" + row.l_id).indexOf(v.duty_id + "|" + v.l_id) === i
        }).sort((a, b) => {
            if(b.duty_id < a.duty_id) return -1
            if(b.duty_id > a.duty_id) return 1

            if(b.location.toLowerCase() > a.location.toLowerCase()) return -1
            if(b.location.toLowerCase() < a.location.toLowerCase()) return 1

            return 0
        }).map((row, i, s) => {
            let _bill = dutyBills.filter(el => el.duty_id === row.duty_id && el.l_id === row.l_id) as IDutyBill[]

            let _total = dutyDetails.reduce((total, el) => {
                if(el.duty_id === row.duty_id && el.l_id === row.l_id) {
                    if(el.claim === EClaimType.ALL) total += (el.qty * (el.transport + el.duty))
                    if(el.claim === EClaimType.DUTY_ONLY) total += (el.qty * el.duty)
                    if(el.claim === EClaimType.TRANSPORT_ONLY) total += (el.qty * el.transport)
                    return total
                }
                return total
            }, 0)

            return {
                ...row,
                show_running: <TextButton onClick={() => history("/duty/" + row.duty_id)}>{row.running}</TextButton>,
                total: currencyFormatter(_total),
                duty_bill_id: _bill.length === 1 ? _bill[0].duty_bill_id : null,
                notes: _bill.length === 1 ? _bill[0].notes : "",
                action: _bill.length === 1 
                ? <Box justifyContent='center' alignItems='center'>
                    <EditIcon iconcolor='blue' onClick={() => {
                        setDutyBill(v => ({
                            ...v,
                            duty_bill_id: _bill[0].duty_bill_id,
                            duty_id: row.duty_id,
                            l_id: row.l_id,
                            location: row.location,
                            running: row.running,
                            total: currencyFormatter(_total),
                            notes: _bill[0].notes
                        }))
                        setShowModal(v => !v)
                        setTimeout(() => {
                            if(inputRef.current) {
                                inputRef.current.focus()
                                inputRef.current.select()
                            }
                        }, 500)
                    }} /> 
                    <DeleteIcon iconcolor='red' onClick={() => handleDelete(_bill[0].duty_bill_id)} /> 
                </Box>
                : <Box justifyContent='center' alignItems='center'>
                    <SelectIcon iconcolor='green' onClick={() => {
                        setDutyBill(v => ({
                            ...v,
                            duty_bill_id: null,
                            duty_id: row.duty_id,
                            l_id: row.l_id,
                            location: row.location,
                            running: row.running,
                            total: currencyFormatter(_total),
                            notes: ""
                        }))
                        setShowModal(v => !v)
                        setTimeout(() => {
                            if(inputRef.current) {
                                inputRef.current.focus()
                                inputRef.current.select()
                            }
                        }, 500)
                    }} />
                </Box>
            }
        })
    }, [dutyBills, dutyDetails, handleDelete, history])


    const MemoTable = useMemo(() => {
        return <ManageTable 
            defaultHeader={defaultHeader}
            columnSetting="dutyBillList"
            data={memoData}
            filterKey={["running", "location", "notes"]}
            loading={loading}
            />
    }, [memoData, loading])

    const handleSubmit = () => {
        setAlert({
            show: true, 
            message: dutyBill.duty_bill_id ? "Edit Duty Bill" : "New Duty Bill",
            type: "question",
            cb: async () => {
                try {
                    if(!dutyBill.duty_bill_id) {
                        let insert = await axios.post("/dutyBill", {
                            duty_id: dutyBill.duty_id,
                            l_id: dutyBill.l_id,
                            notes: dutyBill.notes
                        })

                        if(insert.status !== 201) {
                            return setAlert({
                                show: true,
                                message: insert.data.message,
                                type: "danger"
                            })
                        }

                        return setAlert({
                            show: true,
                            message: "Duty Bill Created Successfully !!!",
                            type: "success",
                            cb: () => {
                                setDutyBills(v => ([...v, insert.data]))
                                setShowModal(false)
                            }
                        })
                    } else {
                        let update = await axios.patch("/dutyBill/" + dutyBill.duty_bill_id, {
                            notes: dutyBill.notes
                        })

                        if(update.status !== 200) {
                            return setAlert({
                                show: true,
                                message: update.data.message,
                                type: "danger"
                            })
                        }

                        return setAlert({
                            show: true,
                            message: "Duty Bill Updated Successfully !!!",
                            type: "success",
                            cb: () => {
                                setDutyBills(v => v.map(row => {
                                    if(row.duty_bill_id === dutyBill.duty_bill_id) {
                                        return {
                                            ...row,
                                            notes: update.data.notes
                                        }
                                    }
                                    return row
                                }))
                                setShowModal(false)
                            }
                        })
                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message
                    })
                }
            }
        })
    }

    const handleOnKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.which === 13) {
            return handleSubmit()
        }
        return
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb items={breadCrumbItems} />
            {MemoTable}
            <Modal
            title={dutyBill.duty_bill_id ? "Edit Duty Bill" : "New Duty Bill"}
            show={showModal}
            closeModal={() => setShowModal(false)}
            clickOutside
            width="20%"
            >
                <Box flexDirection='column' gap="1rem">
                    <Box alignItems='center'>
                        <Box width="8rem">Running</Box>
                        <Box width="2rem">:</Box>
                        <Box>{dutyBill.running}</Box>
                    </Box>
                    <Box alignItems='center'>
                        <Box width="8rem">Location</Box>
                        <Box width="2rem">:</Box>
                        <Box>{dutyBill.location}</Box>
                    </Box>
                    <Box alignItems='center'>
                        <Box width="8rem">Total</Box>
                        <Box width="2rem">:</Box>
                        <Box>{dutyBill.total}</Box>
                    </Box>
                    <Box alignItems='center'>
                        <Box width="8rem">Doc No.</Box>
                        <Box width="2rem">:</Box>
                        <Box>
                            <Input forwardedRef={inputRef} placeholder='Doc No.' value={dutyBill.notes} 
                            onKeyPress={handleOnKeypress}
                            onChange={e => {
                                setDutyBill(v => ({
                                    ...v,
                                    notes: e.target.value
                                }))
                            }} />
                        </Box>
                    </Box>
                    <Box justifyContent='center' alignItems='center'>
                        <Button color="green" onClick={handleSubmit}>{dutyBill.duty_bill_id ? "Update" : "Create"}</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default DutyBillList