import React from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface Props {
    children?: React.ReactNode
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const StyledDiv = styled.div<ThemePalleteProps>`
    cursor: pointer;
    text-decoration: underline;
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    color: ${props => props.themeSetting?.pallete.neutral.light};

    &:hover {
        color: ${props => props.pallete?.blue[400]};
    }

    &:active {
        opacity: 0.6;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themeSetting?.typography.media}rem);
    }
`


const TextButton = (props: Props) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledDiv themeSetting={theme} pallete={pallete} onClick={props.onClick}>{props.children}</StyledDiv>
    )
}

export default TextButton