import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import Button from '../components/Button'
import Checkbox from '../components/Checkbox'
import Input from '../components/Input'
import ManageTable from '../components/ManageTable'
import Modal from '../components/Modal'
import { Table, TableHeaderDataProps, TD, TH, TR } from '../components/Table'
import TextButton from '../components/TextButton'
import { fetchStockOrder, fetchStockOrderDetail } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import PdfDefinition from '../helpers/pdfDefinition'
import PdfHandler from '../helpers/pdfHandler'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { DeleteIcon, DownloadIcon, EditIcon } from '../icons/icon'
import { IStockOrder, IStockOrderDetail } from '../types/types'

const breadCrumbItems = [
    { name: "Stock Order Maintenance", active: true },
]

let defaultHeader: TableHeaderDataProps[] = [
    { key: "so_id", name: "ID", width: "4%", sort: "asc" },
    { key: "date_time", name: "Date", width: "6%", sort: "unsort" },
    { key: "show_running", name: "Running", width: "12%", sort: "unsort" },
    { key: "supplier", name: "Invoice Header", sort: "unsort" },
    { key: "location", name: "Permit Holder", sort: "unsort" },
    { key: "action", name: "Action", align: "center", width: "5%" },
    { key: "order", name: "Order", align: "center", width: "5%" }
]

const ChooseOrderModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowDetailModal: React.Dispatch<React.SetStateAction<boolean>>
    data: (IStockOrder & { checked: boolean })[]
    setExtractData: React.Dispatch<React.SetStateAction<(IStockOrder & { checked: boolean })[]>>
}) => {
    return <Modal
        title="Choose Order"
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
        width="50%"
    >
        <Box flexDirection='column' gap="1rem">
            <Table
                height="60vh"
                header={
                    <>
                        <TH width="13%">Date</TH>
                        <TH width="23%">Running</TH>
                        <TH>Supplier</TH>
                        <TH>Location</TH>
                        <TH width="4%"></TH>
                    </>
                }
                body={
                    <>
                        {
                            props.data.map((row, i) => {
                                return <TR key={row.so_id}
                                    onClick={() => {
                                        props.setExtractData(v => {
                                            return v.map((el, _, self) => {
                                                if (i === _) {
                                                    return {
                                                        ...el,
                                                        checked: !self[_].checked
                                                    }
                                                }
                                                return el
                                            })
                                        })
                                    }}
                                >
                                    <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                    <TD>{row.running}</TD>
                                    <TD>{row.supplier}</TD>
                                    <TD>{row.location}</TD>
                                    <TD align='center'>
                                        <Checkbox checked={row.checked} onChange={e => {
                                            props.setExtractData(v => {
                                                return v.map((el, _) => {
                                                    if (i === _) {
                                                        return {
                                                            ...el,
                                                            checked: e.target.checked
                                                        }
                                                    }
                                                    return el
                                                })
                                            })
                                        }} />
                                    </TD>
                                </TR>
                            })
                        }
                    </>
                }
            />
            <Box justifyContent='center'>
                <Button color='blue' onClick={() => {
                    props.setShowModal(false)
                    props.setShowDetailModal(true)
                }}>Confirm</Button>
            </Box>
        </Box>
    </Modal>
})

const ChooseDetailModal = React.memo((props: {
    showDetailModal: boolean
    setShowDetailModal: React.Dispatch<React.SetStateAction<boolean>>
    detail: (IStockOrderDetail & { checked: boolean })[]
    extractData:(IStockOrder & { checked: boolean })[]
    setDetail: React.Dispatch<React.SetStateAction<(IStockOrderDetail & { checked: boolean })[]>>
}) => {
    const [filter, setFilter] = useState("")

    const checkedDetailData = useMemo(() => {
        return props.detail.filter(row => props.extractData.filter(el => el.checked).map(el => el.so_id).indexOf(row.so_id) !== -1)
    }, [props.detail, props.extractData])

    const filteredDetailData = useMemo(() => {
        return checkedDetailData.filter(row => {
            return (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                row.location.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        })
    }, [checkedDetailData, filter])

    const isAllChecked = filteredDetailData.length === filteredDetailData.filter(row => row.checked).length

    const exportDetailData = useMemo(() => {
        return checkedDetailData.filter(row => row.checked).sort((a, b) => {
            if (b.so_id < a.so_id) return 1
            if (b.so_id > a.so_id) return -1

            if (b.supplier.toLowerCase() > a.supplier.toLowerCase()) return -1
            if (b.supplier.toLowerCase() < a.supplier.toLowerCase()) return 1

            if (b.location.toLowerCase() > a.location.toLowerCase()) return -1
            if (b.location.toLowerCase() < a.location.toLowerCase()) return 1

            return 0
        })
    }, [checkedDetailData])

    const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newData = [...props.detail]
        let mappedId = newData
            .filter(row => props.extractData.filter(el => el.checked).map(el => el.so_id).indexOf(row.so_id) !== -1)
            .filter(row => {
                return (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                    row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                    row.location.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            }).map(row => row.so_detail_id)

        for (let i = 0; i < newData.length; i++) {
            if (mappedId.indexOf(newData[i].so_detail_id) !== -1) {
                newData[i].checked = e.target.checked
            }
        }
        props.setDetail(newData)
    }

    return <Modal
        title="Extract Detail Data"
        show={props.showDetailModal}
        closeModal={() => props.setShowDetailModal(false)}
        clickOutside
        width="60%"
    >
        <Box flexDirection='column' gap="1rem">
            <Input width="30rem" placeholder='Filter product / supplier / dealer' value={filter} onChange={e => setFilter(e.target.value)} />
            <Table
                height="60vh"
                header={
                    <>
                        <TH width="5%" align='center'>#</TH>
                        <TH width="15%">Running</TH>
                        <TH>Supplier</TH>
                        <TH>Dealer</TH>
                        <TH>Description</TH>
                        <TH width="6%" align='center'>Qty</TH>
                        <TH width="3%">
                            <Checkbox checked={isAllChecked} onChange={handleCheckAll} />
                        </TH>
                    </>
                }
                body={
                    <>
                        {
                            filteredDetailData.map((row, i) => {
                                return <TR key={row.so_detail_id}
                                    onClick={() => {
                                        let newData = [...props.detail]
                                        let index = newData.map(row => row.so_detail_id).indexOf(row.so_detail_id)
                                        newData[index].checked = !newData[index].checked
                                        props.setDetail(newData)
                                    }}
                                >
                                    <TD align='center'>{i + 1}</TD>
                                    <TD>{row.running}</TD>
                                    <TD>{row.supplier}</TD>
                                    <TD>{row.location}</TD>
                                    <TD>{row.brand + " " + row.product}</TD>
                                    <TD align='center'>{row.qty}</TD>
                                    <TD align='center'>
                                        <Checkbox checked={row.checked} onChange={e => {
                                            let newData = [...props.detail]
                                            let index = newData.map(row => row.so_detail_id).indexOf(row.so_detail_id)
                                            newData[index].checked = e.target.checked
                                            props.setDetail(newData)
                                        }} />
                                    </TD>
                                </TR>
                            })
                        }
                    </>
                }
            />
            <Box justifyContent='center'>
                <Box gap="1rem">
                    <Button color="blue" onClick={() => {
                        let _doc = PdfDefinition.customStockOrderDetail(exportDetailData)
                        return new PdfHandler(_doc).view()
                    }}>View</Button>
                    <Button color="blue" onClick={() => {
                        let _doc = PdfDefinition.customStockOrderDetail(exportDetailData)
                        return new PdfHandler(_doc, "STOCK_ALLOCATION").download()
                    }}>Download</Button>
                </Box>
            </Box>
        </Box>
    </Modal>
})

const StockOrderList = () => {
    let history = useNavigate()
    const { token, setToken } = useLogin()
    const [data, setData] = useState<any[]>([])
    const [detail, setDetail] = useState<(IStockOrderDetail & { checked: boolean })[]>([])
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [extractData, setExtractData] = useState<(IStockOrder & { checked: boolean })[]>([])

    const [showDetailModal, setShowDetailModal] = useState(false)
    const [reload, setReload] = useState(false)

    const { setAlert } = useAlert()

    const handleDelete = useCallback((_data: IStockOrder) => {
        setAlert({
            show: true,
            message: "Delete Stock Order # " + _data.running + " ?",
            type: "question",
            cb: async () => {
                try {
                    let result = await axios.delete("/stockOrder/" + _data.so_id)
        
                    if(result.status !== 200) {
                        setAlert({
                            show: true,
                            message: result.data.message,
                            type: "danger"
                        })
                    }
        
                    return setAlert({
                        show: true,
                        message: "Stock Order Deleted Successfully !!!",
                        type: "success",
                        cb: () => {
                            setData(v => v.filter(row => row.so_id !== _data.so_id))
                        }
                    })
        
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        type: "danger",
                        message: err.message
                    })
                }
            }
        })
    }, [setAlert])

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            let [_data, _detail] = await Promise.all([
                fetchStockOrder(token), fetchStockOrderDetail(token)
            ])

            if (Array.isArray(_data)) {
                setData(_data.map(row => ({
                    ...row,
                    show_running: <Box justifyContent='space-between' alignItems='center'>
                        <TextButton
                            onClick={() => {
                                let currentDetail = _detail.filter(el => el.so_id === row.so_id)
                                let _doc = PdfDefinition.customStockOrder(row, currentDetail)
                                return new PdfHandler(_doc, row.running).view()
                            }}
                        >{row.running}</TextButton>
                        <DownloadIcon iconcolor="red" onClick={() => {
                            let currentDetail = _detail.filter(el => el.so_id === row.so_id)
                            let _doc = PdfDefinition.customStockOrder(row, currentDetail)
                            return new PdfHandler(_doc, row.running).download()
                        }} />
                    </Box>,
                    action: <Box alignItems='center' justifyContent='center'>
                        {
                            !row.invoice_id &&
                            <EditIcon iconcolor="blue" iconsize='h6' onClick={() => history("/stockOrder/" + row.so_id)} />
                        }
                        {
                            !row.invoice_id &&
                            <DeleteIcon iconcolor="red" iconsize='h6' onClick={() => handleDelete(row)} />
                        }
                    </Box>,
                    order: <Box alignItems='center' justifyContent='center'>
                        {
                            <EditIcon iconcolor={!row.invoice_id ? "blue" : "red"} iconsize='h6' onClick={() => history("/orderForm/" + row.so_id)} />
                        }
                    </Box>
                })))
            }

            if (Array.isArray(_detail)) {
                setDetail(_detail.map(row => ({ ...row, checked: false })))

                setExtractData(_data.splice(-20).sort((a, b) => b.so_id - a.so_id).map(row => ({ ...row, checked: false })))
            }

            setLoading(false)

        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, history, setAlert, setToken, reload, handleDelete])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb
                items={breadCrumbItems}
                button={
                    [
                        {
                            name: "New",
                            color: "blue",
                            action: "/stockOrder/new"
                        },
                        {
                            name: "Detail List",
                            color: "blue",
                            action: "/stockOrderDetail"
                        },
                        {
                            name: "Extract Data",
                            color: "blue",
                            action: () => setShowModal(true)
                        }
                    ]
                }
            />
            <ManageTable
                defaultHeader={defaultHeader}
                columnSetting="stockOrderList"
                data={data}
                filterKey={["running", "supplier", "location"]}
                loading={loading}
            />

            <ChooseOrderModal
                showModal={showModal}
                setShowModal={setShowModal}
                setShowDetailModal={setShowDetailModal}
                data={extractData}
                setExtractData={setExtractData}
            />

            <ChooseDetailModal 
            showDetailModal={showDetailModal}
            setShowDetailModal={setShowDetailModal}
            detail={detail}
            setDetail={setDetail}
            extractData={extractData}
            />
        </>
    )
}

export default StockOrderList