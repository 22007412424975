import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardHeader } from '../components/Card'
import Input from '../components/Input'
import LoadingContainer from '../components/LoadingContainer'
import Modal from '../components/Modal'
import PageContent from '../components/PageContent'
import Select from '../components/Select'
import StaticTableRow from '../components/StaticTableRow'
import { Table, TD, TH, TR } from '../components/Table'
import { fetchDutyById, fetchDutyDetailByField, fetchInvoiceByField, fetchStockOrderById, 
    fetchStockOrderDetailByField, fetchLocation, fetchStockOrder, fetchInvoice } from '../fetchers/fetcher'
import dateToYMD from '../helpers/dateToYMD'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import PdfDefinition from '../helpers/pdfDefinition'
import PdfHandler from '../helpers/pdfHandler'
import randomID from '../helpers/randomID'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { DeleteIcon, SelectIcon } from '../icons/icon'
import { EClaimType, IDuty, IDutyDetail, ILocation, IStockOrder } from '../types/types'

interface ITableData {
    uid: string,
    duty_detail_id: number | null,
    location: string,
    l_id: number,
    p_id: number,
    brand: string,
    product: string,
    cost: number,
    tax: number,
    qty: number,
    duty: number,
    transport: number,
    claim: string
}

const breadCrumbItems = [
    { name: "Duty Maintenance", navigate: "/duty" },
    { name: "Duty", active: true },
]

const StyledInfoBox = styled(Box)`
    gap: 3rem;

    @media (max-width: 768px) {
        gap: 1rem;
        flex-direction: column;
    }
`

const TransferModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    loading: boolean
    stockOrders: IStockOrder[]
}) => {
    let location = useLocation()

    const [filter, setFilter] = useState("")

    return <Modal
        title="Transfer from Stock Order"
        width="50%"
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
    >
        
        <Box flexDirection='column' gap="1rem">
            <Input width="20rem" placeholder='Filter table...' value={filter} onChange={e => setFilter(e.target.value)} />
            {
                props.loading
                    ? <LoadingContainer height="70vh" />
                    : (
                        <Table
                            height="70vh"
                            header={
                                <>
                                    <TH width="5%">ID</TH>
                                    <TH>Date</TH>
                                    <TH>Running</TH>
                                    <TH>Invoice Header</TH>
                                    <TH>Permit Holder</TH>
                                    <TH width="5%"></TH>
                                </>
                            }
                            body={
                                <>
                                    {
                                        props.stockOrders
                                        .filter(row => new Date(row.date_time).getFullYear() === new Date().getFullYear())
                                        .filter(row => {
                                            return row.location.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || 
                                                    row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 || 
                                                    row.running.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                                        }).map((row) => {
                                            return <TR key={"stockOrder" + row.so_id}>
                                                <TD>{row.so_id}</TD>
                                                <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                                <TD>{row.running}</TD>
                                                <TD>{row.supplier}</TD>
                                                <TD>{row.location}</TD>
                                                <TD align='center'>
                                                    <Box justifyContent='center' alignItems='center'>
                                                        <SelectIcon iconcolor='red' onClick={() => {
                                                            location.state = {
                                                                so_id: row.so_id
                                                            }
                                                            props.setShowModal(false)
                                                        }} />
                                                    </Box>
                                                </TD>
                                            </TR>
                                        })
                                    }
                                </>
                            }
                        />
                    )
            }
        </Box>
    </Modal>
})

const LocationSelect = React.memo((props: {
    locations: ILocation[]
    l_id: number
    uid: string
    setTableData: React.Dispatch<React.SetStateAction<ITableData[]>>
}) => {
    return <Select 
    mediaWidth='8rem'
    width='100%'
    value={props.l_id.toString()} 
    onChange={e => {
        props.setTableData(v => v.map((row) => {
            if(row.uid === props.uid) {
                return {
                    ...row,
                    l_id: Number(e.target.value)
                }
            }
            return row
        }))
    }}>
        {
            props.locations.map(row => {
                return <option key={"location" + row.l_id} value={row.l_id.toString()}>{row.location}</option>
            })
        }
    </Select>
})

const Duty = () => {
    let history = useNavigate()
    const { duty_id } = useParams()
    const location = useLocation()

    const [loading, setLoading] = useState(false)
    const [locations, setLocations] = useState<ILocation[]>([])
    const [reload, setReload] = useState(false)
    const [date_time, setDate_time] = useState(dateToYMD(new Date()))
    const [bnd_to_myr, setBnd_to_myr] = useState<string>("3")

    const [header, setHeader] = useState("New Duty")
    const [tableData, setTableData] = useState<ITableData[]>([])
    const [stockOrders, setStockOrders] = useState<IStockOrder[]>([])
    const [duty, setDuty] = useState<IDuty | null>(null)
    const [dutyDetail, setDutyDetail] = useState<IDutyDetail[]>([])

    const [showTransferModal, setShowTransferModal] = useState(false)

    const [filter, setFilter] = useState("")

    const { setAlert } = useAlert()
    const { token, setToken } = useLogin()

    const fetchLocationData = useCallback(async () => {
        setLoading(true)
        try {
            let _data = await fetchLocation(token)
            setLocations(_data.filter(row => row.status === "active" && row.type === "dealer"))
            setLoading(false)
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [reload, token, setToken, setReload, setAlert])

    const fetchTransferData = useCallback(async () =>{
        try {
            if(duty_id === "new") {
                let [_stockOrders, _invoices] = await Promise.all([
                    fetchStockOrder(token), fetchInvoice(token)
                ])
    
                let uncompleteInvoiceSo_id = _invoices.filter(row => !row.duty_id).map(row => row.so_id)
    
                setStockOrders(_stockOrders.filter(row => uncompleteInvoiceSo_id.indexOf(row.so_id) !== -1).sort((a, b) => b.so_id - a.so_id))
            }
            return
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [reload, setToken, setReload, setAlert, token, duty_id])

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            if (duty_id === "new") {
                if (location.state && location.state.so_id) {

                    let [_stockOrder, _stockOrderDetail, _invoice] = await Promise.all([
                        fetchStockOrderById(token, location.state.so_id),
                        fetchStockOrderDetailByField(token, { so_id: location.state.so_id }),
                        fetchInvoiceByField(token, { so_id: location.state.so_id })
                    ])


                    setHeader("New Duty From Stock Order " + _stockOrder.running)
                    setBnd_to_myr(_invoice[0].bnd_to_myr.toString())

                    let newTableData: ITableData[] = []

                    _stockOrderDetail.forEach(row => {
                        let tax = row.tax / 100;
                        let duty = row.cost / (_invoice[0].bnd_to_myr as number) * 0.5 * tax;
                        let floor = Math.floor(duty);
                        let fraction = duty - floor;

                        if (fraction > 0.5) {
                            duty = floor + 1;
                        } else if (fraction <= 0.5) {
                            duty = floor + 0.5;
                        }
                        let newObject = {
                            uid: randomID(),
                            duty_detail_id: null,
                            location: row.location,
                            l_id: row.l_id,
                            p_id: row.p_id,
                            brand: row.brand,
                            product: row.product,
                            cost: row.cost,
                            tax: row.tax,
                            qty: row.qty,
                            duty: duty,
                            transport: 3,
                            claim: EClaimType.ALL
                        }

                        newTableData = [...newTableData, newObject]
                    })

                    newTableData.sort((a, b) => {
                        if (a.l_id > b.l_id) return -1
                        if (a.l_id < b.l_id) return 1
                        return 0
                    })

                    setTableData(newTableData)
                    return
                }

                setHeader("New Duty")
                setDate_time(dateToYMD(new Date()))
                setBnd_to_myr("3")
                setTableData([])
                return
            } else {
                let [_duty, _dutyDetail] = await Promise.all([
                    fetchDutyById(token, Number(duty_id)),
                    fetchDutyDetailByField(token, { duty_id: Number(duty_id) })
                ])

                setHeader("Edit Duty " + _duty.running)
                setBnd_to_myr(_duty.bnd_to_myr.toString())
                setDate_time(dateToYMD(new Date(_duty.date_time)))
     
                let newDetail = _dutyDetail.map(row => {
                    return {
                        uid: randomID(),
                        duty_detail_id: row.duty_detail_id,
                        location: row.location,
                        l_id: row.l_id,
                        p_id: row.p_id,
                        brand: row.brand,
                        product: row.product,
                        cost: row.cost,
                        tax: row.tax,
                        qty: row.qty,
                        duty: row.duty,
                        transport: row.transport,
                        claim: row.claim
                    }
                })
                setDuty(_duty)
                setDutyDetail(_dutyDetail)
                setTableData(newDetail)
            }
        } catch (err: any) {
            console.log(err.message)
            setLoading(false)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }

    }, [duty_id, location.state, token, setReload, reload, setToken, setAlert])

    const _button = useMemo(() => {
        if (duty_id === "new") {
            return [
                {
                    name: "Transfer From Stock Order",
                    color: "red",
                    action: () => setShowTransferModal(true)
                }
            ] as BreadCrumbButtonProps[]
        } else {
            return [
                {
                    name: "Download",
                    color: "blue",
                    action: async () => {
                        let _doc = await PdfDefinition.aplusCustomDuty(duty!, dutyDetail)
                        return new PdfHandler(_doc, duty!.running).download()
                    }
                },
                {
                    name: "View",
                    color: "blue",
                    action: async () => {
                        let _doc = await PdfDefinition.aplusCustomDuty(duty!, dutyDetail)
                        return new PdfHandler(_doc, duty!.running).view()
                    }
                },
                {
                    name: "New",
                    color: "green",
                    action: () => history("/duty/new")
                }
            ] as BreadCrumbButtonProps[]
        }
    }, [duty_id, history, duty, dutyDetail])

    const calculateDuty = (cost: number, tax: number) => {
        let duty = cost / (bnd_to_myr as unknown as number) * 0.5 * (tax / 100);
        let floor = Math.floor(duty);
        let fraction = duty - floor;

        if (fraction > 0.5) {
            duty = floor + 1;
        } else if (fraction <= 0.5) {
            duty = floor + 0.5;
        }

        return duty
    }

    const handleDelete = useCallback((_data: ITableData) => {
        setAlert({
            show: true,
            message: <Box flexDirection='column' gap="1rem" alignItems='flex-start' width="100%">
                <Box>Delete Item</Box>
                <Box width="100%">
                    <Box width="6rem">Item :</Box>
                    <Box>{_data.brand + " " + _data.product}</Box>
                </Box>
            </Box>,
            type: "danger",
            cb: async () => {
                try {
                    if (_data.duty_detail_id) {
                        let result = await axios.delete("/dutyDetail/" + _data.duty_detail_id)

                        if (result.status !== 200) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }
                    }

                    setTableData(v => v.filter(row => row.uid !== _data.uid))

                    setTimeout(() => {
                        setAlert({
                            show: true,
                            message: _data.brand + " " + _data.product + " Deleted Successfully !!!",
                            type: "success"
                        })
                    }, 500)
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }, [setAlert])

    const handleSubmit = () => {
        let error: string[] = []

        for (let i = 0; i < tableData.length; i++) {
            if (isNaN(Number(tableData[i].qty)) || !tableData[i].qty || !tableData[i].qty.toString().trim()) error = [...error, "Quantity row " + (i + 1)]
            if (isNaN(Number(tableData[i].transport)) || !tableData[i].transport || !tableData[i].transport.toString().trim()) error = [...error, "Transport row " + (i + 1)]
            if (isNaN(Number(tableData[i].duty)) || !tableData[i].duty || !tableData[i].duty.toString().trim()) error = [...error, "Duty row " + (i + 1)]
            if (!tableData[i].claim || !tableData[i].claim.trim()) error = [...error, "Claim row " + (i + 1)]
        }

        if (!date_time) error = [...error, "Date"]
        if (!bnd_to_myr || !bnd_to_myr.trim()) error = [...error, "Bnd To Myr"]

        if (error.length > 0) {
            return setAlert({
                show: true,
                type: "danger",
                message: <Box flexDirection='column' gap="0.5rem" alignItems='flex-start' width="100%">
                    <Box>Error :-</Box>
                    {
                        error.map((element, _) => {
                            return <Box width="100%" key={"error" + _}>
                                {element}
                            </Box>
                        })
                    }
                </Box>
            })
        }

        setAlert({
            show: true,
            message: duty_id === "new" ? "Create New Duty ?" : "Update Duty ?",
            type: "question",
            cb: async () => {
                if (duty_id === "new") {
                    let info = await axios.post("/duty", {
                        so_id: location.state.so_id,
                        date_time: date_time,
                        bnd_to_myr: bnd_to_myr
                    })

                    if(info.status !== 201) {
                        return setAlert({
                            show: true,
                            message: info.data.message,
                            type: "danger"
                        })
                    }

                    let detail = await axios.post("/dutyDetail/multiple", {
                        duty_id: info.data.duty_id,
                        p_id: tableData.map(row => row.p_id),
                        l_id: tableData.map(row => row.l_id),
                        cost: tableData.map(row => row.cost),
                        qty: tableData.map(row => row.qty),
                        duty: tableData.map(row => row.duty),
                        transport: tableData.map(row => row.transport),
                        claim: tableData.map(row => row.claim)
                    })

                    if (detail.status !== 201) {
                        return setAlert({
                            show: true,
                            message: detail.data.message,
                            type: "danger"
                        })
                    }

                    return setAlert({
                        show: true,
                        message: "Duty Created Successfully !!!",
                        type: "success",
                        cb: () => {
                            history("/duty/" + info.data.duty_id)
                        }
                    })
                } else {
                    let updateInfo = await axios.patch("/duty/" + duty_id, {
                        date_time: date_time,
                        bnd_to_myr: bnd_to_myr
                    })

                    if (updateInfo.status !== 200) {
                        return setAlert({
                            show: true,
                            message: updateInfo.data.message,
                            type: "danger"
                        })
                    }

            
                    let newDetail = tableData.filter(row => !row.duty_detail_id)
                    let oldDetail = tableData.filter(row => !!row.duty_detail_id)

                    if (newDetail.length > 0) {
                        let insertDetail = await axios.post("/invoiceDetail/multiple", {
                            duty_id: duty_id,
                            p_id: newDetail.map(row => row.p_id),
                            l_id: newDetail.map(row => row.l_id),
                            cost: newDetail.map(row => row.cost),
                            qty: newDetail.map(row => row.qty),
                            duty: newDetail.map(row => row.duty),
                            transport: newDetail.map(row => row.transport),
                            claim: newDetail.map(row => row.claim)
                        })

                        if (insertDetail.status !== 201) {
                            return setAlert({
                                show: true,
                                message: insertDetail.data.message,
                                type: "danger"
                            })
                        }
                    }

                    let updateDetail = await axios.patch("/dutyDetail/multiple", {
                        duty_detail_id: oldDetail.map(row => row.duty_detail_id),
                        p_id: oldDetail.map(row => row.p_id),
                        l_id: oldDetail.map(row => row.l_id),
                        qty: oldDetail.map(row => row.qty),
                        duty: oldDetail.map(row => row.duty),
                        transport: oldDetail.map(row => row.transport),
                        claim: oldDetail.map(row => row.claim)
                    })

                    if (updateDetail.status !== 200) {
                        return setAlert({
                            show: true,
                            message: updateDetail.data.message,
                            type: "danger"
                        })
                    }

                    return setAlert({
                        show: true,
                        message: "Duty Updated Successfully !!!",
                        type: "success",
                        cb: () => fetchData()
                    })

               
                }
            } 
        })
    }

    useEffect(() => {
        fetchData()
        fetchLocationData()
        fetchTransferData()
    }, [fetchData, fetchLocationData, fetchTransferData])

    return (
        <>
            <Breadcrumb
                items={breadCrumbItems}
                button={_button}
            />

            <PageContent>
                <Card width="100%">
                    <CardHeader border>{header}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="3rem">
                            <StyledInfoBox>
                                <Input label="Date" width="20rem" mediaWidth='100%' type="date" value={date_time} onChange={e => setDate_time(e.target.value)} />
                                <Input label="BND to MYR" width="20rem" mediaWidth='100%' placeholder='Currency rate...' value={bnd_to_myr} onChange={e => setBnd_to_myr(e.target.value)} />
                            </StyledInfoBox>
                            <Box flexDirection='column' gap="1rem">
                                <Box justifyContent='space-between'>
                                    <Input value={filter} onChange={e => setFilter(e.target.value)} placeholder="Filter" width='30rem' mediaWidth='85%' />
                                    <Button color="green" onClick={handleSubmit}>{duty_id === "new" ? "Submit" : "Update"}</Button>
                                </Box>
                                <Table
                                    header={
                                        <>
                                            <TH width="3%" align="center">#</TH>
                                            <TH width="10%">Location</TH>
                                            <TH>Item Description</TH>
                                            <TH width="5%" align="center">Cost(MYR)</TH>
                                            <TH width="5%" align="center">Cost(BND)</TH>
                                            <TH width="3%" align="center">Tax%</TH>
                                            <TH width="5%" align="center">Qty</TH>
                                            <TH width="5%" align="center">Transport</TH>
                                            <TH width="4%" align="center">R.Duty</TH>
                                            <TH width="5%" align="center">Duty</TH>
                                            <TH width="8%" align="center">Claim</TH>
                                            <TH width="6%" align="right">Total</TH>
                                            <TH width="3%" align="center"></TH>
                                        </>
                                    }
                                    body={
                                        <>
                                            {
                                                tableData
                                                .filter(row => (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 || 
                                                row.location.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                                                .map((row, i) => {
                                                    return <TR key={row.uid}>
                                                        <TD align="center">{i + 1}</TD>
                                                        <TD>
                                                            <LocationSelect 
                                                            uid={row.uid}
                                                            setTableData={setTableData}
                                                            locations={locations}
                                                            l_id={row.l_id}
                                                            />
                                                        </TD>
                                                        <TD>{row.brand + " " + row.product}</TD>
                                                        <TD align="center">{row.cost}</TD>
                                                        <TD align="center">{(row.cost / (bnd_to_myr as unknown as number)).toFixed(2)}</TD>
                                                        <TD align="center">{row.tax}</TD>
                                                        <TD align="center">
                                                            <Input
                                                                align='center'
                                                                width='100%'
                                                                mediaWidth='4rem'
                                                                value={row.qty as unknown as string}
                                                                onChange={e => {
                                                                    let newTableData = [...tableData]
                                                                    let value = e.target.value as unknown as number
                                                                    let index = newTableData.map(el => el.uid).indexOf(row.uid)
                                                                    newTableData[index].qty = value
                                                                    setTableData(newTableData)
                                                                }} />
                                                        </TD>
                                                        <TD align="center">
                                                            <Input
                                                                align='center'
                                                                width='100%'
                                                                mediaWidth='4rem'
                                                                value={row.transport as unknown as string}
                                                                onChange={e => {
                                                                    let newTableData = [...tableData]
                                                                    let value = e.target.value as unknown as number
                                                                    let index = newTableData.map(el => el.uid).indexOf(row.uid)
                                                                    newTableData[index].transport = value
                                                                    setTableData(newTableData)
                                                                }} />
                                                        </TD>
                                                        <TD align="center">
                                                            {calculateDuty(row.cost, row.tax)}
                                                        </TD>
                                                        <TD align='center'>
                                                            <Input
                                                                align='center'
                                                                width='100%'
                                                                mediaWidth='4rem'
                                                                value={row.duty as unknown as string}
                                                                onChange={e => {
                                                                    let newTableData = [...tableData]
                                                                    let value = e.target.value as unknown as number
                                                                    let index = newTableData.map(el => el.uid).indexOf(row.uid)
                                                                    newTableData[index].duty = value
                                                                    setTableData(newTableData)
                                                                }} /> 
                                                        </TD>
                                                        <TD>
                                                            <Select 
                                                            width='100%'
                                                            mediaWidth='8rem'
                                                            value={row.claim}
                                                            onChange={e => {
                                                                let newTableData = [...tableData]
                                                                let index = newTableData.map(el => el.uid).indexOf(row.uid)
                                                                newTableData[index].claim = e.target.value
                                                                setTableData(newTableData)
                                                            }}
                                                            >
                                                                {
                                                                    Object.values(EClaimType).map(element => {
                                                                        return <option key={element} value={element}>{element}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                        </TD>
                                                        <TD align="right">
                                                        {
                                                            row.qty * (row.claim === EClaimType.ALL || row.claim === EClaimType.DUTY_ONLY  ? row.duty : 0) +
                                                            row.qty * (row.claim === EClaimType.ALL || row.claim === EClaimType.TRANSPORT_ONLY ? row.transport : 0)
                                                        }
                                                        </TD>
                                                        <TD align="center">
                                                            <Box justifyContent='center' alignItems='center'>
                                                                <DeleteIcon iconcolor='red' onClick={() => handleDelete(row)} />
                                                            </Box>
                                                        </TD>
                                                    </TR>
                                                })
                                            }
                                            <StaticTableRow row={(tableData.length === 0 ? 16 : 15) - tableData.length} column={13} />
                                                {
                                                    tableData.length > 0 &&
                                                    <TR>
                                                        <TD colSpan={6} align="right">Total Quantity</TD>
                                                        <TD align="center">{tableData.reduce((total, row) => total + Number(row.qty), 0)}</TD>
                                                        <TD colSpan={4} align="right">Total Claim</TD>
                                                        <TD align='right'>
                                                            {tableData.reduce((total, row) => {
                                                                total += row.qty * (row.claim === EClaimType.ALL || row.claim === EClaimType.DUTY_ONLY ? row.duty : 0) +
                                                                        row.qty * (row.claim === EClaimType.ALL || row.claim === EClaimType.TRANSPORT_ONLY ? row.transport : 0)
                                                                return total
                                                            }, 0).toFixed(2)}
                                                        </TD>
                                                        <TD align="center"></TD>
                                                    </TR>
                                                }
                                        </>
                                    }
                                />
                            </Box>
                        </Box>
                    </CardBody>
                </Card>
            </PageContent>
            <TransferModal 
                showModal={showTransferModal}
                setShowModal={setShowTransferModal}
                loading={loading}
                stockOrders={stockOrders}
            />
        </>
    )
}

export default Duty