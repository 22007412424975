const randomID = (len: number = 18) => {
    let ID = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for ( var i = 0; i < len; i++ ) {
        ID += characters.charAt(Math.floor(Math.random() * 62));
    }
    return ID;
}

export default randomID
