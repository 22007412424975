import React, { useEffect, useRef, useState } from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

// types
interface TabsProps {
    tabs?: {
        tab: string
        content: React.ReactNode
    }[]
    minHeight?: string
    maxHeight?: string
}

// styled
const StyledContainer = styled.div<ThemePalleteProps>`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

`

const StyledTabsContainer = styled.div<ThemePalleteProps>`
    display: flex;
    position: relative;
    box-sizing: border-box;
    border-bottom: 0.1rem solid ${props => props.pallete?.blue[200]};
`

const StyledTab = styled.div<ThemePalleteProps>`
    cursor: pointer;
    font-weight: 600;
    transition: color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    padding: calc(${props => props.themeSetting?.spacing.sm}rem / 3) ${props => props.themeSetting?.spacing.lg}rem;
    box-sizing: border-box;
    /* text-transform: uppercase; */

    &.active {
    }
`

const StyledTabUnderline = styled.div`
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    border: 0.1rem solid lightblue;
    border-radius: 0.1rem;
    transition: left 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
`

const StyledTabContentContainer = styled.div<TabsProps>`
    box-sizing: border-box;
    min-height: ${props => props.minHeight || "auto"};
    max-height: ${props => props.maxHeight || "auto"};
    overflow-y: scroll;
`

const StyledTabContent = styled.div`
    padding: 1rem 0;
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: visibility 1000ms cubic-bezier(0.075, 0.82, 0.165, 1), 
                    opacity 1000ms cubic-bezier(0.075, 0.82, 0.165, 1),
                    display 1000ms cubic-bezier(0.075, 0.82, 0.165, 1);

    &.show {
        display: block;
        visibility: visible;
        opacity: 1;
    }
`


const Tabs = (props: TabsProps) => {
    const [currentTab, setCurrentTab] = useState<number>(0)
    const [underlineLeftWidth, setUnderlineLeftWidth] = useState({
        left: 0,
        width: 0
    })

    const {theme, pallete} = useThemeSetting()

    const tabsRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        if(tabsRef.current) {
            let allTab = tabsRef.current.querySelectorAll(".tab")

            if(allTab) {
                let firstTab = allTab[0]
                let _currentTab = allTab[currentTab]

                setUnderlineLeftWidth(v => ({
                    ...v,
                    left: _currentTab.getBoundingClientRect().left - firstTab.getBoundingClientRect().left,
                    width: _currentTab.getBoundingClientRect().width
                }))
            }
        }
    }, [currentTab])

    return (
        <StyledContainer
        className="tabsAndContentContainer"
        >
            <StyledTabsContainer className='tabsContainer' ref={tabsRef}>
              {
                    props.tabs && Array.isArray(props.tabs) && props.tabs.map((row, index) => {
                        return <StyledTab 
                        themeSetting={theme}
                        pallete={pallete}
                        className={"tab tab_" + index + (currentTab === index ? " active" : "")}
                        key={row.tab + index} 
                        onClick={() => setCurrentTab(index)}>
                            {row.tab}
                        </StyledTab>
                    })
                }
                <StyledTabUnderline 
                className="tabUnderline"
                style={{
                    left: underlineLeftWidth.left + "px",
                    width: underlineLeftWidth.width + "px"
                }}
                />
            </StyledTabsContainer>
            <StyledTabContentContainer
            className="tabsContentContainer"
            minHeight={props.minHeight}
            maxHeight={props.maxHeight}
            >
                {
                    props.tabs && Array.isArray(props.tabs) && props.tabs.map((row, index) => {
                        return <StyledTabContent 
                        key={"tabContent_" + index} 
                        className={"tabContent_" + index + (currentTab === index ? " show" : "")}
                        >
                        {row.content}
                         </StyledTabContent>
                    })
                }
            </StyledTabContentContainer>
        </StyledContainer>
    )
}

export default Tabs