import { IInvoice, IInvoiceDetail } from "../types/types"
import PdfDefinition from "./pdfDefinition"

const getInvoiceDoc = async (_data: IInvoice, _detail: IInvoiceDetail[]) => {
    switch(_data.s_id) {
        case 4:
            return await PdfDefinition.uniqbeCustomInvoice(_data, _detail)
        case 28:
            return PdfDefinition.chinaElectronicInvoice(_data, _detail)
        case 8:
            return await PdfDefinition.srklCustomInvoice(_data, _detail)
        case 5:
            return await PdfDefinition.primenetCustomInvoice(_data, _detail)
        case 42:
            return await PdfDefinition.aplusMalaysiaCustomInvoice(_data, _detail)
        default:
            return await PdfDefinition.aplusCustomInvoice(_data, _detail)
    }
}

export default getInvoiceDoc