import React from 'react'
import styled from "styled-components"
import { ThemePalleteProps } from '../types/types'
import { AiOutlinePlus, AiOutlineSearch, AiOutlineMinus } from "react-icons/ai"
import useThemeSetting from '../hooks/useThemeSetting'

interface InputProps {
    type?: "text" | "number" | "date" | "search" | "password"
    label?: string
    placeholder?: string
    value?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    width?: string
    disableInput?: boolean
    iconType?: "search" | "add" | "minus"
    direction?: "column" | "row"
    labelWidth?: number | string
    onClick?: React.MouseEventHandler<HTMLDivElement>
    forwardedRef?: React.RefObject<HTMLInputElement>
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
    readOnly?: boolean
    align?: "left" | "center" | "right"
    className?: string
    mediaWidth?: string
}

const StyledInputContainer = styled.div<ThemePalleteProps & {direction?: "column" | "row", width?: string, mediaWidth?: string }>`
    display: flex;
    gap: 0.3rem;
    flex-direction: ${props => props.direction ? props.direction : "column"};
    width: ${props => props.width ? props.width : null};
    align-items: ${props => (
        !props.direction
        ? "flex-start"
        : props.direction === "row" ? "center" : "flex-start" 
    )};

    @media (max-width: 768px) {
        gap: 0.2rem;
        width: ${props => props.mediaWidth ? props.mediaWidth : null};
	}

`

const StyledInputLabel = styled.label<ThemePalleteProps & {labelWidth?: string | number}>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    font-weight: 500;
    margin-left: 0.3rem;
    user-select: none;

    width: ${props => props.labelWidth ? (typeof props.labelWidth === "number" ? `${props.labelWidth}rem` : props.labelWidth) : null};

    @media (max-width: 768px) {
        gap: 0.2rem;
        font-size: ${props => props.themeSetting?.typography.media}rem;
	}
`

const iconContainerWidth = "2.8rem"

const StyledInput = styled.input<ThemePalleteProps & InputProps & { mediaWidth?: string }>`
    box-sizing: border-box;
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    border: none;
    outline: none;
    line-height: 2;
    padding: 0 0.6rem;
    user-select: none;
    text-align: ${props => props.align ? props.align : "left"};

    box-shadow: inset 0 0.1rem 0.2rem rgba(0,0,0,.39), 
                        0 -0.1rem 0.1rem ${props => props.themeSetting?.pallete.mode === "dark" ? "#434957" : "#fff"}, 
                        0 0.1rem 0 ${props => props.themeSetting?.pallete.mode === "dark" ? "#434957" : "#fff"};

    background-color: #d0d1d5;
    color: #040509;

    width: ${props => (
        props.width 
        ? (props.iconType ? `calc(${props.width} - ${iconContainerWidth})` : props.width)
        : "auto"
    )};

    ${props => props.disableInput ? `pointer-events: none;` : null}
    ${props => !props.iconType ? `border-radius: 0.3rem;` : `border-top-left-radius: 0.3rem; border-bottom-left-radius: 0.3rem;`}


    ::placeholder {
        color: ${props => props.themeSetting?.pallete.neutral.main};
        opacity: 1;
    }

    :-ms-input-placeholder {
        color: ${props => props.themeSetting?.pallete.neutral.main};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        color: ${props => props.themeSetting?.pallete.neutral.main};
        opacity: 1;
    }

    :focus {
        background-color: #e1e2fe;
    }

    &[type=date],
    &[type=week],
    &[type=datetime-local],
    &[type=time],
    &[type=month] {
        font-size: ${props => props.themeSetting?.typography.fontSize}rem;
        padding: 0 0.6rem;
        line-height: 1.7;

        @media (max-width: 768px) {
            font-size: ${props => props.themeSetting?.typography.media}rem;
            padding: 0.1rem 0.5rem;
            width: ${props => props.mediaWidth ? props.mediaWidth : null};
        }
    }

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        padding: 0.1rem 0.5rem;
        width: ${props => props.mediaWidth ? props.mediaWidth : null};
	}

`

const StyledInputIconGroup = styled.div<{ width?: string, mediaWidth?: string }>`
    display: flex;
    border-radius: 0.3rem;
    width: ${props => props.width ? props.width : null};

    @media (max-width: 768px) {
        width: ${props => props.mediaWidth ? props.mediaWidth : null};
    }
`

const StyledIconContainer = styled.div<ThemePalleteProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-left: none;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    width: ${iconContainerWidth};
    
    background-color: #a3a3a3;
`

const PlusIcon = styled(AiOutlinePlus)<ThemePalleteProps>`
    color: #141414;
    font-size: ${props => props.themesetting?.icon.size.sm}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.media}rem;
	}
`
const SearchIcon = styled(AiOutlineSearch)<ThemePalleteProps>`
    color: #141414;
    font-size: ${props => props.themesetting?.icon.size.sm}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.media}rem;
	}
`

const MinusIcon = styled(AiOutlineMinus)<ThemePalleteProps>`
    color: #141414;
    font-size: ${props => props.themesetting?.icon.size.sm}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themesetting?.typography.media}rem;
	}
`

const Input = (props: InputProps) => {
    const {theme, pallete} = useThemeSetting()

    const switchIcon = (iconType?: "add" | "search" | "minus") => {
        switch(iconType) {
            case "add":
                return <PlusIcon themesetting={theme} />
            case "search":
                return <SearchIcon themesetting={theme} />
            case "minus":
                return <MinusIcon themesetting={theme} />
        }
    }

    return (
        <StyledInputContainer themeSetting={theme} direction={props.direction} width={props.width} mediaWidth={props.mediaWidth} className={props.className}>
            {props.label && <StyledInputLabel themeSetting={theme} labelWidth={props.labelWidth}>{props.label}</StyledInputLabel>}
            <StyledInputIconGroup width={props.width} mediaWidth={props.mediaWidth} >
                <StyledInput 
                themeSetting={theme} 
                pallete={pallete} 
                width={props.width} 
                disableInput={props.disableInput} 
                iconType={props.iconType}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                type={props.type ? props.type : "text"}
                ref={props.forwardedRef}
                onKeyPress={props.onKeyPress}
                readOnly={props.readOnly}
                align={props.align}
                mediaWidth={props.mediaWidth} 
                />
                {
                    props.iconType &&
                    <StyledIconContainer themeSetting={theme} pallete={pallete} onClick={props.onClick}>
                        {switchIcon(props.iconType)}
                    </StyledIconContainer>
                }
            </StyledInputIconGroup>
        </StyledInputContainer>
    )
}

export default Input