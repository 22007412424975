import React from 'react'
import styled from 'styled-components'
import useThemeSetting from '../hooks/useThemeSetting';
import { ThemePalleteProps } from '../types/types';

interface Props {
    color?: "blue" | "red" | "green"
    children?: React.ReactNode
}

const switchColor = (props: ThemePalleteProps, color?: Props["color"]) => {
    switch(color) {
        case "blue":
            return props.themeSetting?.pallete.primary.main;
        case "red":
            return props.themeSetting?.pallete.tertiery.main;
        case "green":
            return props.themeSetting?.pallete.secondary.main;
        default:
            return null;
    }
}

const StyledColor = styled.div<ThemePalleteProps & Props>`
    color: ${props => switchColor(props, props.color)};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem; 
    }
`

const Color = (props: Props) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledColor themeSetting={theme} pallete={pallete} color={props.color}>{props.children}</StyledColor>
    )
}

export default Color