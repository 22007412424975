export type ThemeMode = "dark" | "light"

export type ThemeType = {
    pallete: {
        mode: ThemeMode
        primary: {
            main: string
        }
        secondary: {
            main: string
        }
        tertiery: {
            main: string
        }
        neutral: {
            dark: string
            main: string
            light: string
        },
        background: {
            default: string
            light: string
        },
        font: {
            dark: string
            main: string
            light: string
        }
    },
    typography: {
        fontFamily: string
        fontSize: number
        media: number
        h1: {
            fontFamily: string
            fontSize: number
        }
        h2: {
            fontFamily: string
            fontSize: number
        }
        h3: {
            fontFamily: string
            fontSize: number
        }
        h4: {
            fontFamily: string
            fontSize: number
        }
        h5: {
            fontFamily: string
            fontSize: number
        }
        h6: {
            fontFamily: string
            fontSize: number
        }
    },
    spacing: {
        sm: number
        md: number
        lg: number
        xl: number
    },
    icon: {
        size: {
            sm: number
            md: number
            lg: number
            xl: number
        }
    }
}

export interface IColorPalette {
    grey: {
        100: string
        200: string
        300: string
        400: string
        500: string
        600: string
        700: string
        800: string
        900: string
    },
    black: {
        100: string
        200: string
        300: string
        400: string
        500: string
        600: string
        700: string
        800: string
        900: string
    },
    green: {
        100: string
        200: string
        300: string
        400: string
        500: string
        600: string
        700: string
        800: string
        900: string
    },
    red: {
        100: string
        200: string
        300: string
        400: string
        500: string
        600: string
        700: string
        800: string
        900: string
    },
    blue: {
        100: string
        200: string
        300: string
        400: string
        500: string
        600: string
        700: string
        800: string
        900: string
    },
}

export const colorPalette = (mode: ThemeMode): IColorPalette => ({
    ...(mode === "dark"
    ? {
        grey: {
                  100: "#e0e0e0",
                  200: "#c2c2c2",
                  300: "#a3a3a3",
                  400: "#858585",
                  500: "#666666",
                  600: "#525252",
                  700: "#3d3d3d",
                  800: "#292929",
                  900: "#141414"
        },
        black: {
                  100: "#d0d1d5",
                  200: "#a1a4ab",
                  300: "#727681",
                  400: "#434957",
                  500: "#141b2d",
                  600: "#101624",
                  700: "#0c101b",
                  800: "#080b12",
                  900: "#040509"
        },
        green: {
                  100: "#dbf5ee",
                  200: "#b7ebde",
                  300: "#94e2cd",
                  400: "#70d8bd",
                  500: "#4cceac",
                  600: "#3da58a",
                  700: "#2e7c67",
                  800: "#1e5245",
                  900: "#0f2922"
        },
        red: {
                  100: "#f8dcdb",
                  200: "#f1b9b7",
                  300: "#e99592",
                  400: "#e2726e",
                  500: "#db4f4a",
                  600: "#af3f3b",
                  700: "#832f2c",
                  800: "#58201e",
                  900: "#2c100f"
        },
        blue: {
                  100: "#e1e2fe",
                  200: "#c3c6fd",
                  300: "#a4a9fc",
                  400: "#868dfb",
                  500: "#6870fa",
                  600: "#535ac8",
                  700: "#3e4396",
                  800: "#2a2d64",
                  900: "#151632"
        }
    } : {
        grey: {
                  100: "#141414",
                  200: "#292929",
                  300: "#3d3d3d",
                  400: "#525252",
                  500: "#666666",
                  600: "#858585",
                  700: "#a3a3a3",
                  800: "#c2c2c2",
                  900: "#e0e0e0"
        },
        black: {
                  100: "#040509",
                  200: "#080b12",
                  300: "#0c101b",
                  400: "#101624",
                  500: "#141b2d",
                  600: "#434957",
                  700: "#727681",
                  800: "#a1a4ab",
                  900: "#d0d1d5",
        },
        green: {
                  100: "#0f2922",
                  200: "#1e5245",
                  300: "#2e7c67",
                  400: "#3da58a",
                  500: "#4cceac",
                  600: "#70d8bd",
                  700: "#94e2cd",
                  800: "#b7ebde",
                  900: "#dbf5ee",
        },
        red: {
                  100: "#2c100f",
                  200: "#58201e",
                  300: "#832f2c",
                  400: "#af3f3b",
                  500: "#db4f4a",
                  600: "#e2726e",
                  700: "#e99592",
                  800: "#f1b9b7",
                  900: "#f8dcdb",
        },
        blue: {
                  100: "#151632",
                  200: "#2a2d64",
                  300: "#3e4396",
                  400: "#535ac8",
                  500: "#6870fa",
                  600: "#868dfb",
                  700: "#a4a9fc",
                  800: "#c3c6fd",
                  900: "#e1e2fe",
        }
    })
})

export const themeSetting = (mode: ThemeMode): ThemeType => {
    const colors = colorPalette(mode)

    return {
        pallete: {
            mode: mode,
            ...(mode === "dark"
            ? {
                primary: {
                    main: colors.blue[400]
                },
                secondary: {
                    main: colors.green[500]
                },
                tertiery: {
                    main: colors.red[500]
                },
                neutral: {
                    dark: colors.grey[700],
                    main: colors.grey[500],
                    light: colors.grey[100]
                },
                background: {
                    default: colors.black[400],
                    light: colors.black[900]
                },
                font: {
                    dark: colors.black[700],
                    main: colors.black[500],
                    light: colors.black[100],
                }
            } : {
                primary: {
                    main: colors.blue[500]
                },
                secondary: {
                    main: colors.green[400]
                },
                tertiery: {
                    main: colors.red[500]
                },
                neutral: {
                    dark: colors.grey[700],
                    main: colors.grey[500],
                    light: colors.grey[100]
                },
                background: {
                    default: "#fcfcfc",
                    light: "#eaeaeaea"
                },
                font: {
                    dark: colors.black[600],
                    main: colors.black[500],
                    light: colors.black[100],
                }
            })
        },
        typography: {
            fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
            fontSize: 1.2,
            media: 0.8,
            h1: {
                fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
                fontSize: 4.0,
            },
            h2: {
                fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
                fontSize: 3.2,
            },
            h3: {
                fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
                fontSize: 2.4,
            },
            h4: {
                fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
                fontSize: 2.0,
            },
            h5: {
                fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
                fontSize: 1.6,
            },
            h6: {
                fontFamily: [ "Source Sans Pro", "sans-serif" ].join(","),
                fontSize: 1.4,
            }
        },
        spacing: {
            sm: 1.2,
            md: 1.4,
            lg: 1.6,
            xl: 1.8
        },
        icon: {
            size: {
                sm: 1.4,
                md: 1.6,
                lg: 1.8,
                xl: 2
            }
        }
    }
}