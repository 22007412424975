import React from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

export interface TableHeaderDataProps {
    key: string
    name: string
    align?: "left" | "center" | "right"
    width?: string | number
    hide?: boolean
    sort?: "asc" | "desc" | "unsort"
    onClick?: React.MouseEventHandler<HTMLTableHeaderCellElement>
}

interface TableBodyDataProps {
    [key: string]: any
}

interface Props {
    width?: string | number
    height?: string | number
    header?: React.ReactNode | TableHeaderDataProps[]
    body?: React.ReactNode | TableBodyDataProps[]
    id?: string
    mediaScreen?: boolean
}

interface TableCellProps {
    align?: "left" | "center" | "right"
    width?: string | number
}

const TableContainer = styled.div<ThemePalleteProps & Props & { mediaScreen?: boolean }>`
    position: relative;
    box-sizing: border-box;
    background-color: ${props => props.themeSetting?.pallete.background.light};
    width: ${props => !props.width ? "100%" : (typeof props.width === "string" ? props.width : (props.width + "rem"))};
    height: ${props => !props.height ? "auto" : (typeof props.height === "string" ? props.height : (props.height + "rem"))};
    overflow-y: scroll;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);

    @media (max-width: 768px) {
        overflow-x: ${props => props.mediaScreen ? "scroll" : null};
    }
`

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
`

const StyledTh = styled.th<ThemePalleteProps & TableCellProps & { sort?: "asc" | "unsort" | "desc" }>`
    top: 0;
    position: sticky;
    background-color: ${props => 
        !props.sort
        ? props.pallete?.grey[800] 
        : (
            props.sort === "unsort"
            ? props.pallete?.grey[800] 
            : props.pallete?.grey[700]
        )
    };
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;
    padding: calc(${props => props.themeSetting?.spacing.sm}rem /2) calc(${props => props.themeSetting?.spacing.sm}rem / 1.5);
    text-align: ${props => !props.align ? "left" : props.align};
    width: ${props => !props.width ? "auto" : (typeof props.width === "string" ? props.width : props.width + "rem")};
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    user-select: none;
    cursor: ${props => props.sort !== undefined ? "pointer" : null};

    &:not(:last-child) {
        border-right: 0.1rem solid ${props => props.pallete?.grey[800]};
    }

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        padding: 0 calc(${props => props.themeSetting?.spacing.sm}rem / 2);
        line-height: 2.5;
        white-space: nowrap;
	}
`

const StyledRow = styled.tr<ThemePalleteProps & { hide?: boolean }>`
    display: ${props => props.hide ? "none" : "table-row"};

    &:hover {
        background-color: ${props => props.pallete?.grey[900]};
    }

    & td {
        border-bottom: 0.1rem dotted ${props => props.pallete?.grey[700]};
    }
`

const StyledTd = styled.td<ThemePalleteProps & TableCellProps & { opacity?: number, onClick?: React.MouseEventHandler<HTMLTableCellElement> }>`
    padding: 0 calc(${props => props.themeSetting?.spacing.sm}rem / 1.5);
    text-align: ${props => !props.align ? "left" : props.align};
    user-select: none;
    opacity: ${props => props.opacity ? props.opacity : null};
    line-height: 2.5;
    cursor: ${props => props.onClick ? "pointer" : null};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;

    &:not(:last-child) {
        border-right: 0.1rem dotted ${props => props.pallete?.grey[700]};
    }

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
        padding: calc(${props => props.themeSetting?.spacing.sm}rem / 4.5) calc(${props => props.themeSetting?.spacing.sm}rem / 2);
        line-height: 1.5;
        white-space: nowrap;
	}
`

const TR = (props: { 
    children?: React.ReactNode, 
    onDoubleClick?: React.MouseEventHandler<HTMLTableRowElement>,
    onClick?: React.MouseEventHandler<HTMLTableRowElement>,
    hide?: boolean
}) => {
    const {theme, pallete} = useThemeSetting()

    return <StyledRow 
    themeSetting={theme} 
    pallete={pallete} 
    onDoubleClick={props.onDoubleClick} 
    onClick={props.onClick}
    hide={props.hide}
    >
        {props.children}
    </StyledRow>
}

const TH = (props: { 
    children?: React.ReactNode, 
    align?: "left" | "center" | "right", 
    width?: string | number, 
    sort?: "asc" | "desc" | "unsort", 
    onClick?: React.MouseEventHandler<HTMLTableHeaderCellElement> 
}) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledTh 
            themeSetting={theme} 
            pallete={pallete}
            align={props.align}
            width={props.width}
            sort={props.sort}
            onClick={props.onClick}
            >
            {props.children}
        </StyledTh>
    )
}

const TD = (props: { children?: React.ReactNode, align?: "left" | "center" | "right", onClick?: React.MouseEventHandler<HTMLTableCellElement>, colSpan?: number, opacity?: number }) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledTd 
            themeSetting={theme} 
            pallete={pallete}
            align={props.align}
            onClick={props.onClick}
            colSpan={props.colSpan}
            opacity={props.opacity}
            >
            {props.children}
        </StyledTd>
    )
}

const Table = (props: Props) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <TableContainer 
        themeSetting={theme} 
        pallete={pallete} 
        width={props.width}
        height={props.height}
        mediaScreen={props.mediaScreen}
        >
            <StyledTable id={props.id}>
                <thead>
                    <TR>
                        {
                            Array.isArray(props.header)
                            ? (
                                props.header.map((row, _) => {
                                    if(row.hide) {
                                        return null
                                    } else {
                                        return <TH 
                                        key={"tableHead" + _} 
                                        align={row.align} 
                                        width={row.width} 
                                        sort={row.sort} 
                                        onClick={row.onClick}
                                        >{row.name}</TH>
                                    }
                                })
                            ) : (
                                props.header
                            )
                        }
                    </TR>
                </thead>
                <tbody>
                    {
                        Array.isArray(props.body)
                        ? (
                            props.body.map((row, i) => {
                                return <TR key={"tableRow" + i}>
                                    {
                                        Array.isArray(props.header) && props.header.map((col, j) => {
                                            if(col.hide) {
                                                return null
                                            } else {
                                                return <TD key={"tableCell" + j}
                                                align={col.align}
                                                onClick={Array.isArray(row[col.key]) ? row[col.key][1] : undefined}                                            
                                                >
                                                    {
                                                        Array.isArray(row[col.key]) 
                                                        ? row[col.key][0] 
                                                        : row[col.key]
                                                    } 
                                                </TD>
                                            }
                                        })
                                    }
                                </TR>
                            })
                        ) : (
                            props.body
                        )
                    }
                </tbody>
            </StyledTable>
        </TableContainer>
    )
}

export {Table, TR, TH, TD}