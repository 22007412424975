import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { matchObjectSearch } from '../helpers/matchObjectSearch'
import { ResetIcon, SettingIcon } from '../icons/icon'
import Box from './Box'
import { Card, CardBody } from './Card'
import Checkbox from './Checkbox'
import Input from './Input'
import LoadingContainer from './LoadingContainer'
import Modal from './Modal'
import PageContent from './PageContent'
import { Table, TableHeaderDataProps, TD, TH, TR } from './Table'
import TextButton from './TextButton'

interface Props {
    defaultHeader: TableHeaderDataProps[]
    columnSetting: string
    data: { [key: string]: any }[]
    filterKey?: string[]
    loading?: boolean
    tableRowClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}

export const IconGroupContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.1rem;
`

const StyledBox = styled(Box)`
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 0.5rem;
    }
`

const StyledSettingBox = styled(Box)`
    gap: 3rem;

    @media (max-width: 768px) {
        gap: 2rem;
    }
`


const ColumnSettingModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    columnHeader: TableHeaderDataProps[]
    setColumnHeader: React.Dispatch<React.SetStateAction<TableHeaderDataProps[]>>
    defaultHeader: TableHeaderDataProps[]
}) => {
    const handleSetColumnHeader = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let newData = [...props.columnHeader]
        newData[index].hide = !e.target.checked
        props.setColumnHeader(newData)
    }

    const handleResetColumn = () => {
        let newHeader = JSON.parse(JSON.stringify(props.defaultHeader))
        props.setColumnHeader(newHeader)
    }

    return <Modal
        title="Column Setting"
        width="fit-content"
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
        mediaWidth='fit-content'
    >
        <Box flexDirection='column' gap="1rem">
            <Box gap="10rem">
                <Box flexDirection='column' gap="0.5rem">
                    {
                        props.columnHeader.map((row, _) => {
                            if (row.name === "") return null
                            if (_ > 8) return null
                            return <Checkbox key={row.key + _} label={row.name} checked={row.hide ? false : true} onChange={e => handleSetColumnHeader(e, _)} />
                        })
                    }
                </Box>
                <Box flexDirection='column' gap="0.5rem">
                    {
                        props.columnHeader.map((row, _) => {
                            if (row.name === "") return null
                            if (_ <= 8) return null
                            return <Checkbox key={row.key + _} label={row.name} checked={row.hide ? false : true} onChange={e => handleSetColumnHeader(e, _)} />
                        })
                    }
                </Box>
            </Box>
            <Box alignItems='center' justifyContent='center'>
                <IconGroupContainer onClick={handleResetColumn}>
                    <ResetIcon />
                    Reset Column
                </IconGroupContainer>
            </Box>
        </Box>
    </Modal>
})

const TableRow = React.memo((props: {
    columnHeader: TableHeaderDataProps[]
    row: { [key: string]: any }
    tableRowClick?: (e: React.MouseEvent<HTMLTableRowElement>) => void
}) => {
    return <TR onClick={props.tableRowClick}>
        {
            Array.isArray(props.columnHeader) && props.columnHeader.map((col, j) => {
                if (col.hide) return null

                return <TD key={"tableCell" + j} align={col.align}>
                    {
                        col.key === "date_time"
                            ? new Date(props.row[col.key]).toLocaleDateString("en-GB")
                            : props.row[col.key]
                    }
                </TD>
            })
        }
    </TR>
})

const ManageTable = ({ data, filterKey, ...props }: Props) => {
    const [showModal, setShowModal] = useState(false)

    const [filter, setFilter] = useState("")
    const [showRecord, setShowRecord] = useState(500)

    const [columnHeader, setColumnHeader] = useState<TableHeaderDataProps[]>(() => {
        let column = window.localStorage.getItem(props.columnSetting)

        if (column) {
            return JSON.parse(column)
        } else {
            let storageData = JSON.stringify(props.defaultHeader)

            window.localStorage.setItem(props.columnSetting, storageData)
            return JSON.parse(storageData)
        }
    })

    const handleSort = (sort: "asc" | "desc" | "unsort", index: number) => {
        let newColumnData = [...columnHeader]

        for (let i = 0; i < newColumnData.length; i++) {
            if (i !== index) {
                if (newColumnData[i].sort !== undefined) {
                    newColumnData[i].sort = "unsort"
                }
            }
        }

        if (sort === "asc") {
            newColumnData[index].sort = "unsort"
        } else if (sort === "unsort") {
            newColumnData[index].sort = "desc"
        } else if (sort === "desc") {
            newColumnData[index].sort = "asc"
        }

        setColumnHeader(newColumnData)
    }

    const memoData = useMemo(() => {
        if (!Array.isArray(data)) return []

        let newData = [...data]

        let sortHead = columnHeader.filter(row => row.sort !== undefined && (row.sort === "asc" || row.sort === "desc"))
        if (sortHead.length === 1) {
            let sorted = sortHead[0]

            if (sorted.sort === "asc") {
                newData.sort((a, b) => {
                    if (a[sorted.key] < b[sorted.key]) return 1
                    if (a[sorted.key] > b[sorted.key]) return -1
                    return 0
                })
            } else if (sorted.sort === "desc") {
                newData.sort((a, b) => {
                    if (a[sorted.key] > b[sorted.key]) return 1
                    if (a[sorted.key] < b[sorted.key]) return -1
                    return 0
                })
            }
        }

        return newData
    }, [data, columnHeader])

    const filteredData = useMemo(() => {
        if (!filter) {
            return memoData
        }
        return memoData.filter(row => matchObjectSearch(row, filterKey ? filterKey : [], filter))
    }, [filter, memoData, filterKey])

    const finalData = useMemo(() => {
        return filteredData.slice(0, showRecord)
    }, [filteredData, showRecord])

    useEffect(() => {
        if (Array.isArray(columnHeader)) {
            window.localStorage.setItem(props.columnSetting, JSON.stringify(columnHeader))
        }
    }, [columnHeader, props.columnSetting])

    return (
        <>
            <PageContent>
                <Card width="100%">
                    <CardBody>
                        <Box flexDirection='column' alignItems='flex-end' gap="1rem">
                            <StyledBox className='manageTableTopContainer'>
                                <Input
                                    width="30rem"
                                    mediaWidth='100%'
                                    className="manageTableFilterInput"
                                    type="text"
                                    placeholder='Search'
                                    value={filter}
                                    onChange={e => setFilter(e.target.value)}
                                />
                                <StyledSettingBox>
                                    <Box gap="0.3rem" alignItems='center'>
                                        <Box>Scroll : </Box>
                                        <TextButton onClick={() => {
                                            let allTableRow = document.querySelectorAll("#manageTable tr")
                                            allTableRow[0].scrollIntoView({
                                                behavior: "smooth",
                                                block: "center"
                                            })
                                        }}>Top</TextButton>
                                        <Box>/</Box>
                                        <TextButton onClick={() => {
                                            let allTableRow = document.querySelectorAll("#manageTable tr")
                                            allTableRow[allTableRow.length - 1].scrollIntoView({
                                                behavior: "smooth",
                                                block: "center"
                                            })
                                        }}>Bottom</TextButton>
                                    </Box>
                                    <IconGroupContainer onClick={() => setShowModal(true)}>
                                        <SettingIcon />
                                        <div>Column Setting</div>
                                    </IconGroupContainer>
                                </StyledSettingBox>
                            </StyledBox>
                            {
                                props.loading
                                    ? <LoadingContainer height="75vh" />
                                    : (
                                        <Table
                                            id="manageTable"
                                            height="75vh"
                                            header={<>
                                                {
                                                    columnHeader.map((row, _) => {
                                                        if (row.hide) return null

                                                        return <TH
                                                            key={"tableHead" + _}
                                                            align={row.align}
                                                            width={row.width}
                                                            sort={row.sort}
                                                            onClick={row.sort !== undefined ? handleSort.bind(null, row.sort, _) : undefined}
                                                        >{row.name}</TH>
                                                    })
                                                }
                                            </>}
                                            body={
                                                <>
                                                    {
                                                        finalData.map((row, i) => {
                                                            return <TableRow key={"tableRow" + i} row={row} columnHeader={columnHeader} tableRowClick={props.tableRowClick} />
                                                        })
                                                    }
                                                    {
                                                        finalData.length < filteredData.length &&
                                                        <TR>
                                                            <TD
                                                                opacity={0.5}
                                                                align='center'
                                                                colSpan={columnHeader.length}
                                                                onClick={() => {
                                                                    setShowRecord(v => v + 500)
                                                                }}>
                                                                More Result
                                                            </TD>
                                                        </TR>
                                                    }
                                                </>
                                            }
                                        />
                                    )
                            }
                            <Box width="100%" justifyContent='space-between'>
                                <Box>
                                    {
                                        finalData.filter(row => row.checked !== undefined).length !== 0 &&
                                        <>Selected : {finalData.filter(row => !!row.checked).length}</>
                                    }
                                </Box>
                            
                                <Box>
                                    Show Record : {finalData.length} / {filteredData.length}

                                </Box>
                            </Box>
                        </Box>
                    </CardBody>
                </Card>
            </PageContent>

            <ColumnSettingModal
                showModal={showModal}
                setShowModal={setShowModal}
                columnHeader={columnHeader}
                setColumnHeader={setColumnHeader}
                defaultHeader={props.defaultHeader}
            />
        </>
    )
}

export default ManageTable