import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';

pdfMake.vfs = pdfFonts.pdfMake.vfs

const handleClientPdf = ( doc: TDocumentDefinitions ) => {
    return pdfMake.createPdf(doc)
}

class PdfHandler {
    doc: TDocumentDefinitions
    fileName: string | undefined

    constructor(_doc: TDocumentDefinitions, _fileName: string | undefined = undefined) {
        this.doc = _doc
        this.fileName = _fileName
    }

    print() {
        var win = window.open('', '_blank');
        handleClientPdf(this.doc).print({}, win)
        return setTimeout(() => {
            win!.print()
        }, 1000)
    }

    download() {
        return handleClientPdf(this.doc).download(this.fileName + ".pdf")
    }

    view() {
        return handleClientPdf(this.doc).open()
    }
}

export default PdfHandler