import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'
import Box from './Box'
import BreadcrumbButton from './BreadcrumbButton'

interface BreadCrumbItemsProps {
    name: string
    navigate?: string
    active?: boolean
}

export interface BreadCrumbButtonProps {
    name: string
    action?: string | (() => void)
    color: "blue" | "green" | "red"
}

interface BreadCrumbProps {
    children?: React.ReactNode
    items: BreadCrumbItemsProps[]
    button?: BreadCrumbButtonProps[]
}

const BreadcrumbContainer = styled.div<ThemePalleteProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: calc(${props => props.themeSetting?.typography.h6.fontSize}rem * 0.9);

    font-weight: 500;
    user-select: none;
    line-height: 1.5;

    @media (max-width: 768px) {
        font-size: calc(${props => props.themeSetting?.typography.h6.fontSize}rem * 0.5);
        gap: 1rem;
        line-height: 1;
        flex-direction: column;
        align-items: flex-start;
    }
`

const BreadcrumbItem = styled.div<ThemePalleteProps>`
    color: ${props => props.themeSetting?.pallete.neutral.light};
    opacity: 0.5;
    cursor: pointer;

    &.active {
        opacity: 1;
        cursor: default;
    }

    &:hover {
        opacity: 0.7;
    }

    &.active:hover {
        opacity: 1;
    }
`

const StyledBox = styled(Box)`
    gap: 1rem;

    @media (max-width: 768px) {
        gap: 0.3rem;
    }
`

const StyledBreadcrumbBox = styled(Box)`
    gap: 0.3rem;
`

const Breadcrumb = (props: BreadCrumbProps) => {
    let history = useNavigate()
    const { theme } = useThemeSetting()

    return (
        <BreadcrumbContainer themeSetting={theme}>
            <StyledBreadcrumbBox>
                {
                    Array.isArray(props.items) && 
                    props.items.map((row, _) => {
                        return <BreadcrumbItem
                            key={row.name + _}
                            className={"breadcrumb_item" + (row.active ? " active" : "")}
                            themeSetting={theme}
                            onClick={() => row.navigate && history(row.navigate)} >
                            {row.name} {props.items.length !== _ + 1 && "/"}
                            </BreadcrumbItem>
                    })
                }
            </StyledBreadcrumbBox>
            {
                props.button
                && (
                    <StyledBox>
                        {
                            props.button && 
                            Array.isArray(props.button) &&
                            props.button.map((row, _) => {
                                return <BreadcrumbButton
                                    key={"breadcrumbButton" + _}
                                    color={row.color}
                                    onClick={() => (
                                        typeof row.action === "string"
                                            ? history(row.action)
                                            : (
                                                row.action ? row.action() : undefined
                                            )
                                    )}
                                >
                                    {row.name}
                                </BreadcrumbButton>
                            })
                        }
                    </StyledBox>
                )
            }
        </BreadcrumbContainer>
    )
}

export default Breadcrumb