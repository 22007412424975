import React from 'react'
import styled from 'styled-components'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface Props {
    children?: React.ReactNode
    height?: string
}

const Styled = styled.div<ThemePalleteProps & { height?: string }>`
    height: ${props => props.height};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.themeSetting?.typography.h6.fontSize}rem;
    background-color: ${props => props.pallete?.grey[900]};

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
	}
`

const LoadingContainer = (props:Props) => {
    const {theme, pallete} = useThemeSetting()
  return (
    <Styled height={props.height} themeSetting={theme} pallete={pallete}>{props.children || "Loading Data..."}</Styled>
  )
}

export default LoadingContainer