import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import { Card, CardHeader } from '../components/Card'
import PageContent from '../components/PageContent'
import StaticTableRow from '../components/StaticTableRow'
import { Table, TD, TH, TR } from '../components/Table'
import { fetchInvoice, fetchInvoiceDetail, fetchLocation, fetchStockOrder, fetchStockOrderDetail, fetchSupplier } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { DeleteIcon, DownloadIcon, EditIcon, SelectIcon } from '../icons/icon'
import { IInvoice, IInvoiceDetail, ILocation, IStockOrder, IStockOrderDetail, ISupplier } from '../types/types'
import BreadcrumbButton from '../components/BreadcrumbButton'
import TextButton from '../components/TextButton'
import PdfDefinition from '../helpers/pdfDefinition'
import PdfHandler from '../helpers/pdfHandler'
import Select from '../components/Select'
import Input from '../components/Input'
import axios from 'axios'
import LoadingContainer from '../components/LoadingContainer'
import styled from 'styled-components'

const StyledHeader1 = styled(Box)`
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
    }
`

const StyledBottomBox = styled(Box)`
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const breadCrumbItems = [
    { name: "Home", active: true }
]

const DUTY_INVOICE_HEADER = "Aplus Brunei"

const OnComingStockSection = React.memo((props: {
    stockOrderDetail: IStockOrderDetail[]
    setStockOrderDetail: React.Dispatch<React.SetStateAction<IStockOrderDetail[]>>
    refData: React.MutableRefObject<IStockOrderDetail[]>
    suppliers: ISupplier[]
    locations: ILocation[]
    handleDelete: (_data: IStockOrderDetail) => void
    handleEdit: (_data: IStockOrderDetail) => void
    loading: boolean
}) => {
    const [filter, setFilter] = useState("")
    const [scroll, setScroll] = useState(true)

    return <Card width="100%">
        <Box flexDirection='column' gap="1rem">
            <CardHeader>
                <StyledHeader1>
                    <Box>On Coming Stock</Box>
                    <Input 
                    width='35rem' 
                    mediaWidth='100%'
                    placeholder='Filter Running / Supplier / Dealer / Product / Model No.' 
                    value={filter} 
                    onChange={e => setFilter(e.target.value)} />
                </StyledHeader1>
            </CardHeader>
            {
                props.loading
                ? <LoadingContainer height='38vh' />
                :  <Table
                height={scroll ? "38vh" : "auto"}
                header={
                    <>
                        <TH align="center" width="4%">#</TH>
                        <TH width="10%">Running</TH>
                        <TH width="10%">Supplier</TH>
                        <TH width="10%">Dealer</TH>
                        <TH>Item Description</TH>
                        <TH width="12%">Model No.</TH>
                        <TH width="6%">Cost(MYR)</TH>
                        <TH align='center' width="5%">Qty</TH>
                        <TH align='center' width="5%">Changed</TH>
                        <TH align='center' width="3%"></TH>
                    </>
                }
                body={
                    <>
                        {
                            props.stockOrderDetail
                                .filter(row => {
                                    return row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                                        row.location.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                                        (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                                        row.model_no.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                                        row.running.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                                })
                                .map((row, _) => {
                                    return <TR key={row.so_detail_id}>
                                        <TD align="center">{_ + 1}</TD>
                                        <TD>{row.running}</TD>
                                        <TD>
                                            <Select width='100%' value={row.s_id as unknown as string} onChange={e => {
                                                let newData = [...props.stockOrderDetail]
                                                let index = newData.map(el => el.so_detail_id).indexOf(row.so_detail_id)
                                                newData[index].s_id = e.target.value as unknown as number
                                                props.setStockOrderDetail(newData)
                                            }}>
                                                {
                                                    props.suppliers.map(el => {
                                                        return <option key={el.s_id} value={el.s_id}>{el.supplier}</option>
                                                    })
                                                }
                                            </Select>
                                        </TD>
                                        <TD>
                                            <Select width='100%' value={row.l_id as unknown as string} onChange={e => {
                                                let newData = [...props.stockOrderDetail]
                                                let index = newData.map(el => el.so_detail_id).indexOf(row.so_detail_id)
                                                newData[index].l_id = e.target.value as unknown as number
                                                props.setStockOrderDetail(newData)
                                            }}>
                                                {
                                                    props.locations.map(el => {
                                                        return <option key={el.l_id} value={el.l_id}>{el.location}</option>
                                                    })
                                                }
                                            </Select>
                                        </TD>
                                        <TD>{row.brand + " " + row.product}</TD>
                                        <TD>{row.model_no}</TD>
                                        <TD>{row.cost}</TD>
                                        <TD align="center">
                                            <Input align="center" width="100%" value={row.qty as unknown as string} onChange={e => {
                                                let newData = [...props.stockOrderDetail]
                                                let index = newData.map(el => el.so_detail_id).indexOf(row.so_detail_id)
                                                newData[index].qty = e.target.value as unknown as number
                                                props.setStockOrderDetail(newData)
                                            }} />
                                        </TD>
                                        <TD align="center">
                                            {
                                                (
                                                    props.refData.current.filter(el => el.so_detail_id === row.so_detail_id)[0].s_id.toString() !== row.s_id.toString() ||
                                                    props.refData.current.filter(el => el.so_detail_id === row.so_detail_id)[0].l_id.toString() !== row.l_id.toString() ||
                                                    props.refData.current.filter(el => el.so_detail_id === row.so_detail_id)[0].qty.toString() !== row.qty.toString()
                                                ) &&
                                                <Box alignItems='center' justifyContent='center'>
                                                    <EditIcon iconcolor='blue' onClick={() => props.handleEdit(row)} />
                                                </Box>
                                            }
                                        </TD>
                                        <TD align='center'>
                                            <Box alignItems='center' justifyContent='center'>
                                                <DeleteIcon iconcolor='red' onClick={() => props.handleDelete(row)} />
                                            </Box>
                                        </TD>
                                    </TR>
                                })
                        }
                        {
                            <StaticTableRow row={15 - props.stockOrderDetail.length} column={10} />
                        }
                    </>
                }
            />
            }
           
            <Box justifyContent='flex-end'>
                <TextButton onClick={() => {
                    setScroll(v => !v)
                    setTimeout(() => {
                        document.querySelector("body")?.scrollTo(0,0)
                    }, 100)
                }}>{scroll ? "Full Table" : "Scroll Table"}</TextButton>
            </Box>
        </Box>
    </Card>
})

const Home = () => {
    let history = useNavigate()

    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)
    const [stockOrder, setStockOrder] = useState<IStockOrder[]>([])
    const [stockOrderDetail, setStockOrderDetail] = useState<IStockOrderDetail[]>([])
    const [invoice, setInvoice] = useState<IInvoice[]>([])
    const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetail[]>([])
    const [suppliers, setSuppliers] = useState<ISupplier[]>([])
    const [locations, setLocations] = useState<ILocation[]>([])

    const { setAlert } = useAlert()
    const { token, setToken } = useLogin()

    const refData = useRef<IStockOrderDetail[]>([])

    const fetchData = useCallback(async () => {
        setLoading(true)
        try {
            let [_stockOrder, _stockOrderDetail, _invoice, _invoiceDetail, _supplier, _location] = await Promise.all([
                fetchStockOrder(token),
                fetchStockOrderDetail(token),
                fetchInvoice(token),
                fetchInvoiceDetail(token),
                fetchSupplier(token),
                fetchLocation(token)
            ])

            setStockOrder(_stockOrder.sort((a, b) => b.so_id - a.so_id).slice(0, 10).filter(row => !row.invoice_id))
            setInvoice(_invoice.sort((a, b) => b.invoice_id - a.invoice_id).slice(0, 10).filter(row => !row.duty_id))
            setInvoiceDetail(_invoiceDetail)
            setSuppliers(_supplier.filter(row => row.type === "order"))
            setLocations(_location.filter(row => row.type === "dealer" && row.status === "active"))


            let _comingStock = _stockOrderDetail.filter(row => _stockOrder.sort((a, b) => b.so_id - a.so_id).slice(0, 10)
                .filter(row => !row.invoice_id).map(el => el.so_id).indexOf(row.so_id) !== -1)
            setStockOrderDetail(_comingStock)

            refData.current = JSON.parse(JSON.stringify(_comingStock))
            setLoading(false)
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
            setLoading(false)
        }
    }, [reload, token, setToken, setAlert])

    const handleEdit = useCallback((_data: IStockOrderDetail) => {
        setAlert({
            show: true,
            message: "Update Item ?",
            type: "question",
            cb: async () => {
                try {
                    let update = await axios.patch("/stockOrderDetail/" + _data.so_detail_id, {
                        s_id: _data.s_id,
                        l_id: _data.l_id,
                        qty: _data.qty
                    })


                    if (update.status !== 200) {
                        setAlert({ show: true, message: update.data.message, type: "danger" })
                        return
                    }

                    return setAlert({
                        show: true,
                        message: "Item Updated Successfully !!!",
                        type: "success",
                        cb: () => {
                            fetchData()
                        }
                    })

                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }, [fetchData, setAlert])

    const handleDelete = useCallback((_data: IStockOrderDetail) => {
        setAlert({
            show: true,
            message: "Delete Item ?",
            type: "question",
            cb: async () => {
                try {
                    let update = await axios.delete("/stockOrderDetail/" + _data.so_detail_id)


                    if (update.status !== 200) {
                        setAlert({ show: true, message: update.data.message, type: "danger" })
                        return
                    }

                    return setAlert({
                        show: true,
                        message: "Item Deleted Successfully !!!",
                        type: "success",
                        cb: () => {
                            fetchData()
                        }
                    })

                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }, [setAlert, fetchData])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb items={breadCrumbItems} />
            <PageContent>
                <Box flexDirection='column' gap="2rem">
                    <OnComingStockSection 
                    suppliers={suppliers}
                    locations={locations}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    refData={refData}
                    stockOrderDetail={stockOrderDetail}
                    setStockOrderDetail={setStockOrderDetail}
                    loading={loading}
                    />
                    <StyledBottomBox>
                        <Box flex={1}>
                            <Card width="100%">
                                <Box flexDirection='column' gap="1rem">
                                    <CardHeader>
                                        <Box alignItems='center' justifyContent='space-between'>
                                            KIV Stock Order
                                            <BreadcrumbButton color="blue" onClick={() => history("/stockOrder/new")}>New Stock Order</BreadcrumbButton>
                                        </Box>
                                    </CardHeader>
                                    {
                                        loading
                                        ? <LoadingContainer height="30vh" />
                                        : <Table
                                        height="30vh"
                                        header={
                                            <>
                                                <TH align="center" width="5%">#</TH>
                                                <TH>Date</TH>
                                                <TH>Running</TH>
                                                <TH>Invoice Header</TH>
                                                <TH>Permit Holder</TH>
                                                <TH width="5%">Edit</TH>
                                            </>
                                        }
                                        body={
                                            <>
                                                {
                                                    stockOrder.map((row, _) => {
                                                        return <TR key={row.so_id}>
                                                            <TD align='center'>{_ + 1}</TD>
                                                            <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                                            <TD>
                                                                <Box alignItems='center' justifyContent='space-between'>
                                                                    <TextButton onClick={() => {
                                                                        let _detail = stockOrderDetail.filter(el => el.so_id === row.so_id)
                                                                        let _doc = PdfDefinition.customStockOrder(row, _detail)
                                                                        return new PdfHandler(_doc, row.running).view()
                                                                    }}
                                                                    >{row.running}</TextButton>
                                                                    <DownloadIcon iconcolor='red' onClick={() => {
                                                                        let _detail = stockOrderDetail.filter(el => el.so_id === row.so_id)
                                                                        let _doc = PdfDefinition.customStockOrder(row, _detail)
                                                                        return new PdfHandler(_doc, row.running).download()
                                                                    }} />
                                                                </Box>
                                                            </TD>
                                                            <TD>{row.supplier}</TD>
                                                            <TD>{row.location}</TD>
                                                            <TD align='center'>
                                                                <Box justifyContent='center' alignItems='center'>
                                                                    <SelectIcon iconcolor='red' onClick={() => history("/orderForm/" + row.so_id)} />
                                                                </Box>
                                                            </TD>
                                                        </TR>
                                                    })
                                                }
                                                <StaticTableRow row={8 - stockOrder.length} column={6} />
                                            </>
                                        }
                                    />
                                    }
                                    
                                </Box>
                            </Card>
                        </Box>
                        <Box flex={1}>
                            <Card width="100%">
                                <Box flexDirection='column' gap="1rem">
                                    <CardHeader>
                                        <Box alignItems='center' justifyContent='space-between'>
                                            KIV Invoice
                                            <Box gap="1rem">
                                                <BreadcrumbButton color="blue" onClick={() => history("/invoice/new")}>New Invoice</BreadcrumbButton>
                                                <BreadcrumbButton color="blue" onClick={() => history("/duty/new")}>New Duty</BreadcrumbButton>
                                            </Box>
                                        </Box>
                                    </CardHeader>
                                    {
                                        loading
                                        ? <LoadingContainer height="30vh" />
                                        : <Table
                                        height="30vh"
                                        header={
                                            <>
                                                <TH align="center" width="5%">#</TH>
                                                <TH>Date</TH>
                                                <TH>Running</TH>
                                                <TH>Invoice Header</TH>
                                                <TH>Permit Holder</TH>
                                                <TH width="5%">Edit</TH>
                                                <TH width="5%">Duty</TH>
                                            </>
                                        }
                                        body={
                                            <>
                                                {
                                                    invoice.map((row, _) => {
                                                        return <TR key={row.so_id}>
                                                            <TD align='center'>{_ + 1}</TD>
                                                            <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                                            <TD>
                                                                <Box alignItems='center' justifyContent='space-between'>
                                                                    <TextButton onClick={async () => {
                                                                        let _detail = invoiceDetail.filter(el => el.invoice_id === row.invoice_id)
                                                                        let _doc = await PdfDefinition.aplusCustomInvoice(row, _detail)
                                                                        return new PdfHandler(_doc, row.running).view()
                                                                    }}
                                                                    >{row.running}</TextButton>
                                                                    <DownloadIcon iconcolor='red' onClick={async () => {
                                                                        let _detail = invoiceDetail.filter(el => el.invoice_id === row.invoice_id)
                                                                        let _doc = await PdfDefinition.aplusCustomInvoice(row, _detail)
                                                                        return new PdfHandler(_doc, row.running).download()
                                                                    }} />
                                                                </Box></TD>
                                                            <TD>{row.supplier}</TD>
                                                            <TD>{row.location}</TD>
                                                            <TD align='center'>
                                                                <Box justifyContent='center' alignItems='center'>
                                                                    <SelectIcon iconcolor='red' onClick={() => history("/invoice/" + row.so_id)} />
                                                                </Box>
                                                            </TD>
                                                            <TD align='center'>
                                                                {
                                                                    row.supplier === DUTY_INVOICE_HEADER &&
                                                                    <Box justifyContent='center' alignItems='center'>
                                                                        <SelectIcon iconcolor='blue' onClick={() => history("/duty/new", { state: { so_id: row.so_id } })} />
                                                                    </Box>
                                                                }
                                                            </TD>
                                                        </TR>
                                                    })
                                                }
                                                <StaticTableRow row={8 - invoice.length} column={7} />
                                            </>
                                        }
                                    />
                                    }
                                    
                                </Box>
                            </Card>
                        </Box>
                    </StyledBottomBox>
                </Box>
            </PageContent>
        </>
    )
}

export default Home