import React, { useState } from 'react'
import { alertContext } from './alertContext'

interface Props {
    children?: React.ReactNode
}

export interface InitialAlertProps {
    show: boolean
    message?: React.ReactNode,
    type?: "success" | "danger" | "question",
    cb?: () => any
    ok?: string
    cancel?: string
}

const initialAlert: InitialAlertProps = { show: false, message: null, type: undefined, cb: () => {}, ok: undefined, cancel: undefined }

const AlertState = (props: Props) => {
    const [alert, setAlert] = useState<InitialAlertProps>(initialAlert)

    return (
        <alertContext.Provider value={{ alert, setAlert, initialAlert }}>
            {props.children}
        </alertContext.Provider>
    )
}

export default AlertState