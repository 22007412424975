import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Box from '../components/Box'
import Breadcrumb from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardFooter, CardHeader } from '../components/Card'
import Input from '../components/Input'
import LoadingContainer from '../components/LoadingContainer'
import Modal from '../components/Modal'
import PageContent from '../components/PageContent'
import Select from '../components/Select'
import StaticTableRow from '../components/StaticTableRow'
import { Table, TD, TH, TR } from '../components/Table'
import Tabs from '../components/Tabs'
import { fetchLocation, fetchProduct, fetchStockOrder, fetchStockOrderById, fetchStockOrderDetailByField, fetchSupplier } from '../fetchers/fetcher'
import PdfDefinition from '../helpers/pdfDefinition'
import PdfHandler from '../helpers/pdfHandler'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { DeleteIcon, EditIcon, SelectIcon } from '../icons/icon'
import { ILocation, IProduct, IStockOrder, IStockOrderDetail, ISupplier } from '../types/types'

interface ProductTableProps {
    products: IProduct[]
    handleSelectProduct: (data: IProduct) => void
    forwardedRef: React.RefObject<HTMLInputElement>
    qtyForwardedRef: React.RefObject<HTMLInputElement>
    loading: boolean
}

interface PopModalProps {
    showModal: {
        show: boolean
        data: "" | "location" | "supplier"
    }
    setShowModal: React.Dispatch<React.SetStateAction<{
        show: boolean
        data: "" | "location" | "supplier"
    }>>
    locations: ILocation[]
    suppliers: ISupplier[]

    handleSelectLocation: (data: ILocation) => void
    handleSelectSupplier: (data: ISupplier) => void
}

interface OrderTableProps {
    info?: IStockOrder
    detail: IStockOrderDetail[]
}

const StyledWholeBox = styled(Box)`
    gap: 3rem;
    flex-direction: column;

    @media (max-width: 768px) {
        gap: 1rem;
    }
`


const StyledTopBox = styled(Box)`
    gap: 3rem;

    @media (max-width: 768px) {
        gap: 1rem;
        flex-direction: column;
    }
`


const breadCrumb = [
    { name: "Stock Order Maintenance", navigate: "/stockOrder" },
    { name: "Order Form", active: true },
]

const initialModal: {
    show: boolean
    data: "" | "location" | "supplier"
} = {
    show: false,
    data: ""
}

const initialDealer: {
    l_id: null | number
    location: string
} = {
    l_id: null,
    location: ""
}

const initialSupplier: {
    s_id: null | number
    supplier: string
} = {
    s_id: null,
    supplier: ""
}

const initialProduct: {
    p_id: null | number
    product: string
} = {
    p_id: null,
    product: ""
}

const ProductTable = React.memo((tableProps: ProductTableProps) => {
    const [filterProduct, setFilterProduct] = useState("")

    const handleOnKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            let _product = tableProps.products.filter(row => {
                return (row.brand + " " + row.product).toLowerCase().indexOf(filterProduct.toLowerCase()) !== -1 ||
                    row.model_no.toLowerCase().indexOf(filterProduct.toLowerCase()) !== -1
            })

            if (_product.length === 1) {
                tableProps.handleSelectProduct(_product[0])

                if (tableProps.qtyForwardedRef.current) {
                    tableProps.qtyForwardedRef.current.focus()
                    tableProps.qtyForwardedRef.current.select()
                }
                return
            }
        }
    }

    return <Card width="100%">
        <Box flexDirection='column' gap="1rem" width="100%">
            <Input
                mediaWidth='100%'
                width='40rem'
                placeholder='Filter Product Description / Model Number'
                value={filterProduct}
                onChange={e => setFilterProduct(e.target.value)}
                forwardedRef={tableProps.forwardedRef}
                onKeyPress={handleOnKeypress}
            />
            {
                tableProps.loading
                    ? <LoadingContainer height="23.5vh" />
                    : <Table
                        height="23.5vh"
                        header={
                            <>
                                <TH width="5%">ID</TH>
                                <TH>Product Description</TH>
                                <TH width="15%">Model Number</TH>
                                <TH width="5%" align='center'>Tax%</TH>
                                <TH width="8%" align='right'>Cost(MYR)</TH>
                                <TH width="3%"></TH>
                            </>
                        }
                        body={
                            <>
                                {
                                    tableProps.products.filter(row => {
                                        return (row.brand + " " + row.product).toLowerCase().indexOf(filterProduct.toLowerCase()) !== -1 ||
                                            row.model_no.toLowerCase().indexOf(filterProduct.toLowerCase()) !== -1
                                    }).map(row => {
                                        return <TR key={"product" + row.p_id} onDoubleClick={() => {
                                            tableProps.handleSelectProduct(row)

                                            if (tableProps.qtyForwardedRef.current) {
                                                tableProps.qtyForwardedRef.current.focus()
                                                tableProps.qtyForwardedRef.current.select()
                                            }
                                        }}>
                                            <TD>{row.p_id}</TD>
                                            <TD>{row.brand + " " + row.product}</TD>
                                            <TD>{row.model_no}</TD>
                                            <TD align='center'>{row.tax}</TD>
                                            <TD align='right'>{row.cost}</TD>
                                            <TD align='center'>
                                                <Box justifyContent='center' alignItems='center'>
                                                    <SelectIcon iconcolor='red' onClick={() => {
                                                        tableProps.handleSelectProduct(row)

                                                        if (tableProps.qtyForwardedRef.current) {
                                                            tableProps.qtyForwardedRef.current.focus()
                                                            tableProps.qtyForwardedRef.current.select()
                                                        }
                                                    }} />
                                                </Box>
                                            </TD>
                                        </TR>
                                    })
                                }
                            </>
                        }
                    />
            }

        </Box>
    </Card>
})

const DealerTable = React.memo((props: OrderTableProps) => {
    const [filter, setFilter] = useState("")

    const memoDetail = useMemo(() => {
        if (!filter) return props.detail

        return props.detail.filter(row => {
            return row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                row.model_no.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        })

    }, [filter, props.detail])

    const memoDealer = useMemo(() => {
        if (Array.isArray(memoDetail) && memoDetail.length > 0) {
            return memoDetail.filter((v, i, s) => {
                return s.map(el => el.l_id).indexOf(v.l_id) === i
            })
        }
        return []
    }, [memoDetail])

    const memoProduct = useMemo(() => {

        if (Array.isArray(memoDetail) && memoDetail.length > 0) {
            return memoDetail.filter((v, i, s) => {
                return s.map(el => el.p_id).indexOf(v.p_id) === i
            })
        }
        return []
    }, [memoDetail])

    return <Box flexDirection='column' gap="1rem">
        <Box justifyContent='space-between' alignItems='flex-end'>
            <Input width="30rem" mediaWidth='90%' placeholder='Filter product / model / dealer...' value={filter} onChange={e => setFilter(e.target.value)} />
            <Box gap="1rem">
                <Button color="blue"
                    onClick={() => {
                        if (props.info) {
                            let _doc = PdfDefinition.customStockOrder(props.info, props.detail)
                            return new PdfHandler(_doc).view()
                        }
                    }}
                >View</Button>
                <Button color="blue"
                    onClick={() => {
                        if (props.info) {
                            let _doc = PdfDefinition.customStockOrder(props.info, props.detail)
                            return new PdfHandler(_doc, props.info.running).download()
                        }
                    }}
                >Download</Button>
            </Box>
        </Box>
        <Table
            header={
                <>
                    <TH width="3%" align='center'>#</TH>
                    <TH>Product Description</TH>
                    <TH width="10%">Model No</TH>
                    {
                        memoDealer.map(row => {
                            return <TH align='center' key={"dealer" + row.l_id} width="10%">{row.location}</TH>
                        })
                    }
                    <TH width="3%"></TH>
                </>
            }
            body={
                <>
                    {
                        memoProduct.length === 0
                            ? <StaticTableRow row={10} column={4} />
                            : <>
                                {
                                    memoProduct.map((row, _) => {
                                        return <TR key={"summary" + row.p_id}>
                                            <TD align='center'>{_ + 1}</TD>
                                            <TD>{row.brand + " " + row.product}</TD>
                                            <TD>{row.model_no}</TD>
                                            {
                                                memoDealer.map(col => {
                                                    let _qty = memoDetail.reduce((total, el) => {
                                                        if (el.l_id === col.l_id && row.p_id === el.p_id) {
                                                            total += (el.qty * 1)
                                                        }
                                                        return total
                                                    }, 0)
                                                    return <TD align='center' key={"dealerCol" + col.l_id + row.p_id}>{!_qty ? "" : _qty * 1}</TD>
                                                })
                                            }
                                            <TD align='center'>
                                                {
                                                    memoDetail.reduce((total, el) => {
                                                        if (row.p_id === el.p_id) {
                                                            total += (el.qty * 1)
                                                        }
                                                        return total
                                                    }, 0)
                                                }
                                            </TD>
                                        </TR>
                                    })
                                }
                                {
                                    memoProduct.length < 10 &&
                                    <StaticTableRow row={10 - memoProduct.length} column={4 + memoDealer.length} />
                                }
                            </>
                    }
                    <TR>
                        <TD colSpan={3} align="right">Total</TD>
                        {
                            memoDealer.map(col => {
                                let _totalCol = memoDetail.reduce((total, row) => {
                                    if (col.l_id === row.l_id) {
                                        total += (row.qty * 1)
                                    }
                                    return total
                                }, 0)
                                return <TD align='center' key={"dealerLastRow" + col.l_id}>{_totalCol}</TD>
                            })
                        }
                        <TD align='center'>
                            {
                                memoDetail.reduce((total, row) => total + row.qty * 1, 0)
                            }
                        </TD>
                    </TR>
                </>
            }
        />
    </Box>
})

const SupplierTable = React.memo((props: OrderTableProps) => {
    const [filter, setFilter] = useState("")

    const memoDetail = useMemo(() => {
        if (!filter) return props.detail

        return props.detail.filter(row => {
            return row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                row.model_no.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        })

    }, [filter, props.detail])

    const memoSupplier = useMemo(() => {
        if (Array.isArray(memoDetail) && memoDetail.length > 0) {
            return memoDetail.filter((v, i, s) => {
                return s.map(el => el.s_id).indexOf(v.s_id) === i
            })
        }
        return []
    }, [memoDetail])

    const memoProduct = useMemo(() => {
        if (Array.isArray(memoDetail) && memoDetail.length > 0) {
            return memoDetail.filter((v, i, s) => {
                return s.map(el => el.p_id).indexOf(v.p_id) === i
            })
        }
        return []
    }, [memoDetail])

    return <Box flexDirection='column' gap="1rem">
        <Box justifyContent='space-between' alignItems='flex-end'>
            <Input width="30rem" mediaWidth='90%' placeholder='Filter product / model / supplier...' value={filter} onChange={e => setFilter(e.target.value)} />
            <Box gap="1rem">
                <Button color="blue"
                    onClick={() => {
                        if (props.info) {
                            let _doc = PdfDefinition.customStockOrderSupplier(props.info, props.detail)
                            return new PdfHandler(_doc).view()
                        }
                    }}
                >View</Button>
                <Button color="blue"
                    onClick={() => {
                        if (props.info) {
                            let _doc = PdfDefinition.customStockOrderSupplier(props.info, props.detail)
                            return new PdfHandler(_doc, props.info.running).download()
                        }
                    }}
                >Download</Button>
            </Box>
        </Box>
        <Table
            header={
                <>
                    <TH width="3%" align='center'>#</TH>
                    <TH>Product Description</TH>
                    <TH width="10%">Model No</TH>
                    {
                        memoSupplier.map((row, _) => {
                            return <TH align='center' key={"supplier_table" + row.s_id + _} width="10%">{row.supplier}</TH>
                        })
                    }
                    <TH width="3%"></TH>
                </>
            }
            body={
                <>
                    {
                        memoProduct.length === 0
                            ? <StaticTableRow row={10} column={4} />
                            : <>
                                {
                                    memoProduct.map((row, _) => {
                                        return <TR key={"summary" + row.p_id}>
                                            <TD align='center'>{_ + 1}</TD>
                                            <TD>{row.brand + " " + row.product}</TD>
                                            <TD>{row.model_no}</TD>
                                            {
                                                memoSupplier.map((col, __) => {
                                                    let _qty = memoDetail.reduce((total, el) => {
                                                        if (el.s_id === col.s_id && row.p_id === el.p_id) {
                                                            total += (el.qty * 1)
                                                        }
                                                        return total
                                                    }, 0)
                                                    return <TD align='center' key={"supplierCol" + col.s_id + row.p_id + __}>{!_qty ? "" : _qty * 1}</TD>
                                                })
                                            }
                                            <TD align='center'>
                                                {
                                                    memoDetail.reduce((total, el) => {
                                                        if (row.p_id === el.p_id) {
                                                            total += (el.qty * 1)
                                                        }
                                                        return total
                                                    }, 0)
                                                }
                                            </TD>
                                        </TR>
                                    })
                                }
                                {
                                    memoProduct.length < 10 &&
                                    <StaticTableRow row={10 - memoProduct.length} column={4 + memoSupplier.length} />
                                }
                            </>
                    }
                    <TR>
                        <TD colSpan={3} align="right">Total</TD>
                        {
                            memoSupplier.map((col, _) => {
                                let _totalCol = memoDetail.reduce((total, row) => {
                                    if (col.s_id === row.s_id) {
                                        total += (row.qty * 1)
                                    }
                                    return total
                                }, 0)
                                return <TD align='center' key={"supplierLastRow" + col.s_id + _}>{_totalCol}</TD>
                            })
                        }
                        <TD align='center'>
                            {
                                memoDetail.reduce((total, row) => total + row.qty * 1, 0)
                            }
                        </TD>
                    </TR>
                </>
            }
        />
    </Box>
})

const DetailTable = React.memo((props: OrderTableProps &
{
    handleDelete: (data: IStockOrderDetail) => void
    suppliers: ISupplier[]
    locations: ILocation[]
    setDetail: React.Dispatch<React.SetStateAction<IStockOrderDetail[]>>
    refData: React.MutableRefObject<IStockOrderDetail[]>
    so_id: string
}) => {
    const [filter, setFilter] = useState("")

    const { setAlert } = useAlert()
    const { token } = useLogin()

    const memoData = useMemo(() => {
        if (!filter) return props.detail

        return props.detail.filter(row => {
            return row.supplier.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                row.location.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                row.model_no.toLowerCase().indexOf(filter.toLowerCase()) !== -1
        })

    }, [filter, props.detail])

    const handleEdit = useCallback((_data: IStockOrderDetail) => {
        setAlert({
            show: true,
            message: "Update Item ?",
            type: "question",
            cb: async () => {
                try {
                    let update = await axios.patch("/stockOrderDetail/" + _data.so_detail_id, {
                        s_id: _data.s_id,
                        l_id: _data.l_id,
                        qty: _data.qty
                    })


                    if (update.status !== 200) {
                        setAlert({ show: true, message: update.data.message, type: "danger" })
                        return
                    }

                    return setAlert({
                        show: true,
                        message: "Item Updated Successfully !!!",
                        type: "success",
                        cb: async () => {
                            let _detail = await fetchStockOrderDetailByField(token, { so_id: props.so_id })
                            props.setDetail(_detail)
                            props.refData.current = JSON.parse(JSON.stringify(_detail))
                        }
                    })

                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }, [setAlert, token, props])

    return <Box flexDirection='column' gap="1rem">
        <Box justifyContent='space-between' alignItems='flex-end'>
            <Input width="30rem" mediaWidth='90%' placeholder='Filter product / model / supplier / dealer...' value={filter} onChange={e => setFilter(e.target.value)} />
            <Box gap="1rem">
                <Button color="blue"
                    onClick={() => {
                        if (props.info) {
                            let _doc = PdfDefinition.customStockOrderDetail(props.detail)
                            return new PdfHandler(_doc).view()
                        }
                    }}
                >View</Button>
                <Button color="blue"
                    onClick={() => {
                        if (props.info) {
                            let _doc = PdfDefinition.customStockOrderDetail(props.detail)
                            return new PdfHandler(_doc, props.info.running).download()
                        }
                    }}
                >Download</Button>
            </Box>
        </Box>
        <Table
            mediaScreen={true}
            header={
                <>
                    <TH width="3%" align="center">#</TH>
                    <TH width="10%">Supplier</TH>
                    <TH width="10%">Dealer</TH>
                    <TH>Product Description</TH>
                    <TH width="8%">Model No</TH>
                    <TH width="4%" align='center'>Qty</TH>
                    <TH width="4%" align='center'>Changed</TH>
                    <TH width="3%"></TH>
                </>
            }
            body={
                <>
                    {
                        memoData.length === 0
                            ? <StaticTableRow row={10} column={8} />
                            : <>
                                {
                                    memoData.map((row, _) => {
                                        return <TR key={"detail" + row.so_detail_id}>
                                            <TD align='center'>{_ + 1}</TD>
                                            <TD>
                                                <Select 
                                                mediaWidth='6rem'
                                                width='100%'
                                                    value={row.s_id as unknown as string}
                                                    onChange={e => {
                                                        let newData = [...props.detail]
                                                        let index = newData.map(el => el.so_detail_id).indexOf(row.so_detail_id)
                                                        newData[index].s_id = e.target.value as unknown as number
                                                        props.setDetail(newData)
                                                    }}
                                                >
                                                    {
                                                        props.suppliers.map(el => {
                                                            return <option key={"details_suppliers" + row.so_detail_id + el.s_id} value={el.s_id}>{el.supplier}</option>
                                                        })
                                                    }
                                                </Select>
                                            </TD>
                                            <TD>
                                                <Select 
                                                mediaWidth='6rem'
                                                width='100%'
                                                    value={row.l_id as unknown as string}
                                                    onChange={e => {
                                                        let newData = [...props.detail]
                                                        let index = newData.map(el => el.so_detail_id).indexOf(row.so_detail_id)
                                                        newData[index].l_id = e.target.value as unknown as number
                                                        props.setDetail(newData)
                                                    }}
                                                >
                                                    {
                                                        props.locations.map(el => {
                                                            return <option key={"details_locations" + row.so_detail_id + el.l_id} value={el.l_id}>{el.location}</option>
                                                        })
                                                    }
                                                </Select>
                                            </TD>
                                            <TD>{row.brand + " " + row.product}</TD>
                                            <TD>{row.model_no}</TD>
                                            <TD align='center'>
                                                <Input 
                                                align="center" 
                                                width="100%" 
                                                mediaWidth='3rem'
                                                value={row.qty as unknown as string} 
                                                onChange={e => {
                                                    let newData = [...props.detail]
                                                    let index = newData.map(el => el.so_detail_id).indexOf(row.so_detail_id)
                                                    newData[index].qty = e.target.value as unknown as number
                                                    props.setDetail(newData)
                                                }} />
                                            </TD>
                                            <TD align='center'>
                                                {
                                                    (
                                                        props.refData.current.filter(el => el.so_detail_id === row.so_detail_id)[0].s_id.toString() !== row.s_id.toString() ||
                                                        props.refData.current.filter(el => el.so_detail_id === row.so_detail_id)[0].l_id.toString() !== row.l_id.toString() ||
                                                        props.refData.current.filter(el => el.so_detail_id === row.so_detail_id)[0].qty.toString() !== row.qty.toString()
                                                    ) &&
                                                    <Box alignItems='center' justifyContent='center'>
                                                        <EditIcon iconcolor='blue' onClick={() => handleEdit(row)} />
                                                    </Box>
                                                }
                                            </TD>
                                            <TD align='center'>
                                                <Box justifyContent='center' alignItems='center'>
                                                    <DeleteIcon iconcolor='red' onClick={() => props.handleDelete(row)} />
                                                </Box>
                                            </TD>
                                        </TR>
                                    })
                                }
                                {
                                    memoData.length < 10 &&
                                    <StaticTableRow row={10 - memoData.length} column={8} />
                                }
                            </>
                    }
                    <TR>
                        <TD colSpan={5} align="right">Total</TD>
                        <TD align="center">{memoData.reduce((total, row) => total + row.qty * 1, 0)}</TD>
                        <TD align="center"></TD>
                        <TD align="center"></TD>
                    </TR>
                </>
            }
        />
    </Box>
})

const PopModal = React.memo((modalProps: PopModalProps) => {
    const [filterModal, setFilterModal] = useState("")

    const modalInputRef = useRef<HTMLInputElement>(null)

    const handleModalInputOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            if (modalProps.showModal.data === "location") {
                let _location = modalProps.locations.filter(row => row.location.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1)

                if (_location.length !== 1) return

                modalProps.handleSelectLocation(_location[0])
                setFilterModal("")
                return
            }

            if (modalProps.showModal.data === "supplier") {
                let _supplier = modalProps.suppliers.filter(row => row.supplier.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1)

                if (_supplier.length !== 1) return

                modalProps.handleSelectSupplier(_supplier[0])
                setFilterModal("")
                return
            }
        }
    }

    useEffect(() => {
        if (modalInputRef.current && modalProps.showModal.show) {
            let timeout = setTimeout(() => {
                modalInputRef.current?.focus()
            }, 100)

            return () => clearTimeout(timeout)
        }
    }, [modalProps.showModal.show])

    return <Modal
        width="20%"
        title={(modalProps.showModal.data && modalProps.showModal.data[0].toUpperCase() + modalProps.showModal.data.slice(1)) + ' List'}
        show={modalProps.showModal.show}
        closeModal={() => modalProps.setShowModal(v => ({ ...v, show: !v.show }))}
        clickOutside
    >
        <Box flexDirection='column' gap="1rem">
            <Input
                width="100%"
                placeholder='Search'
                value={filterModal}
                onChange={e => setFilterModal(e.target.value)}
                forwardedRef={modalInputRef}
                onKeyPress={handleModalInputOnKeyPress}
            />
            <Table
                height="50vh"
                header={
                    <>
                        <TH>ID</TH>
                        <TH>{modalProps.showModal.data}</TH>
                    </>
                }
                body={
                    <>
                        {
                            modalProps.showModal.data && (
                                modalProps.showModal.data === "location"
                                    ? modalProps.locations.filter(row => row.location.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1).map((row, _) => {
                                        return <TR key={"modal_location" + row.l_id + _} onDoubleClick={() => {
                                            modalProps.handleSelectLocation(row)
                                            setFilterModal("")
                                        }}>
                                            <TD>{row.l_id}</TD>
                                            <TD>{row.location}</TD>
                                        </TR>
                                    })
                                    : modalProps.suppliers.filter(row => row.supplier.toLowerCase().indexOf(filterModal.toLowerCase()) !== -1).map((row, _) => {
                                        return <TR key={"modal_supplier" + row.s_id + _} onDoubleClick={() => {
                                            modalProps.handleSelectSupplier(row)
                                            setFilterModal("")
                                        }}>
                                            <TD>{row.s_id}</TD>
                                            <TD>{row.supplier}</TD>
                                        </TR>
                                    })
                            )
                        }
                    </>
                }
            />
        </Box>
    </Modal>
})

const KivModal = React.memo((props: { showModal: boolean, setShowModal: React.Dispatch<React.SetStateAction<boolean>>, data: IStockOrder[] }) => {
    let history = useNavigate()

    return <Modal
        title="Kiv Stock Order Doc."
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        width="60%"
        clickOutside
    >
        <Box flexDirection='column' gap="1rem">
            <Table
                height="50vh"
                header={
                    <>
                        <TH width="5%">ID</TH>
                        <TH>Date</TH>
                        <TH>Running</TH>
                        <TH>Invoice Header</TH>
                        <TH>Permit Holder</TH>
                        <TH width="5%"></TH>
                    </>
                }
                body={
                    <>
                        {
                            props.data
                                .filter(row => !row.invoice_id && new Date(row.date_time).getFullYear() === new Date().getFullYear())
                                .sort((a, b) => b.so_id - a.so_id)
                                .map((row) => {
                                    return <TR key={"stockOrder" + row.so_id}>
                                        <TD>{row.so_id}</TD>
                                        <TD>{new Date(row.date_time).toLocaleDateString("en-GB")}</TD>
                                        <TD>{row.running}</TD>
                                        <TD>{row.supplier}</TD>
                                        <TD>{row.location}</TD>
                                        <TD align='center'>
                                            <Box justifyContent='center' alignItems='center'>
                                                <SelectIcon iconcolor='red' onClick={() => {
                                                    history("/orderForm/" + row.so_id)
                                                    props.setShowModal(false)
                                                }} />
                                            </Box>
                                        </TD>
                                    </TR>
                                })
                        }
                    </>
                }
            />
        </Box>
    </Modal>
})

const OrderForm = () => {
    let history = useNavigate()
    const { so_id } = useParams()

    const [reload, setReload] = useState(true)
    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState<IStockOrder | null>(null)
    const [detail, setDetail] = useState<IStockOrderDetail[]>([])
    const [suppliers, setSuppliers] = useState<ISupplier[]>([])
    const [locations, setLocations] = useState<ILocation[]>([])
    const [products, setProducts] = useState<IProduct[]>([])

    const [supplier, setSupplier] = useState(initialSupplier)
    const [dealer, setDealer] = useState(initialDealer)
    const [product, setProduct] = useState(initialProduct)
    const [qty, setQty] = useState("")

    const [stockOrders, setStockOrders] = useState<IStockOrder[]>([])
    const [showModal, setShowModal] = useState(initialModal)
    const [showKivModal, setShowKivModal] = useState(false)

    const [addingOrder, setAddingOrder] = useState(false)

    const { token, setToken } = useLogin()
    const { setAlert } = useAlert()

    const filterProductInputRef = useRef<HTMLInputElement>(null)
    const qtyInputRef = useRef<HTMLInputElement>(null)

    const refData = useRef<IStockOrderDetail[]>([])

    const fetchData = useCallback(async () => {
        try {
            if (so_id) {
                setLoading(true)
                let [_info, _detail, _supplier, _location, _product, _stockOrders] = await Promise.all([
                    fetchStockOrderById(token, so_id),
                    fetchStockOrderDetailByField(token, { so_id }),
                    fetchSupplier(token),
                    fetchLocation(token),
                    fetchProduct(token),
                    fetchStockOrder(token)
                ])
                setInfo(_info)
                setDetail(_detail)
                setSuppliers(_supplier.filter(row => row.type === "order"))
                setLocations(_location.filter(row => row.type === "dealer" && row.status === "active"))
                setProducts(_product.filter(row => row.status === "active"))
                setStockOrders(_stockOrders)
                refData.current = JSON.parse(JSON.stringify(_detail))
                setLoading(false)
            }
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                ok: err.message === "fetch timeout" ? "Click to Reload" : "Click To Login",
                type: "danger",
                cb: () => {
                    if (err.message === "fetch timeout") {
                        return setReload(!reload)
                    }
                    return setToken("")
                }
            })
        }
    }, [so_id, token, setAlert, setToken, reload])

    const handleSelectSupplier = useCallback((_data: ISupplier) => {
        setSupplier({
            s_id: _data.s_id,
            supplier: _data.supplier
        })
        setShowModal(v => ({ ...v, data: "location" }))
    }, [])

    const handleSelectLocation = useCallback((_data: ILocation) => {
        setDealer({
            l_id: _data.l_id,
            location: _data.location
        })
        setShowModal(v => ({ ...v, show: !v.show }))

        if (filterProductInputRef.current) {
            filterProductInputRef.current.focus()
            filterProductInputRef.current.select()
        }
    }, [])

    const handleSelectProduct = useCallback((_data: IProduct) => {
        setProduct({
            p_id: _data.p_id,
            product: _data.brand + " " + _data.product
        })
    }, [])

    const handleQtyKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13 && !addingOrder) {
            handleAddOrder()
        }
    }

    const handleAddOrder = () => {
        if (!supplier.s_id) return setAlert({ show: true, message: "Please select supplier...", type: "danger" })
        if (!dealer.l_id) return setAlert({ show: true, message: "Please select dealer...", type: "danger" })
        if (!product.p_id) return setAlert({ show: true, message: "Please select product...", type: "danger" })
        if (isNaN(Number(qty)) || qty === "") {
            return setAlert({ show: true, message: "Invalid quantity...", type: "danger" })
        }

        setAlert({
            show: true,
            message: <Box flexDirection='column' gap="1rem" alignItems='flex-start' width="100%">
                <Box>Add Order</Box>
                <Box width="100%">
                    <Box width="6rem">Supplier :</Box>
                    <Box>{supplier.supplier}</Box>
                </Box>
                <Box width="100%">
                    <Box width="6rem">Dealer :</Box>
                    <Box>{dealer.location}</Box>
                </Box>
                <Box width="100%">
                    <Box width="6rem">Product :</Box>
                    <Box width="19rem">{product.product}</Box>
                </Box>
                <Box width="100%">
                    <Box width="6rem">Quantity :</Box>
                    <Box>{qty}</Box>
                </Box>
            </Box>,
            type: "question",
            cb: async () => {
                setAddingOrder(true)
                try {
                    let result = await axios.post("/stockOrderDetail", {
                        so_id: so_id,
                        l_id: dealer.l_id,
                        s_id: supplier.s_id,
                        p_id: product.p_id,
                        qty: qty
                    })

                    if (result.status !== 201) {
                        return setAlert({
                            show: true,
                            message: result.data.message,
                            type: "danger"
                        })
                    }

                    setAlert({
                        show: true,
                        message: "Added Successfully !!!",
                        type: "success",
                        cb: async () => {
                            let _detail = await fetchStockOrderDetailByField(token, { so_id: so_id! })
                            setDetail(_detail)
                            refData.current = JSON.parse(JSON.stringify(_detail))
                            if (filterProductInputRef.current) {
                                filterProductInputRef.current.focus()
                                filterProductInputRef.current.select()
                            }
                            setAddingOrder(false)
                        }
                    })
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }

    const handleDelete = useCallback((_data: IStockOrderDetail) => {
        setAlert({
            show: true,
            message: <Box flexDirection='column' gap="1rem" alignItems='flex-start' width="100%">
                <Box>Delete Order</Box>
                <Box width="100%">
                    <Box width="6rem">Supplier :</Box>
                    <Box>{_data.supplier}</Box>
                </Box>
                <Box width="100%">
                    <Box width="6rem">Dealer :</Box>
                    <Box>{_data.location}</Box>
                </Box>
                <Box width="100%">
                    <Box width="6rem">Product :</Box>
                    <Box width="19rem">{_data.brand + " " + _data.product}</Box>
                </Box>
                <Box width="100%">
                    <Box width="6rem">Quantity :</Box>
                    <Box>{_data.qty}</Box>
                </Box>
            </Box>,
            type: "question",
            cb: async () => {
                try {
                    let result = await axios.delete("/stockOrderDetail/" + _data.so_detail_id)

                    if (result.status !== 200) {
                        return setAlert({
                            show: true,
                            message: result.data.message,
                            type: "danger"
                        })
                    }

                    setAlert({
                        show: true,
                        message: "Product Deleted Successfully !!!",
                        type: "success",
                        cb: () => {
                            setDetail(v => {
                                refData.current = JSON.parse(JSON.stringify(v.filter(row => row.so_detail_id !== _data.so_detail_id)))
                                return v.filter(row => row.so_detail_id !== _data.so_detail_id)
                            })
                        }
                    })
                } catch (err: any) {
                    console.log(err)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger",
                        cb: () => {
                            return setToken("")
                        }
                    })
                }
            }
        })
    }, [setAlert, setToken])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb
                items={breadCrumb}
                button={[
                    {
                        name: "Kiv Doc.",
                        color: "red",
                        action: () => setShowKivModal(true)
                    },
                    {
                        name: "Reload Suppliers",
                        color: "blue",
                        action: async () => {
                            let result = await fetchSupplier(token)
                            setSuppliers(result.filter(row => row.type === "order"))
                        }
                    },
                    {
                        name: "Reload Dealers",
                        color: "blue",
                        action: async () => {
                            let result = await fetchLocation(token)
                            setLocations(result.filter(row => row.type === "dealer" && row.status === "active"))
                        }
                    },
                    {
                        name: "Reload Products",
                        color: "blue",
                        action: async () => {
                            let result = await fetchProduct(token)
                            setProducts(result.filter(row => row.status === "active"))
                        }
                    },
                    {
                        name: "Create Invoice",
                        color: "green",
                        action: () => {
                            history("/invoice/new", {
                                state: {
                                    so_id: so_id
                                }
                            })
                        }
                    },
                ]}
            />
            <PageContent>
                <StyledWholeBox>
                    <StyledTopBox>
                        <Card mediaWidth='100%'>
                            <CardHeader border>Order # {info?.running}</CardHeader>
                            <CardBody>
                                <Box flexDirection='column' gap="2rem">

                                    <Input
                                        mediaWidth='100%'
                                        width='30rem'
                                        iconType="add"
                                        disableInput
                                        placeholder='Find supplier...'
                                        onClick={() => {
                                            setShowModal(v => ({ show: !v.show, data: "supplier" }))
                                        }}
                                        value={supplier.supplier}
                                        readOnly
                                    />

                                    <Input
                                        mediaWidth='100%'
                                        width='30rem'
                                        iconType="add"
                                        disableInput
                                        placeholder='Find dealer...'
                                        onClick={() => setShowModal(v => ({ show: !v.show, data: "location" }))}
                                        value={dealer.location}
                                        readOnly
                                    />

                                    <Input
                                        mediaWidth='100%'
                                        width='30rem'
                                        disableInput
                                        placeholder='Product Description...'
                                        value={product.product}
                                        readOnly
                                    />

                                    <Input
                                        mediaWidth='100%'
                                        width='30rem'
                                        placeholder='Insert quantity...'
                                        value={qty}
                                        onChange={e => setQty(e.target.value)}
                                        forwardedRef={qtyInputRef}
                                        onKeyPress={handleQtyKeypress}
                                    />
                                </Box>

                            </CardBody>
                            <CardFooter border>
                                <Box justifyContent='flex-end'>
                                    <Button color="green" onClick={handleAddOrder}>Submit</Button>
                                </Box>
                            </CardFooter>
                        </Card>
                        <ProductTable
                            products={products}
                            handleSelectProduct={handleSelectProduct}
                            forwardedRef={filterProductInputRef}
                            qtyForwardedRef={qtyInputRef}
                            loading={loading}
                        />
                    </StyledTopBox>

                    <Box>
                        <Card width="100%">
                            <Tabs
                                minHeight='46vh'
                                tabs={[
                                    {
                                        tab: "DETAIL",
                                        content: <DetailTable
                                            info={info ? info : undefined}
                                            detail={detail}
                                            suppliers={suppliers}
                                            locations={locations}
                                            setDetail={setDetail}
                                            handleDelete={handleDelete}
                                            refData={refData}
                                            so_id={so_id!}
                                        />
                                    },
                                    {
                                        tab: "DEALER",
                                        content: <DealerTable info={info ? info : undefined} detail={detail} />
                                    },
                                    {
                                        tab: "SUPPLIER",
                                        content: <SupplierTable info={info ? info : undefined} detail={detail} />
                                    },
                                ]}
                            />
                        </Card>
                    </Box>
                </StyledWholeBox>
            </PageContent>

            <PopModal
                suppliers={suppliers}
                locations={locations}
                showModal={showModal}
                setShowModal={setShowModal}
                handleSelectLocation={handleSelectLocation}
                handleSelectSupplier={handleSelectSupplier}
            />

            <KivModal
                showModal={showKivModal}
                setShowModal={setShowKivModal}
                data={stockOrders}
            />
        </>
    )
}

export default OrderForm