import { API_URL } from "../helpers/apiUrl";
import { 
	IBrand, 
	IInvoice, 
	IInvoiceDetail, 
	ILocation, 
	IProduct, 
	IStockOrder, 
	IStockOrderDetail, 
	ISupplier, IDuty, IDutyDetail, IDutyBill
} from "../types/types";

const defaultConfig = (token?: string) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    credentials: "include",
  } as RequestInit;
};

const fetchData = async <T>(token: string, api: string) => {
	const controller = new AbortController();

	const timeout = setTimeout(() => {
		controller.abort();
	}, 10000);

	try {
		let result = await fetch(API_URL + api, {
		...defaultConfig(token),
		signal: controller.signal,
		});

		if(result.status !== 200) {
			throw new Error(result.statusText)
		}

		let json = await result.json();

		return json as T;
	} catch (err: any) {
		if (err.name === "AbortError") {
			throw new Error(`fetch timeout`);
		} else {
			throw new Error(err);
		}
	} finally {
		clearTimeout(timeout);
	}
};

export const fetchBrand = async (token: string) => {
	try{
		return await fetchData<IBrand[]>(token, "/brand")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchBrandById = async (token: string, id: number | string) => {
	try{
		return await fetchData<IBrand>(token, "/brand/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchProduct = async (token: string) => {
	try {
		return await fetchData<IProduct[]>(token, "/product")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchProductById = async (token: string, id: number | string) => {
	try{
		return await fetchData<IProduct>(token, "/product/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchSupplier = async (token: string) => {
	try{
		return await fetchData<ISupplier[]>(token, "/supplier")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchSupplierById = async (token: string, id: number | string) => {
	try{
		return await fetchData<ISupplier>(token, "/supplier/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchLocation = async (token: string) => {
	try{
		return await fetchData<ILocation[]>(token, "/location")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchLocationById = async (token: string, id: number | string) => {
	try{
		return await fetchData<ILocation>(token, "/location/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchStockOrder = async (token: string) => {
	try {
		return await fetchData<IStockOrder[]>(token, "/stockOrder")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchStockOrderById = async (token: string, id: number | string) => {
	try{
		return await fetchData<IStockOrder>(token, "/stockOrder/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchStockOrderDetail = async (token: string) => {
	try{
		return await fetchData<IStockOrderDetail[]>(token, "/stockOrderDetail")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchStockOderDetailById = async (token: string, id: number | string) => {
	try{
		return await fetchData<IStockOrderDetail>(token, "/stockOrderDetail/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchStockOrderDetailByField = async (token: string, obj: { [key: string]: string | number }) => {
	try {
		return await fetchData<IStockOrderDetail[]>(token, `/stockOrderDetail?${Object.keys(obj)[0]}=` + Object.values(obj)[0])
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchInvoice = async (token: string) => {
	try {
		return await fetchData<IInvoice[]>(token, "/invoice")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchInvoiceById = async (token: string, id: number | string) => {
	try{
		return await fetchData<IInvoice>(token, "/invoice/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchInvoiceByField = async (token: string, obj: { [key: string]: string | number }) => {
	try {
		return await fetchData<IInvoice[]>(token, `/invoice?${Object.keys(obj)[0]}=` + Object.values(obj)[0])
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchInvoiceDetail = async (token: string) => {
	try{
		return await fetchData<IInvoiceDetail[]>(token, "/invoiceDetail")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchInvoiceDetailByField = async (token: string, obj: { [key: string]: string | number }) => {
	try {
		return await fetchData<IInvoiceDetail[]>(token, `/invoiceDetail?${Object.keys(obj)[0]}=` + Object.values(obj)[0])
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchDuty = async (token: string) => {
	try {
		return await fetchData<IDuty[]>(token, "/duty")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchDutyById = async (token: string, id: number | string) => {
	try{
		return await fetchData<IDuty>(token, "/duty/" + id)
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchDutyDetail = async (token: string) => {
	try{
		return await fetchData<IDutyDetail[]>(token, "/dutyDetail")
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchDutyDetailByField = async (token: string, obj: { [key: string]: string | number }) => {
	try {
		return await fetchData<IDutyDetail[]>(token, `/dutyDetail?${Object.keys(obj)[0]}=` + Object.values(obj)[0])
	} catch (err: any) {
		throw new Error(err.message)
	}
};

export const fetchDutyBill = async (token: string) => {
	try{
		return await fetchData<IDutyBill[]>(token, "/dutyBill")
	} catch (err: any) {
		throw new Error(err.message)
	}
};