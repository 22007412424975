import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ColorModeState } from './contexts/colorModeContext/ColorModeState';
import LoginState from './contexts/loginContext/LoginState';
import AlertState from './contexts/alertContext/AlertState';
import SidebarState from './contexts/sidebarContext/SidebarState';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<AlertState>
		<LoginState>
			<SidebarState>
				<ColorModeState>
					{/* <React.StrictMode> */}
					<App />
					{/* </React.StrictMode> */}
				</ColorModeState>
			</SidebarState>
		</LoginState>
	</AlertState>
);

