import React from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface Props {
    children?: React.ReactNode
    onClick?: () => void
    color: "blue" | "green" | "red"
}

const switchColor = (props: ThemePalleteProps, color?: Props["color"]) => {
    switch(color) {
        case "blue":
            return props.pallete?.blue["500"];
        case "red":
            return props.pallete?.red["600"];
        case "green":
            return props.pallete?.green["500"];
        default:
            return null;
    }
}

const StyledButton = styled.button<ThemePalleteProps & { color: "blue" | "green" | "red" }>`
    box-sizing: border-box;
    margin: 0;
    padding: calc(${props => props.themeSetting?.spacing.sm}rem / 4) calc(${props => props.themeSetting?.spacing.sm}rem / 2);
    cursor: pointer;
    font-weight: 600;
    background-color: ${props => switchColor(props, props.color)};
    color: #ffffff;
    border: none;
    outline: none;
    border-radius: 0.3rem;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
    font-size: calc(${props => props.themeSetting?.typography.fontSize}rem * 0.9);

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        padding: calc(${props => props.themeSetting?.spacing.sm}rem / 5) calc(${props => props.themeSetting?.spacing.sm}rem / 3);
        font-size: ${props => props.themeSetting?.typography.media}rem;
    }

`

const BreadcrumbButton = (props: Props) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledButton onClick={props.onClick} themeSetting={theme} pallete={pallete} color={props.color}>{props.children}</StyledButton>
    )
}

export default BreadcrumbButton