import React from 'react'
import { TD, TR } from './Table'

interface Props {
    row: number
    column: number
}

const StaticTableRow = (props: Props) => {
    const _row = Array.from({ length: props.row }, (_, k) => k)
    const _column = Array.from({ length: props.column }, (_, k) => k)

    return (
        <>
            {
                _row.map((_, i) => {
                    return <TR key={"emptyRow" + i}>
                        {
                            _column.map((_, j) => {
                                return j === 0 ? <TD key={"emptyColumn" + j}>&nbsp;</TD> : <TD key={"emptyColumn" + j} />
                            })
                        }
                    </TR>
                })
            }
        </>
    )
}

export default StaticTableRow