import React from 'react'
import { AiOutlineEdit, AiOutlineSetting, AiOutlineSelect, AiOutlineDownload } from 'react-icons/ai'
import { BiReset, BiTrash } from 'react-icons/bi'
import styled from 'styled-components'
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface IconProps {
    iconsize?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
    iconcolor?: "red" | "blue" | "green"
    onClick?: React.MouseEventHandler<SVGAElement>
}

const switchColor = (props: ThemePalleteProps, color?: IconProps["iconcolor"]) => {
    switch(color) {
        case "blue":
            return props.themesetting?.pallete.primary.main;
        case "red":
            return props.themesetting?.pallete.tertiery.main;
        case "green":
            return props.themesetting?.pallete.secondary.main;
        default:
            return null;
    }
}

const StyledEdit = styled(AiOutlineEdit)<ThemePalleteProps & IconProps>`
    cursor: pointer;
    color: ${props => switchColor(props, props.iconcolor)};
    font-size: ${props => props.themesetting?.typography[props.iconsize ? props.iconsize : "h6"].fontSize}rem;
    margin: 0 0.5rem;

    &:active {
        opacity: 0.6;
    }
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem / 1.6);
        margin: 0 0.25rem;
    }
`

const StyledDelete = styled(BiTrash)<ThemePalleteProps & IconProps>`
    cursor: pointer;
    color: ${props => switchColor(props, props.iconcolor)};
    font-size: ${props => props.themesetting?.typography[props.iconsize ? props.iconsize : "h6"].fontSize}rem;
    margin: 0 0.5rem;

    &:active {
        opacity: 0.6;
    }
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem / 1.6);
        margin: 0 0.25rem;
    }
`

const StyledReset = styled(BiReset)<ThemePalleteProps & IconProps>`
    cursor: pointer;
    color: ${props => switchColor(props, props.iconcolor)};
    font-size: ${props => props.themesetting?.typography[props.iconsize ? props.iconsize : "h6"].fontSize}rem;
    margin: 0 0.5rem;

    &:active {
        opacity: 0.6;
    }
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem / 1.6);
        margin: 0 0.25rem;
    }
`

const StyledSetting = styled(AiOutlineSetting)<ThemePalleteProps & IconProps>`
    cursor: pointer;
    color: ${props => switchColor(props, props.iconcolor)};
    font-size: ${props => props.themesetting?.typography[props.iconsize ? props.iconsize : "h6"].fontSize}rem;
   margin: 0 0.5rem;

    &:active {
        opacity: 0.6;
    }
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem / 1.6);
        margin: 0 0.25rem;
    }
`

const StyledSelect = styled(AiOutlineSelect)<ThemePalleteProps & IconProps>`
    cursor: pointer;
    color: ${props => switchColor(props, props.iconcolor)};
    font-size: ${props => props.themesetting?.typography[props.iconsize ? props.iconsize : "h6"].fontSize}rem;
    margin: 0 0.5rem;

    &:active {
        opacity: 0.6;
    }
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem / 1.6);
        margin: 0 0.25rem;
    }

`
const StyledDownload = styled(AiOutlineDownload)<ThemePalleteProps & IconProps>`
    cursor: pointer;
    color: ${props => switchColor(props, props.iconcolor)};
    font-size: ${props => props.themesetting?.typography[props.iconsize ? props.iconsize : "h6"].fontSize}rem;
    margin: 0 0.5rem;
    display: inline-block;

    &:active {
        opacity: 0.6;
    }
    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: calc(${props => props.themesetting?.typography.fontSize}rem / 1.6);
        margin: 0 0.25rem;
    }
`

const EditIcon = (props: IconProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledEdit className='icon' themesetting={theme} pallete={pallete} {...props} />
    )
}

const DeleteIcon = (props: IconProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledDelete className='icon' themesetting={theme} pallete={pallete} {...props} />
    )
    
}

const ResetIcon = (props: IconProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledReset className='icon' themesetting={theme} pallete={pallete} {...props} />
    )
}

const SettingIcon = (props: IconProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledSetting className='icon' themesetting={theme} pallete={pallete} {...props} />
    )
}

const SelectIcon = (props: IconProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledSelect className='icon' themesetting={theme} pallete={pallete} {...props} />
    )
}

const DownloadIcon = (props: IconProps) => {
    const {theme, pallete} = useThemeSetting()

    return (
        <StyledDownload className='icon' themesetting={theme} pallete={pallete} {...props} />
    )
}



export { EditIcon, DeleteIcon, ResetIcon, SettingIcon, SelectIcon, DownloadIcon }