import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ThemePalleteProps } from './types/types';
import styled from 'styled-components';
import useThemeSetting from './hooks/useThemeSetting';
import Topbar, { TOPBAR_HEIGHT, TOPBAR_HEIGHT_MEDIA } from './components/Topbar';
import Sidebar, { SIDEBAR_WIDTH } from './components/Sidebar';
import Login from './pages/Login';
import Home from './pages/Home';
import BrandList from './pages/BrandList';
import useLogin from './hooks/useLogin';
import ProductList from './pages/ProductList';
import Brand from './pages/Brand';
import Product from './pages/Product';
import Alert from './components/Alert';
import AuthContainer from './components/AuthContainer';
import SupplierList from './pages/SupplierList';
import Supplier from './pages/Supplier';
import LocationList from './pages/LocationList';
import Location from './pages/Location';
import StockOrderList from './pages/StockOrderList';
import OrderForm from './pages/OrderForm';
import StockOrder from './pages/StockOrder';
import StockOrderDetailList from './pages/StockOrderDetailList';
import InvoiceList from './pages/InvoiceList';
import Invoice from './pages/Invoice';
import DutyList from './pages/DutyList';
import Duty from './pages/Duty';
import DutyBillList from './pages/DutyBillList';
import { ThemeType } from './colorPallete';


const FullContainer = styled.div<ThemePalleteProps>`
	box-sizing: border-box;
	display: block;
	height: 100vh;
	width: 100%;

	font-size: ${props => props.themeSetting?.typography.fontSize}rem;
	color: ${props => props.themeSetting?.pallete.font.main};

	@media (max-width: 768px) {
		font-size: ${props => props.themeSetting?.typography.media}rem;
	}
`

const BottomContainer = styled.div<ThemePalleteProps>`
	position: relative;
	display: flex;
	box-sizing: border-box;
	height: calc(100vh - ${TOPBAR_HEIGHT});
	width: 100%;
	background-color: ${props => props.themeSetting?.pallete.background.light};

	@media (max-width: 768px) {
		height: calc(100vh - ${TOPBAR_HEIGHT_MEDIA});
	}
`

const ContentContainer = styled.div<ThemePalleteProps>`
	box-sizing: border-box;
	width: 100%;
	flex: 1;
	padding: ${props => props.themeSetting?.spacing.sm}rem;
	padding-top: 0.5rem;
	padding-left: calc(${props => props.themeSetting?.spacing.sm}rem + ${SIDEBAR_WIDTH});
	color: ${props => props.themeSetting?.pallete.font.light};
	overflow-y: scroll;

	
	@media (max-width: 768px) {
		padding-left: calc(${props => props.themeSetting?.spacing.sm}rem * 0.5);
		padding-bottom: calc(${props => props.themeSetting?.spacing.sm}rem * 0.5);
		padding-right: calc(${props => props.themeSetting?.spacing.sm}rem * 0.5);
	}
`

const AuthLayout = React.memo((props: {
	token: string
	theme: ThemeType
}) => {
	return props.token
	? <>
		<Topbar />
		<BottomContainer className="bottomContainer" themeSetting={props.theme}>
			<Sidebar />
			<ContentContainer themeSetting={props.theme}>
				<Outlet />
			</ContentContainer>
		</BottomContainer>
	</>
	: <Outlet />
})

const IsLogout = React.memo((props: {
	token: string
	auth: boolean
}) => {
	return !props.token && !props.auth
	? <Login />
	: <Navigate to="/home" replace />
})

const IsLogin = React.memo((props: {
	auth: boolean
	token: string
}) => {
	return !props.token && !props.auth 
	? <Navigate to="/login" replace />
	: <Outlet />
})

function App() {
	const { theme, pallete } = useThemeSetting()
	const { auth, token } = useLogin()

	if(auth) return <AuthContainer />

	return (
		<BrowserRouter>
			<FullContainer themeSetting={theme} pallete={pallete}>
				<Alert />
				<Routes>
					<Route path="/" element={<Navigate to="/home" replace />} />

					<Route element={<AuthLayout token={token} theme={theme} />}>
						<Route path="login" element={<IsLogout token={token} auth={auth} />} />

						<Route element={<IsLogin token={token} auth={auth} />}>
							<Route path="home" element={<Home />} />
							<Route path="brand" element={<BrandList />} />
							<Route path="product" element={<ProductList />} />
							<Route path="supplier" element={<SupplierList />} />
							<Route path="location" element={<LocationList />} />
							<Route path="stockOrder" element={<StockOrderList />} />
							<Route path="stockOrderDetail" element={<StockOrderDetailList />} />
							<Route path="invoice" element={<InvoiceList />} />
							<Route path="duty" element={<DutyList />} />
							<Route path="brand/:b_id" element={<Brand />} />
							<Route path="product/:p_id" element={<Product />} />
							<Route path="supplier/:s_id" element={<Supplier />} />
							<Route path="location/:l_id" element={<Location />} />
							<Route path="orderForm/:so_id" element={<OrderForm />} />
							<Route path="stockOrder/:so_id" element={<StockOrder />} />
							<Route path="invoice/:invoice_id" element={<Invoice />} />
							<Route path="duty/dutyBill" element={<DutyBillList />} />
							<Route path="duty/:duty_id" element={<Duty />} />
						</Route>
					</Route>
				</Routes>
			</FullContainer>
		</BrowserRouter>
	);
}

export default App;
