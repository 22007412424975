import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardFooter, CardHeader } from '../components/Card'
import Input from '../components/Input'
import { IconGroupContainer } from '../components/ManageTable'
import Modal from '../components/Modal'
import PageContent from '../components/PageContent'
import Select from '../components/Select'
import { Table, TD, TH, TR } from '../components/Table'
import { fetchProductById, fetchBrand, fetchProduct } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'
import { ResetIcon, SelectIcon } from '../icons/icon'
import { IBrand, IProduct } from '../types/types'

interface BrandModalProps {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    brands: IBrand[]
    setBrand: React.Dispatch<React.SetStateAction<{
        b_id: number | null
        brand: string
    }>>
}

const breadCrumbItems = [
    { name: "Product Maintenance", navigate: "/product" },
    { name: "Product", active: true },
]

const initialBrand: {
    b_id: number | null
    brand: string
} = {
    b_id: null,
    brand: ""
}


const BrandModal = React.memo((props: BrandModalProps) => {
    const [filter, setFilter] = useState("")

    const ref = useRef<HTMLInputElement>(null)

    const handleSelectBrand = (_data: IBrand) => {
        props.setBrand({
            b_id: _data.b_id,
            brand: _data.brand
        })
        props.setShowModal(false)
    }

    const handleClearBrand = () => {
        props.setBrand(initialBrand)
        setFilter("")
    }

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            let _brand = props.brands.filter(row => row.brand.toLowerCase().indexOf(filter.toLowerCase()) !== -1)

            if (_brand.length === 1) {
                return handleSelectBrand(_brand[0])
            }
        }
        return
    }

    useEffect(() => {
        if (props.showModal && ref.current) {
            let timeout = setTimeout(() => {
                ref.current?.focus()
            }, 100)

            return () => clearTimeout(timeout)
        }
    }, [props.showModal])

    return <Modal
        width="20%"
        title='Brand List'
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
    >
        <Box flexDirection='column' gap="0.5rem">
            <Input
                width="100%"
                placeholder='Search'
                value={filter}
                onChange={e => setFilter(e.target.value)}
                forwardedRef={ref}
                onKeyPress={handleOnKeyPress} />

            <Table
                height="50vh"
                header={
                    <>
                        <TH>#</TH>
                        <TH>Brand</TH>
                    </>
                }
                body={
                    <>
                        {
                            props.brands.filter(row => row.brand.toLowerCase().indexOf(filter.toLowerCase()) !== -1).map(row => {
                                return <TR key={"brand" + row.b_id} onDoubleClick={() => handleSelectBrand(row)}>
                                    <TD>{row.b_id}</TD>
                                    <TD>{row.brand}</TD>
                                </TR>
                            })
                        }
                    </>
                }
            />
            <Box alignItems='center' justifyContent='center'>
                <IconGroupContainer onClick={handleClearBrand}>
                    <ResetIcon />
                    Clear Selection
                </IconGroupContainer>
            </Box>
        </Box>
    </Modal>
})

const ProductModal = React.memo((props: {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
    data: IProduct[]
    handleSelectProduct: (_data: IProduct) => void
}) => {
    const [filter, setFilter] = useState("")

    const ref = useRef<HTMLInputElement>(null)

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.which === 13) {
            let _product = props.data.filter(row => row.product.toLowerCase().indexOf(filter.toLowerCase()) !== -1)

            if (_product.length === 1) {
                return props.handleSelectProduct(_product[0])
            }
        }
        return
    }

    return <Modal
        width="50%"
        title='Product List'
        show={props.showModal}
        closeModal={() => props.setShowModal(false)}
        clickOutside
    >
        <Box flexDirection='column' gap="0.5rem">
            <Input
                width="100%"
                placeholder='Search'
                value={filter}
                onChange={e => setFilter(e.target.value)}
                forwardedRef={ref}
                onKeyPress={handleOnKeyPress} />

            <Table
                height="50vh"
                header={
                    <>
                        <TH width="8%">ID</TH>
                        <TH>Product</TH>
                        <TH>Model No</TH>
                        <TH width="6%"></TH>
                    </>
                }
                body={
                    <>
                        {
                            props.data.filter(row => {
                                return (row.brand + " " + row.product).toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
                                    row.model_no.toLowerCase().indexOf(filter.toLowerCase()) !== -1
                            }).map(row => {
                                return <TR key={"product" + row.p_id} onDoubleClick={() => props.handleSelectProduct(row)}>
                                    <TD>{row.p_id}</TD>
                                    <TD>{row.brand + " " + row.product}</TD>
                                    <TD>{row.model_no}</TD>
                                    <TD align='center'>
                                        <Box justifyContent='center' alignItems='center'>
                                            <SelectIcon iconcolor='red' onClick={() => props.handleSelectProduct(row)} />
                                        </Box>
                                    </TD>
                                </TR>
                            })
                        }
                    </>
                }
            />
        </Box>
    </Modal>
})

const Product = () => {
    let history = useNavigate()
    const { p_id } = useParams()
    const { token, setToken } = useLogin()

    const [reload, setReload] = useState(false)
    const [brands, setBrands] = useState<IBrand[]>([])
    const [products, setProducts] = useState<IProduct[]>([])

    const [brand, setBrand] = useState(initialBrand)
    const [product, setProduct] = useState("")
    const [model_no, setModel_no] = useState("")
    const [cost, setCost] = useState("")
    const [tax, setTax] = useState("")
    const [imei_req, setImei_req] = useState("")
    const [status, setStatus] = useState("")

    const [showModal, setShowModal] = useState(false)

    const [showProductModal, setShowProductModal] = useState(false)

    const { setAlert } = useAlert()

    const inputProductRef = useRef<HTMLInputElement>(null)

    const fetchRefData = useCallback(async () => {
        try {
            let [_brand, _product] = await Promise.all([
                fetchBrand(token),
                fetchProduct(token)
            ])
            setBrands(_brand)
            setProducts(_product)
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, reload, setAlert, setToken])

    const fetchData = useCallback(async () => {
        try {
            if (p_id && p_id !== "new") {
                let _data = await fetchProductById(token, p_id)
    
                setBrand({
                    b_id: _data.b_id,
                    brand: _data.brand
                })
                setProduct(_data.product)
                setModel_no(_data.model_no)
                setCost(_data.cost.toString())
                setTax(_data.tax.toString())
                setImei_req(_data.imei_req)
                setStatus(_data.status)
            } else {
                setBrand(initialBrand)
                setProduct("")
                setModel_no("")
                setCost("")
                setTax("")
                setImei_req("")
                setStatus("")
            }
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }
    }, [token, p_id, setToken, reload, setAlert])

    const handleSelectProduct = useCallback((_data: IProduct) => {
        setBrand({
            b_id: _data.b_id,
            brand: _data.brand
        })
        setProduct(_data.product)
        setModel_no(_data.model_no)
        setCost(_data.cost.toString())
        setTax(_data.tax.toString())
        setImei_req(_data.imei_req)
        setStatus(_data.status)

        setShowProductModal(false)
    }, [])

    const memoButton = useMemo(() => {
        let _btn: BreadCrumbButtonProps[] = [
            {
                name: "Reload Brand",
                color: "blue",
                action: () => fetchRefData()
            },
            {
                name: "Copy From Existing Product",
                color: "blue",
                action: () => setShowProductModal(true)
            },
        ]

        if (p_id === "new") return [..._btn]

        return [
            ..._btn,
            {
                name: "New",
                action: "/product/new",
                color: "blue"
            }
        ] as BreadCrumbButtonProps[]
    }, [p_id, fetchRefData])

    const handleSubmit = () => {
        if (!brand.b_id) return setAlert({ show: true, type: "danger", message: "Please select brand..." })
        if (!product || !product.trim()) return setAlert({ show: true, type: "danger", message: "Please insert product description..." })
        if (isNaN(Number(cost)) || cost === "") return setAlert({ show: true, type: "danger", message: "Invalid cost..." })
        if (isNaN(Number(tax)) || tax === "") return setAlert({ show: true, type: "danger", message: "Invalid tax..." })
        if (!imei_req) return setAlert({ show: true, type: "danger", message: "Please select imei requirement..." })
        if (!status) return setAlert({ show: true, type: "danger", message: "Please select status..." })

        setAlert({
            show: true,
            message: p_id === "new" ? "Create New Product ?" : "Update Product ?",
            type: "question",
            cb: async () => {
                try {
                    if (p_id === "new") {
                        let result = await axios.post("/product", {
                            b_id: brand.b_id,
                            product: product,
                            model_no: model_no,
                            cost: cost,
                            tax: tax,
                            imei_req: imei_req,
                            status: status
                        })

                        if (result.status !== 201) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }

                        setAlert({
                            show: true,
                            message: "Product Created Successfully !!!",
                            type: "success",
                            cb: () => {
                                history("/product/" + result.data.p_id)
                                fetchRefData()
                            }
                        })
                    } else {
                        let result = await axios.patch("/product/" + p_id, {
                            b_id: brand.b_id,
                            product: product,
                            model_no: model_no,
                            cost: cost,
                            tax: tax,
                            imei_req: imei_req,
                            status: status
                        })

                        if (result.status !== 200) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }

                        setAlert({
                            show: true,
                            message: "Product Updated Successfully !!!",
                            type: "success",
                            cb: () => {
                                fetchRefData()
                            }
                        })
                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger"
                    })
                }
            }
        })
    }

    useEffect(() => {
        fetchRefData()
    }, [fetchRefData])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if (showProductModal && inputProductRef.current) {
            let timeout = setTimeout(() => {
                inputProductRef.current?.focus()
            }, 100)
            return () => clearTimeout(timeout)
        }
    }, [showProductModal])

    return (
        <>
            <Breadcrumb
                items={breadCrumbItems}
                button={memoButton}
            />

            <PageContent>
                <Card mediaWidth='100%'>
                    <CardHeader border>{p_id === "new" ? "New Product" : "Edit Product # " + p_id}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="1rem">
                            <Input mediaWidth='100%' width="30rem" label="Brand" placeholder='Select brand...' disableInput iconType="search" value={brand.brand}
                                onClick={() => setShowModal(true)}
                                readOnly
                            />
                            <Input mediaWidth='100%' width="30rem" label="Product" placeholder='Product description...' value={product} onChange={e => setProduct(e.target.value)} />
                            <Input mediaWidth='100%' width="30rem" label="Model No" placeholder='Model number...' value={model_no} onChange={e => setModel_no(e.target.value)} />
                            <Input mediaWidth='100%' width="30rem" label="Cost$" placeholder='Cost...' value={cost} onChange={e => setCost(e.target.value)} />
                            <Input mediaWidth='100%' width="30rem" label="Tax%" placeholder='Tax...' value={tax} onChange={e => setTax(e.target.value)} />
                            <Select mediaWidth='100%' width="30rem" label="Imei Req" value={imei_req} onChange={e => setImei_req(e.target.value)}>
                                <option value="">Select Imei Req</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </Select>
                            <Select mediaWidth='100%' width="30rem" label="Status" value={status} onChange={e => setStatus(e.target.value)}>
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Select>
                        </Box>
                    </CardBody>
                    <CardFooter border>
                        <Box justifyContent='flex-end'>
                            <Button color="green" onClick={handleSubmit}>Submit</Button>
                        </Box>
                    </CardFooter>
                </Card>
            </PageContent>

            <BrandModal
                showModal={showModal}
                setShowModal={setShowModal}
                brands={brands}
                setBrand={setBrand}
            />

            <ProductModal 
            showModal={showProductModal}
            setShowModal={setShowProductModal}
            data={products}
            handleSelectProduct={handleSelectProduct}
            />
        </>
    )
}

export default Product