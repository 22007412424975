import React from 'react'
import styled from "styled-components"
import useThemeSetting from '../hooks/useThemeSetting'
import { ThemePalleteProps } from '../types/types'

interface Props {
    className?: string
    children?: React.ReactNode
    flexDirection?: "column" | "row"
    gap?: number | string
    justifyContent?: "center" | "flex-start" | "flex-end" | "space-between" | "space-evenly" | "space-around"
    alignItems?: "center" | "flex-start" | "flex-end" | "space-between" | "space-evenly" | "space-around"
    width?: number | string
    flex?: number 
}

const StyledBox = styled.div<ThemePalleteProps & Props>`
    box-sizing: border-box;
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : "row"};
    gap: ${props => props.gap ? (typeof props.gap === "number" ? `${props.gap}rem` : props.gap) : null};
    justify-content: ${props => props.justifyContent ? props.justifyContent : null};
    align-items: ${props => props.alignItems ? props.alignItems : null};
    width: ${props => props.width ? props.width : null};
    flex: ${props => props.flex ? props.flex : null};
    font-size: ${props => props.themeSetting?.typography.fontSize}rem;

    @media (max-width: 768px) {
        font-size: ${props => props.themeSetting?.typography.media}rem;
    }
`


const Box = (props: Props) => {
    const {theme} = useThemeSetting()

    return (
        <StyledBox 
        className={props.className}
        flexDirection={props.flexDirection} 
        gap={props.gap}
        justifyContent={props.justifyContent}
        alignItems={props.alignItems}
        width={props.width}
        flex={props.flex}
        themeSetting={theme}
        >{props.children}</StyledBox>
    )
}

export default Box