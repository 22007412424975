import axios from 'axios'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '../components/Box'
import Breadcrumb, { BreadCrumbButtonProps } from '../components/Breadcrumb'
import Button from '../components/Button'
import { Card, CardBody, CardFooter, CardHeader } from '../components/Card'
import Input from '../components/Input'
import PageContent from '../components/PageContent'
import Select from '../components/Select'
import { fetchLocationById } from '../fetchers/fetcher'
import { errorCallback } from '../helpers/errorCallback'
import { errorMessage } from '../helpers/errorMessage'
import useAlert from '../hooks/useAlert'
import useLogin from '../hooks/useLogin'

const breadCrumbItems = [
    { name: "Location Maintenance", navigate: "/location" },
    { name: "Location", active: true },
]

const Location = () => {
    let history = useNavigate()

    const {l_id} = useParams()
    const {token, setToken} = useLogin()
    const [location, setLocation] = useState("")
    const [add1, setAdd1] = useState("")
    const [add2, setAdd2] = useState("")
    const [add3, setAdd3] = useState("")
    const [contact_person, setContact_person] = useState("")
    const [contact_no, setContact_no] = useState("")
    const [type, setType] = useState("")
    const [status, setStatus] = useState("")
    const [reload, setReload] = useState(false)

    const {setAlert} = useAlert()

    const fetchData = useCallback(async () => {
        try {
            if(l_id && l_id !== "new") {
                let _data = await fetchLocationById(token, l_id)
                setLocation(_data.location)
                setAdd1(_data.add1)
                setAdd2(_data.add2)
                setAdd3(_data.add3)
                setContact_person(_data.contact_person)
                setContact_no(_data.contact_no)
                setType(_data.type)
                setStatus(_data.status)
            } else {
                setLocation("")
                setAdd1("")
                setAdd2("")
                setAdd3("")
                setContact_person("")
                setContact_no("")
                setType("")
                setStatus("")
            }
        } catch (err: any) {
            console.log(err.message)
            setAlert({
                show: true,
                message: err.message,
                type: "danger",
                ok: errorMessage(err.message),
                cb: () => errorCallback(err.message, setReload, reload, setToken)
            })
        }

    }, [token, l_id, setToken, setReload, reload, setAlert])

    const memoButton = useMemo(() => {
        if(l_id === "new") return []

        return [
            {
                name: "New",
                action: "/location/new",
                color: "blue"
            }
        ] as BreadCrumbButtonProps[]
    }, [l_id])

    const handleSubmit = () => {
        if (!location || !location.trim()) return setAlert({ show: true, type: "danger", message: "Please insert location name..." })
        if (!type) return setAlert({ show: true, type: "danger", message: "Please select location type..." })
        if (!status) return setAlert({ show: true, type: "danger", message: "Please select status..." })


        setAlert({
            show: true,
            message: l_id === "new" ? "Create New Location ?" : "Update Location ?",
            type: "question",
            cb: async () => {
                try {
                    if (l_id === "new") {
                        let result = await axios.post("/location", {
                            location, add1, add2, add3, contact_person, contact_no, type, status
                        })

                        if (result.status !== 201) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }

                        setAlert({
                            show: true,
                            message: "Location Created Successfully !!!",
                            type: "success",
                            cb: () => {
                                history("/location/" + result.data.l_id)
                            }
                        })
                    } else {
                        let result = await axios.patch("/location/" + l_id, {
                            location, add1, add2, add3, contact_person, contact_no, type, status
                        })

                        if (result.status !== 200) {
                            return setAlert({
                                show: true,
                                message: result.data.message,
                                type: "danger"
                            })
                        }

                        setAlert({
                            show: true,
                            message: "Location Updated Successfully !!!",
                            type: "success"
                        })
                    }
                } catch (err: any) {
                    console.log(err.message)
                    setAlert({
                        show: true,
                        message: err.message,
                        type: "danger",
                        cb: () => {
                            setAlert({
                                show: true,
                                message: err.message,
                                type: "danger"
                            })
                        }
                    })
                }
            }
        })
    }

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <Breadcrumb 
            items={breadCrumbItems}
            button={memoButton}
            />
            <PageContent>
                <Card mediaWidth='100%'>
                    <CardHeader border>{l_id === "new" ? "New Location" : "Edit Location # " + l_id}</CardHeader>
                    <CardBody>
                        <Box flexDirection='column' gap="1rem">
                            <Input width="30rem" mediaWidth='100%' label="Location" value={location} onChange={e => setLocation(e.target.value)} placeholder="Insert location..." />
                            <Input width="30rem" mediaWidth='100%' label="Address1" value={add1} onChange={e => setAdd1(e.target.value)} placeholder="Insert Address1..." />
                            <Input width="30rem" mediaWidth='100%' label="Address2" value={add2} onChange={e => setAdd2(e.target.value)} placeholder="Insert Address2..." />
                            <Input width="30rem" mediaWidth='100%' label="Address3" value={add3} onChange={e => setAdd3(e.target.value)} placeholder="Insert Address3..." />
                            <Input width="30rem" mediaWidth='100%' label="Contact Person" value={contact_person} onChange={e => setContact_person(e.target.value)} placeholder="Insert Contact Person..." />
                            <Input width="30rem" mediaWidth='100%' label="Contact No" value={contact_no} onChange={e => setContact_no(e.target.value)} placeholder="Insert Contact No..." />
                            <Select width="30rem" mediaWidth='100%' label="Type" value={type} onChange={e => setType(e.target.value)}>
                                <option value="">Select Type</option>
                                <option value="permit">Permit</option>
                                <option value="dealer">Dealer</option>
                            </Select>
                            <Select width="30rem" mediaWidth='100%' label="Status" value={status} onChange={e => setStatus(e.target.value)}>
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Select>
                        </Box>
                    </CardBody>
                    <CardFooter border>
                        <Box justifyContent='flex-end'>
                        <Button color="green" onClick={handleSubmit}>Submit</Button>
                        </Box>
                    </CardFooter>
                </Card>
            </PageContent>
        </>
    )
}

export default Location